import { XCircleIcon } from "@heroicons/react/24/outline";
import { ModalBox } from "../modal-box";
import TextEditor from "../text-editor";
import Datepicker from "react-tailwindcss-datepicker";
import { Button } from "../common/buttons";

type NotificationModelProps = {
    isOpen: boolean
    isClose: () => void
    isDone: () => void
    subject: string
    content: string
    sendContent: (content: string) => void
    handleSubject: (e: any) => void
    dateRange: { startDate: Date, endDate: Date }
    handleDateRange: (date: any) => void
    buttonLoading: boolean
}

const NotificationModel: React.FC<NotificationModelProps> = (props) => {
    return (
        <ModalBox isOpen={props.isOpen} onClose={props.isClose}>
            <div className="border-b flex justify-between items-center pb-3">
                <div className="text-sm font-medium text-[#101828]">Notifications</div>
                <XCircleIcon onClick={props.isClose} className='h-6 w-6 cursor-pointer'></XCircleIcon>
            </div>
            <div className="mt-3">
                <div className='grid grid-cols-12 gap-3 align-top justify-between overflow-auto h-72 max-h-80'>
                    <div className='col-span-3 text-sm font-medium'>Description</div>
                    <TextEditor
                        content={props.content}
                        sendContent={props.sendContent}
                        className="h-60"
                    />
                </div>
                <div className='flex mt-4 items-center'>
                    <div className='text-[#344054] text-sm font-medium w-[9.7rem]'>Subject</div>
                    <input
                        className="border rounded-md p-1 px-2 focus:outline-none w-72 h-10"
                        placeholder='Subject'
                        type="text"
                        value={props.subject}
                        name='subject'
                        onChange={props.handleSubject}
                    />
                </div>
                <div className='flex mt-4 items-center Datepicker'>
                    <div className='text-[#344054] text-sm font-medium w-[9.7rem]'>Date Range</div>
                    <Datepicker
                        primaryColor={"purple"}
                        useRange={false}
                        separator={"-"}
                        minDate={new Date()}
                        value={props.dateRange}
                        showFooter={true}
                        onChange={props.handleDateRange}
                        configs={{
                            footer: {
                                cancel: "Cancel",
                                apply: "Apply"
                            }
                        }}
                    />
                </div>
            </div>
            <div className='flex justify-end gap-4 mt-3'>
                <Button variant="secondary" onClick={props.isClose} >Cancel</Button>
                <Button onClick={props.isDone} isLoading={props.buttonLoading} >Save</Button>
            </div>
        </ModalBox>
    );
}

export default NotificationModel;