import React from 'react';

interface PDFPreviewProps {
    byteArrayString: string;
}

const PDFPreview: React.FC<PDFPreviewProps> = ({ byteArrayString }) => {
    const pdfUrl = `data:application/pdf;base64,${byteArrayString}`;

    return (
        <object
            type="application/pdf"
            data={`${pdfUrl}#toolbar=0`}
            width="100%"
            height="100%"
            className='mx-1 h-full outline-none rounded-xl'
        >
            Your browser does not support PDFs. Please download the PDF to view it.
        </object>
    );
};

export default PDFPreview;