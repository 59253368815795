import { useNavigate } from "react-router-dom";
import DropdownButton from "../../../components/common/dropdown-button";
import OptionMenu from "../../../components/option-btn";
import DataTable from "../../../components/table";
import Datepicker from "react-tailwindcss-datepicker";
import { useEffect, useState } from "react";
import { Bill } from "../../../types";
import JSZip from 'jszip';
import { base64ToUint8Array, formattedDate, getCookie } from "../../../utils/helpers";
import { toast, ToastContainer } from "react-toastify";
import Layout from "../../../layout";
import { Button } from "../../../components/common/buttons";
import financeApi from "../../../services/finance";

interface downloadInvoice {
    number: string
    file: string
}

const Billing = () => {
    const navigate = useNavigate()
    const [value, setValue] = useState<any>({
        startDate: null,
        endDate: null
    });
    const [draft, setDraft] = useState(true)
    const [invoiceList, setInvoiceList] = useState<any>([])
    const [bills, setBills] = useState<Bill[]>([])
    const [invoiceListLoading, setInvoiceListLoading] = useState<boolean>(true)
    const [selectedBills, setSelectedBills] = useState<string[]>([]);
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [allInvoiceSelect, setAllInvoiceSelect] = useState(false)

    const handleValueChange = (newValue: any) => {
        const { startDate, endDate } = newValue

        if (startDate && endDate) {
            const filterInvoice = bills.filter((item) => {
                const d1 = new Date(startDate)
                const d2 = new Date(endDate)
                const d = new Date(item.bill_date)
                if (d1 <= d && d <= d2) {
                    return item
                }
            })
            setInvoiceList(billsToTableData(filterInvoice))
        } else {
            setInvoiceList(billsToTableData(bills))
        }

        setValue(newValue)
    }

    const handleAddNewInvoice = () => {
        navigate("/create-bill")
    }

    const handleDraftFilter = () => {
        if (draft) {
            const filterInvoices = bills.filter((item) => {
                return item.is_draft === draft
            })
            setInvoiceList(billsToTableData(filterInvoices))
        } else {
            setInvoiceList(billsToTableData(bills))
        }
        setDraft(!draft)
    }

    const handleSelectDateRange = (option: string) => {
        const currentDate = new Date();
        let startDate;

        switch (option) {
            case "Last 7 Days":
                startDate = new Date(currentDate);
                startDate.setDate(startDate.getDate() - 7);
                break;
            case "Last Month":
                startDate = new Date(currentDate);
                startDate.setMonth(startDate.getMonth() - 1);
                break;
            case "Last Quarter":
                startDate = new Date(currentDate);
                startDate.setMonth(startDate.getMonth() - 3);
                break;
            default:
                startDate = new Date(0);
        }

        setValue({ startDate: startDate, endDate: currentDate })
        const filterInvoice = bills.filter((item) => {
            const d1 = new Date(startDate)
            const d2 = new Date(currentDate)
            const d = new Date(item.bill_date)
            if (d1 <= d && d <= d2) {
                return item
            }
        })
        setInvoiceList(billsToTableData(filterInvoice))
    }

    useEffect(() => {
        const draftData = getCookie("bills")
        const draftBills = draftData ? JSON.parse(draftData) : [];
        const getBills = async () => {
            const data: any = await financeApi.getBills()
            if (data && data.bills) {
                const gatingBills: Bill[] = data.bills
                const mergedBills = [...gatingBills, ...draftBills];
                setBills(mergedBills)
                setInvoiceList(billsToTableData(mergedBills))
                setInvoiceListLoading(false)
            } else {
                setInvoiceListLoading(false)
            }
        }
        getBills()
    }, [])

    const draftCount = bills.filter(item => item.is_draft).length

    const handleCheckboxChange = (billNo: string) => {
        setSelectedBills(prevSelected => {
            if (prevSelected.includes(billNo)) {
                return prevSelected.filter(no => no !== billNo);
            } else {
                return [...prevSelected, billNo];
            }
        });
    };

    const billsToTableData = (invoices: Bill[]) => {
        const tableData = invoices.map((item, index) => {
            const optionMenu = [
                { name: "Edit", onClick: () => { navigate("/edit-bill/" + item.bill_no) } },
                { name: "View", onClick: () => { navigate("/bill-preview/" + item.bill_no) } },
            ]
            const isChecked = selectedBills.includes(item.bill_no);

            return {
                id: index,
                checkBox: <input
                    type="checkbox"
                    disabled={item.is_draft}
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(item.bill_no)}
                    className="cursor-pointer"
                />,
                billingDate: formattedDate(new Date(item.bill_date)),
                invoice: item.invoice_no,
                bill: item.bill_no,
                billingTo: <>{item.bill_from}<p className="text-[#000000B2]">{item.mail_id}</p></>,
                gstIn: item.gst_in,
                amount: item.sub_total,
                action: <OptionMenu items={optionMenu} className={"right-0 bottom-0"} />
            }
        })
        return tableData
    }

    const handleDownload = () => {
        if (!selectedBills.length) {
            toast.error("Not select bills", { autoClose: 3000 });
            return
        }
        setDownloadLoading(true)
        const downloadBills = async () => {
            const response = await financeApi.downloadBills({ bill_no: selectedBills })
            if (response) {
                const blob = await response.blob();
                const contentDisposition = response.headers.get("Content-Type");
                const filename = contentDisposition
                    ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
                    : `bill-${selectedBills[0]}-to-bill-${selectedBills[selectedBills.length - 1]}.zip`;

                const fileUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = filename || `bill-${selectedBills[0]}-to-bill-${selectedBills[selectedBills.length - 1]}.zip`;
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(fileUrl);
                setDownloadLoading(false)
            } else {
                setDownloadLoading(false)
            }
        }
        downloadBills()
    }

    useEffect(() => {
        setInvoiceList(billsToTableData(bills))
    }, [selectedBills]);

    const handleAllCheckInvoice = (e: any) => {
        setAllInvoiceSelect(e.target.checked)
        if (e.target.checked) {
            setSelectedBills(invoiceList.map((item: any) => item.bill));
        } else {
            setSelectedBills([]);
        }
    }

    const headers: any = [
        {
            key: 'checkBox', label: <input
                type="checkbox"
                disabled={false}
                checked={allInvoiceSelect}
                onChange={handleAllCheckInvoice}
                className="cursor-pointer"
            />
        },
        { key: 'billingDate', label: 'Billing Date' },
        { key: 'invoice', label: 'Invoice #' },
        { key: 'bill', label: 'Bill #' },
        { key: 'billingTo', label: 'Billing To' },
        { key: 'gstIn', label: 'GSTIN' },
        { key: 'amount', label: 'Amount' },
        { key: 'action', label: '' },
    ];

    return (
        <Layout>
            <h2 className="text-xl font-semibold mb-4">Billing</h2>
            <div className="flex justify-between mb-4">
                <div className="flex gap-3 justify-start rounded relative">
                    <div>
                        <DropdownButton
                            title={"Last 7 Days"}
                            options={["Last 7 Days", "Last Month", "Last Quarter"]}
                            sendSelectOption={handleSelectDateRange}
                        />
                    </div>
                    <div>
                        <Datepicker
                            containerClassName={"relative border rounded"}
                            primaryColor={"indigo"}
                            useRange={false}
                            separator={"-"}
                            value={value}
                            showFooter={true}
                            onChange={handleValueChange}
                            configs={{
                                footer: {
                                    cancel: "Cancel",
                                    apply: "Apply"
                                }
                            }}
                        />
                    </div>
                    <div className="bg-[#ebebeb] flex justify-between gap-1 p-[2px] rounded font-medium text-sm">
                        <div className={`rounded px-2 py-1 flex justify-between gap-1 items-center cursor-pointer border ${draft ? "bg-white " : ""}`} onClick={handleDraftFilter}>
                            All <span className="text-[#1D1027B2]">{bills.length}</span>
                        </div>
                        <div className={`rounded px-2 py-1 flex justify-between gap-1 items-center cursor-pointer border ${draft ? "" : "bg-white"}`} onClick={handleDraftFilter}>
                            Draft <span className="text-[#1D1027B2]">{draftCount}</span>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <Button variant="outline" onClick={handleDownload} isLoading={downloadLoading} >Download</Button>
                    <Button onClick={handleAddNewInvoice} >Add New</Button>
                </div>
            </div>
            <DataTable
                className={""}
                data={invoiceList}
                loading={invoiceListLoading}
                headers={headers}
                pages={true}
            />
            <ToastContainer />
        </Layout>
    );
}

export default Billing;