import React, { memo, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css"
import { useUserContext } from "../../context/user";
import { useLocation } from 'react-router-dom';
import { DashboardIcon, FinanceIcon, LeaveIcon, NavToggleButton, SettingIcon, TaskIcon } from "../../assets/icons";
import ExpandMenu from "../../components/common/expand-menu";
import { Roles } from "../../config";
import { AllowedUser } from "../../utils/helpers";
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/outline";

type SidebarProps = {
    logout: () => void
}

const Sidebar: React.FC<SidebarProps> = ({ logout }) => {
    const { user } = useUserContext();
    const location = useLocation();
    const [isExpanded, setIsExpanded] = useState<boolean>(() => {
        const storedState = localStorage.getItem('sidebarExpanded');
        return storedState ? JSON.parse(storedState) : true;
    });
    const [sidebarWidth, setSidebarWidth] = useState<string>(isExpanded ? "w-64" : "w-24");
    const [expandMenu, setExpandMenu] = useState<boolean>(false);
    const path = location.pathname;

    const toggleSidebar = () => {
        const newIsExpanded = !isExpanded;
        setSidebarWidth(newIsExpanded ? "w-64" : "w-24");
        setIsExpanded(newIsExpanded);
        localStorage.setItem('sidebarExpanded', JSON.stringify(newIsExpanded));
        if (newIsExpanded && expandMenu) {
            setExpandMenu(true)
        } else {
            setExpandMenu(false)
        }
    };

    const optionsFinance = [
        { title: "Invoices", isActive: path === "/invoices", to: "/invoices" },
        { title: "Bills", isActive: path === "/bills", to: "/bills" }
    ];

    const handleExpandMenu = () => {
        setIsExpanded(true);
        setSidebarWidth("w-64");
        setExpandMenu(prev => !prev);
        localStorage.setItem('sidebarExpanded', JSON.stringify(true));
    };

    useEffect(() => {
        setExpandMenu(optionsFinance.some(option => option.isActive) && isExpanded);
    }, [isExpanded]);

    return (
        <div className={`h-[100vh] p-6 relative border-r dark:bg-[#333333] border-r-[#E6E6E6] bg-white ${sidebarWidth} T sticky top-0 z-50`}>
            <Link to="/dashboard" className="flex justify-start gap-3 items-center">
                <img src="/assets/au-dark-logo.png" alt="Auxilio" className="h-11 w-11" />
                <div className={`text-[#2E3033] text-lg font-extrabold overflow-hidden T ${isExpanded ? 'max-w-32 opacity-100' : 'max-w-0 opacity-0'}`}>AUXILIO</div>
            </Link>
            <button
                className="nav-toggle-btn"
                onClick={toggleSidebar}
            >
                <NavToggleButton className={isExpanded ? 'rotate-180' : 'rotate-0'} />
            </button>
            <Divider />
            <RouteType name="Overview" />
            <NavButton
                name="Dashboard"
                to="/dashboard"
                icon={<DashboardIcon />}
                isActive={path === "/dashboard"}
                isExpanded={isExpanded}
            />
            <Divider />
            <RouteType name={isExpanded ? "Workforce & Finance" : "Finance"} />
            <AllowedUser permissions={[Roles.ADMIN, Roles.SUPER_ADMIN, Roles.FINANCE_ADMIN]}>
                <ExpandMenu
                    isExpanded={isExpanded}
                    isOpen={expandMenu}
                    onClick={handleExpandMenu}
                    title="Finance"
                    options={optionsFinance}
                    icon={<FinanceIcon />}
                />
            </AllowedUser>
            {user.emp_id && routerList.map((item, index) => {
                const to = typeof item.to === 'string' ? item.to : item.to(user.emp_id);
                const isActive = to === path;
                return (
                    <AllowedUser key={index} permissions={item.permission}>
                        <NavButton
                            name={item.name}
                            to={to}
                            icon={item.icon}
                            isActive={isActive}
                            isExpanded={isExpanded}
                        />
                    </AllowedUser>
                )
            })}
            <Divider />
            <RouteType name={isExpanded ? "Settings" : "Settings"} />

            <div
                className={`px-3 h-11 py-[10px] rounded-md flex justify-start items-center gap-2 mt-2 T hover-class hover:bg-[#F7ECFF] relative group bg-white text-[#757575]`}
                onClick={logout}
            >
                <div className={"nav-btn-light"}>
                    {<SettingIcon />}
                </div>
                <div className={`grow shrink basis-0 text-base font-medium hover-text overflow-hidden T ${isExpanded ? "w-auto h-6" : "w-0 h-0"}`}>
                    Logout
                </div>
                {!isExpanded &&
                    <div className="absolute left-full  mb-1 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 text-nowrap w-fit">
                        Logout
                    </div>
                }
            </div>
        </div>
    );
}

export default memo(Sidebar);

function RouteType(props: { name: string }) {
    return (
        <div className="text-[11px] text-[#757575] font-medium uppercase h-5">
            {props.name}
        </div>
    )
}

function Divider() {
    return (
        <div className="bg-[#F6F6F6] h-[2px] my-6 rounded-sm"></div>
    )
}

type NavButtonProps = {
    name: string
    to: string
    icon: ReactNode
    isActive: boolean
    onClick?: () => void
    isExpanded: boolean
}

const NavButton: React.FC<NavButtonProps> = (props) => {
    return (
        <Link to={props.to}
            className={`px-3 h-11 py-[10px] rounded-md flex justify-start items-center gap-2 mt-2 T hover-class hover:bg-[#F7ECFF] relative group ${props.isActive ? "bg-[#F7ECFF]" : " bg-white text-[#757575]"}`}
            onClick={props.onClick}
        >
            <div className={props.isActive ? "nav-btn-active" : "nav-btn-light"}>
                {props.icon}
            </div>
            <div className={`grow shrink basis-0 text-base font-medium hover-text overflow-hidden T ${props.isActive ? "hover-text" : ""} ${props.isExpanded ? "w-auto h-6" : "w-0 h-0"}`}>
                {props.name}
            </div>
            {!props.isExpanded &&
                <div className="absolute left-full  mb-1 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 text-nowrap w-fit">
                    {props.name}
                </div>
            }
        </Link>
    )
}

const routerList = [
    {
        name: "Employee Stats",
        to: "/employee-stats",
        icon: <LeaveIcon />,
        isActive: false,
        permission: [
            // Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.HR_ADMIN,
        ],
    },
    {
        name: "Role Management",
        to: "/role-management",
        icon: <LeaveIcon />,
        isActive: false,
        permission: [
            // Roles.SUPER_ADMIN,
            // Roles.ADMIN,
        ],
    },
    {
        name: "Calendar",
        to: "/calendar",
        icon: <LeaveIcon />,
        isActive: false,
        permission: [
            // Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.FINANCE_ADMIN,
            // Roles.HR_ADMIN,
        ],
    },
    {
        name: "Leave Management",
        to: (empId: string) => `/leave-management/${empId}`,
        icon: <LeaveIcon />,
        isActive: false,
        permission: [
            Roles.ADMIN,
            Roles.SUPER_ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
            Roles.MANAGER,
            Roles.USER
        ],
    },
    {
        name: "Task Stats",
        to: (empId: string) => `/task-stats/${empId}`,
        icon: <TaskIcon />,
        isActive: false,
        permission: [
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
            Roles.MANAGER,
            Roles.USER
        ],
    },
]