import React, { useState } from "react";
import { ModalBox } from "../modal-box";
import { XMarkIcon } from "@heroicons/react/24/outline";
import PDFPreview from "../PDFPreview";
import { EodReportFormProps } from "../../types";
import { formatDateDMonth, formatDateDMonthY } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

const EodReport: React.FC<EodReportFormProps> = (props) => {
    const navigate = useNavigate()
    const [isOpenView, setIsOpenView] = useState(false)

    const pdf = props.report_date ?
        <div className="flex items-center gap-1">
            <PdfSvg />
            {formatDateDMonthY(props.report_date) + ".pdf"}
        </div>
        :
        <p className="font-semibold text-[#E10000]">Not Uploaded</p>
    const className = "text-sm font-medium px-2 py-[10px] border-b "

    const handleViewPdf = () => {
        navigate(`/task-view/${props.emp_id}/${props.id}`);
    }

    return (
        <>
            <div className={className}>{props.index}</div>
            <div className={className}>{formatDateDMonth(props.report_date)}</div>
            <div className={className + " truncated-text"}>{props.department}</div>
            <div className={`${className}  ${props.report_date ? "cursor-pointer" : "cursor-default"}`} onClick={handleViewPdf}>
                {pdf}
            </div>
            <ModalBox isOpen={isOpenView} onClose={() => { setIsOpenView(false) }} >
                <div className="flex justify-between items-center mb-5">
                    <h2 className="h2 w-96 mb-1">Eod Report {props.report_date}</h2>
                    <XMarkIcon className="w-6 h-6 text-[#667085] cursor-pointer" onClick={() => { setIsOpenView(false) }} />
                </div>
                <div className="h-[720px]">
                    <PDFPreview byteArrayString={props.report_date} />
                </div>
            </ModalBox>
        </>
    )
}

export default EodReport;

export const SkeletonEodReport = () => {
    return (
        <>
            <div className={`skeleton skeleton-line short`}></div>
            <div className={`skeleton skeleton-line medium`}></div>
            <div className={`skeleton skeleton-line long`}></div>
            <div className={`skeleton skeleton-line medium`}></div>
        </>
    );
};

export const PdfSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.99992 1.6665V7.08317C9.99992 7.41469 10.1316 7.73263 10.366 7.96705C10.6005 8.20147 10.9184 8.33317 11.2499 8.33317H16.6666V16.6665C16.6666 17.1085 16.491 17.5325 16.1784 17.845C15.8659 18.1576 15.4419 18.3332 14.9999 18.3332H4.99992C4.55789 18.3332 4.13397 18.1576 3.82141 17.845C3.50885 17.5325 3.33325 17.1085 3.33325 16.6665V3.33317C3.33325 2.89114 3.50885 2.46722 3.82141 2.15466C4.13397 1.8421 4.55789 1.6665 4.99992 1.6665H9.99992ZM9.17575 9.87317C8.97612 11.1423 8.3124 12.2922 7.31325 13.0998C6.57409 13.6965 7.24992 14.8673 8.13659 14.5265C9.33565 14.065 10.6634 14.065 11.8624 14.5265C12.7491 14.8682 13.4249 13.6973 12.6858 13.0998C11.6867 12.2921 11.023 11.1423 10.8233 9.87317C10.6758 8.93484 9.32325 8.934 9.17575 9.87317ZM9.99992 11.919L10.6716 13.0807H9.32992L9.99992 11.919ZM11.6666 1.70234C11.9823 1.7693 12.2718 1.9265 12.4999 2.15484L16.1783 5.83317C16.4066 6.06127 16.5638 6.35077 16.6308 6.6665H11.6666V1.70234Z" fill="#FF6252" />
        </svg>
    )
}