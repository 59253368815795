import React, { useEffect } from "react";
import InvoiceHeader from "../invoice-header";
import { Button } from "../common/buttons";

interface InvoiceModelProps {
    isOpen: boolean
    type: string,
    companyName: string,
    date: string,
    onClose: () => void,
    onClick: () => void,
}

const InvoiceModel: React.FC<InvoiceModelProps> = (props) => {

    return (
        <>
            {props.isOpen &&
                <div className="bg-white fixed h-screen w-screen inset-0 z-[1000]">
                    <InvoiceHeader name="" onClose={props.onClose} />
                    <div className="h-[100%] flex justify-center">
                        <div className="flex flex-col items-center mt-[8%]">
                            <img src="/assets/invoice.png" alt="invoice" className="max-h-[40%]" />
                            <h1 className="font-bold text-3xl text-[#1D1027] mt-[5%]">
                                {props.type} has been generated
                            </h1>
                            <p className="text-[#1D1027CC] text-center mt-2 mb-5">
                                {props.type} with date {props.date} has <br /> been generated for {props.companyName}
                            </p>
                            <Button onClick={props.onClick} isLoading={false} >
                                View {props.type} Details
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default InvoiceModel;