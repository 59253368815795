import { ReactNode } from "react";

type ComponentLoadingProps = {
    children: ReactNode;
    childrenSkeleton: ReactNode;
    isLading: boolean;
    error: boolean;
    skeletonArray?: number[]
}

// only use for array
export const ComponentLoading = ({ children, childrenSkeleton, isLading, error, skeletonArray = [1, 2, 3, 4] }: ComponentLoadingProps) => {
    return (
        <>
            {(error || !children) ?
                <div className="flex items-center justify-center h-full">
                    <div className="text-center py-2">
                        <h2 className="text-xl font-semibold text-gray-600-600">No data found</h2>
                        {/* <p className="text-gray-500">Please try again later.</p> */}
                    </div>
                </div>
                : isLading ?
                    skeletonArray.map((_, index) => { // Use index as the key
                        return (
                            <div key={index}>
                                {childrenSkeleton}
                            </div>
                        );
                    })
                    : children
            }
        </>
    );
}