import { useNavigate, useParams } from "react-router-dom";
import InvoiceHeader from "../../../components/invoice-header";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Invoice } from "../../../types";
import { LoadingScreen } from "../../../components/loading-screen";
import { toast, ToastContainer } from "react-toastify";
import { base64ToUint8Array, formattedDate, getCookie } from "../../../utils/helpers";
import financeApi from "../../../services/finance";
import { Button } from "../../../components/common/buttons";
import UploadPdfPreview from "../../../components/upload-pdf-preview";

interface editNote {
    isEditNote: boolean
    value: string
}

const InvoicePreview = () => {
    const navigate = useNavigate()
    const [invoiceData, setInvoiceData] = useState<Invoice>()
    const [noteState, setNoteState] = useState<editNote>({ isEditNote: true, value: "" })
    const { invoiceNo } = useParams()
    const [loadingInvoice, setLoadingInvoice] = useState(true)
    const draftData = getCookie("invoices")
    const draftInvoices: Invoice[] = draftData ? JSON.parse(draftData) : []

    const handleEditTrueNote = () => {
        setNoteState({
            ...noteState, isEditNote: !noteState.isEditNote
        })

        if (!noteState.isEditNote && invoiceData && invoiceNo) {
            invoiceData.notes = noteState.value
            invoiceData.services = invoiceData.services.map(({ invoice_no, bill_no, ...rest }) => rest);
            const updateInvoice = async () => {
                const response = await financeApi.setInvoice(invoiceData)
                if (response) {
                    getInvoice(invoiceNo)
                    toast.success("Notes edit successfully", { autoClose: 3000 });
                }
            }
            updateInvoice()
        }
    }

    const handleCancelNoteChange = () => {
        setNoteState({
            ...noteState, isEditNote: !noteState.isEditNote,
            value: invoiceData?.notes || ""
        })
    }

    const handleEditNote = (e: any) => {
        const value: string = e.target.value
        setNoteState({
            ...noteState, value: value
        })
    }

    useEffect(() => {
        if (invoiceNo) {
            let invoiceFound = false;

            for (let i = 0; i < draftInvoices.length; i++) {
                const element = draftInvoices[i];
                if (element.invoice_no === invoiceNo) {
                    invoiceFound = true;
                    setInvoiceData(element)
                    setNoteState({
                        ...noteState, value: element.notes
                    })
                    setLoadingInvoice(false)
                    break;
                }
            }
            if (!invoiceFound) {
                getInvoice(invoiceNo)
            }
        }
    }, [invoiceNo]);

    const getInvoice = async (no: string) => {
        const data: any = await financeApi.getInvoice(no)
        if (data && data.invoice) {
            const invoice: Invoice = data.invoice
            setInvoiceData(invoice)
            setNoteState({
                ...noteState, value: invoice.notes, isEditNote: true
            })
            setLoadingInvoice(false)
        } else {
            setLoadingInvoice(false)
        }
    }

    const invoiceDate = formattedDate(invoiceData ? new Date(invoiceData.invoice_date) : new Date())
    const grandTotal = invoiceData?.total_tax! + invoiceData?.sub_total!

    const handleExportInvoice = () => {
        const downloadInvoice = async () => {
            const response: any = await financeApi.downloadInvoice({ invoice_no: [invoiceNo] })
            if (response) {
                const blob = await response.blob();

                const contentDisposition = response.headers.get("Content-Type");
                const filename = contentDisposition
                    ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
                    : `inv-${invoiceNo}.zip`;

                const fileUrl = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = filename || `inv-${invoiceNo}.zip`;
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(fileUrl);
            } else { }
        }
        downloadInvoice()
    }

    const handleDownloadAttachment = () => {
        const byteArray = base64ToUint8Array(invoiceData?.attachment);
        const link = document.createElement('a');
        const blob = new Blob([byteArray], { type: "application/octetstream" });
        const url = window.URL;
        let l2 = url.createObjectURL(blob);
        link.href = l2;
        link.download = invoiceNo + "-attachment" + ".pdf"
        link.dispatchEvent(new MouseEvent('click'));
    }

    if (loadingInvoice) {
        return <LoadingScreen />
    }
    const pdfBlob: Uint8Array = invoiceData?.attachment
    const pdfSizeInBytes = pdfBlob?.length || 0;
    const pdfSizeInKB = (pdfSizeInBytes / 1024).toFixed(2)

    return (
        <div className="bg-white">
            <InvoiceHeader name="" onClose={() => { navigate("/invoices") }} />
            <div className="py-6 px-6 grid grid-cols-3 gap-x-[5%] gap-y-6">
                <h1 className="text-[#1D1027] font-bold text-3xl col-span-3">{invoiceData?.invoice_no}</h1>
                <div className="col-span-3 flex justify-end gap-2 py-3">
                    <Button variant="outline" onClick={handleExportInvoice} >Export Invoice</Button>
                    <Button onClick={() => { navigate("/edit-invoice/" + invoiceNo) }} >Edit Invoice</Button>
                </div>
                <div className="col-span-2 bg-[#F4F6F8] px-5 py-5 rounded-xl">
                    <div className="flex justify-between">
                        <div>
                            <h2 className="text-xl font-semibold mb-1">Invoices</h2>
                            <p className="primary-text text-">No: {invoiceData?.invoice_no}</p>
                            <div className="flex ">
                                <p className="light-text w-32">Bill No.</p>
                                <p>{invoiceData?.bill_no}</p>
                            </div>
                            <div className="flex ">
                                <p className="light-text w-32">Invoice Date</p>
                                <p>{invoiceDate}</p>
                            </div>
                        </div>
                        <div className="w-80">
                            <h2 className="text-lg text-[#3E63DD] mb-1">
                                {invoiceData?.company_name}
                            </h2>
                            <p className="primary-text text-">TAX - {invoiceData?.gst_in}</p>
                            <p className="light-text">
                                21, Park Avenue (Twin Bngls) Nr Gulab Tower, Thaltej, Ahmedabad, 380054
                            </p>
                            <p className="light-text">hr@arundataprocessing.co.in</p>
                            <p className="light-text">+91 62015 25013</p>
                        </div>
                    </div>
                    <div className="flex justify-between mt-4">
                        <div>
                            <p className="light-text font-semibold">Customer Details</p>
                            <p>{invoiceData?.bill_to}</p>
                            <p>{invoiceData?.mail_id}</p>
                            <p>{invoiceData?.contact_no}</p>
                        </div>
                        <div className="w-80">
                            <p className="light-text font-semibold">Billing Address</p>
                            <p>
                                {invoiceData?.address_line_1} <br />
                                {invoiceData?.address_line_2}
                            </p>
                        </div>
                    </div>
                    <table className="w-full mt-6 text-xs">
                        <thead>
                            <tr className="py-2 bg-[#F8F9FA]">
                                <th className="light-text py-2 px-2">#</th>
                                <th className="light-text py-2 px-2 text-left">Item Name</th>
                                <th className="light-text py-2 px-2">HSN ACS</th>
                                <th className="light-text py-2 px-2">Qty</th>
                                <th className="light-text py-2 px-2">Price</th>
                                <th className="light-text py-2 px-2 text-end">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceData?.services.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="py-2 px-2 text-center">{index + 1}</td>
                                        <td className="py-2 px-2">
                                            <p >{item.description}</p>
                                            <p className="text-xs light-text">{item.hsn_acs}</p>
                                        </td>
                                        <td className="py-2 px-2 text-center">{item.hsn_acs}</td>
                                        <td className="py-2 px-2 text-center">{item.quantity}</td>
                                        <td className="py-2 px-2 text-center">₹{item.unit_price}</td>
                                        <td className="py-2 px-2 text-end">₹{item.unit_price * item.quantity}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={6} className="py-4"></td>
                            </tr>
                            <tr>
                                <td colSpan={4}></td>
                                <td className="light-text">
                                    Subtotal
                                </td>
                                <td className="text-end">
                                    ₹{invoiceData?.sub_total}
                                </td>
                            </tr>
                            {!!invoiceData?.cgst && <tr>
                                <td colSpan={4}></td>
                                <td className="light-text">
                                    CGST
                                </td>
                                <td className="text-end">
                                    ₹{invoiceData?.cgst}
                                </td>
                            </tr>}
                            {!!invoiceData?.sgst && <tr>
                                <td colSpan={4}></td>
                                <td className="light-text">
                                    SGST
                                </td>
                                <td className="text-end">
                                    ₹{invoiceData?.sgst}
                                </td>
                            </tr>}
                            {!!invoiceData?.igst && <tr>
                                <td colSpan={4}></td>
                                <td className="light-text">
                                    IGST
                                </td>
                                <td className="text-end">
                                    ₹{invoiceData?.igst}
                                </td>
                            </tr>}
                            <tr>
                                <td colSpan={4}></td>
                                <td>
                                    Grand Total
                                </td>
                                <td className="text-end">
                                    ₹{grandTotal}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <p className="light-text font-medium text-xs my-4">
                        Thank you for doing business with us. Have a good day!
                    </p>
                    {invoiceData?.attachment &&
                        <div className="max-w-80">
                            <p className="mb-1">Attachments</p>
                            <UploadPdfPreview name={"Attachments"} byte={Number(pdfSizeInKB)} loading={false} isDownload={true} onRemove={handleDownloadAttachment} />
                        </div>
                    }
                </div>
                <div className="">
                    <div className="flex justify-between items-center">
                        <label htmlFor="note" className="text-[#344054] text-sm font-medium block mb-2">Note</label>
                        <div className="flex gap-6 items-center">
                            {noteState.isEditNote ? "" :
                                <button type="button" className="text-[#0062FFCC] flex gap-2 items-center text-sm font-medium mb-2" onClick={handleCancelNoteChange} >
                                    Cancel
                                </button>}
                            <button type="button" className="text-[#0062FFCC] flex gap-2 items-center text-sm font-medium mb-2" onClick={handleEditTrueNote} >
                                <PencilSquareIcon className="h-5 w-5" />
                                {noteState.isEditNote ? "Edit Note" : "Save Note"}
                            </button>
                        </div>
                    </div>
                    <textarea name="note" id="note"
                        onChange={handleEditNote}
                        value={noteState.value}
                        rows={3}
                        disabled={noteState.isEditNote}
                        maxLength={800}
                        placeholder='Please ensure payment is made by the due date to avoid any late fees.'
                        className="text-area"></textarea>

                    <h2 className="text-[#344054] text-base font-semibold mt-2">Logs</h2>
                    <div className="max-h-96 overflow-scroll">
                        {invoiceData?.updated_at?.map((item, index) => {
                            const [date, time] = item.split('T');
                            const [hour, minute] = time.split(':');
                            const isLastElement = index === invoiceData.updated_at.length - 1;
                            const circleClass = isLastElement ? ' ' : 'green-circle';

                            return (
                                <div className="flex items-center gap-3 mt-3" key={index}>
                                    <div className={`h-8 w-8 rounded-full bg-green-700 relative ${circleClass}`}></div>
                                    <div>
                                        <h3 className="text-sm font-medium text-[#333333]">
                                            {index === 0 ? 'Invoice was generated' : 'Changes made in Invoice'}
                                        </h3>
                                        <p className="text-[#888888] text-xs">{date}, {hour}:{minute}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default InvoicePreview;