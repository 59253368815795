
export type DotListType = {
    name: string,
    color: string
}

export const DotList: React.FC<DotListType> = ({ name, color }) => {
    const colorCode = `bg-[${color}]`
    return (
        <div className="flex justify-start gap-2 items-center">
            <div className={`h-3 w-3 rounded-full ${colorCode}`}></div>
            <p className="text-xs font-medium text-black">
                {name}
            </p>
        </div>
    )
}