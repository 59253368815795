import { QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

interface InvoiceHeaderProps {
    name: string,
    onClose: () => void
}

const InvoiceHeader: React.FC<InvoiceHeaderProps> = (props) => {
    return (
        <div className="bg-white py-3 px-6 flex justify-between sticky top-0 z-50 border-b border-[#1D10271A] items-center">
            <div className="flex gap-3 items-center">
                <img src="/assets/logo.png" alt="Auxilio" className="h-7 w-7" />
                <div className="text-zinc-800 text-base font-bold">AUXILIO</div>
                {props.name &&
                    <>
                        <div className="w-[1px] bg-[#00000033] h-6"> </div>
                        <div className="text-base font-semibold text-[#1C1C1C]">
                            {props.name}
                        </div>
                    </>
                }
            </div>
            <div className="flex gap-4 h-9 items-center">
                <div className="flex gap-1 items-center">
                    <QuestionMarkCircleIcon className="h-6 w-6 text-[#1C1C1CB2] cursor-pointer" />
                    <p className="text-[#1C1C1CB2] text-sm">
                        Do you need any help?
                    </p>
                </div>
                <div className="w-[1px] bg-[#00000033] h-6"> </div>
                <XCircleIcon className="h-6 w-6 cursor-pointer" onClick={props.onClose} />
            </div>
        </div>
    );
}

export default InvoiceHeader;