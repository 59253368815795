import React, { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { HolidayList, LeaveAllocationProps, LeaveBalance, LeaveLog, LeaveLogNodeProps, TeamMemberType } from '../../types';
import { APPROVALS, APPROVALS_STATUS, LeavesType } from '../../constants';
import { ModalBox } from '../../components/modal-box';
import { toast } from "react-toastify";
import { useUserContext } from '../../context/user';
import UpcomingHoliday from '../../components/common/upcoming-holiday';
import DropdownButton from '../../components/common/dropdown-button';
import ConfirmDialog from '../../components/confirm-dialog';
import { InputElement, TextAreaInput } from '../../components/common/form-input';
import { useParams } from 'react-router-dom';
import { convertToISOFormat2, countLeaveDays, FormattedDdMmDate, FormattedYMDate, getDateRange, holidayFilter } from '../../utils/helpers';
import { CasualLeave, PaidLeave, SickLeave, WFH } from '../../assets/icons';
import Layout from '../../layout';
import hrApi from '../../services/hr-module';
import TeamMember, { TeamMemberSkeleton } from '../../components/common/team-member';
import useLeaveLogs from '../../hooks/use-leaves';
import { ComponentLoading } from '../../components/component-loading';
import { LeaveBadge } from '../../components/badges/leave';
import { Button, CrossButton } from '../../components/common/buttons';
import "./index.css"
import { LeaveCard, LeaveCardSkeleton, LeaveLogNode } from '../../components/common/leave-card';

const defaultLeaveAllocation: LeaveAllocationProps = {
    empID: "",
    startDate: "",
    endDate: "",
    days: 0,
    leave_type: {
        id: "33333333-3333-3333-3333-333333333333",
        name: "Paid Leave",
        description: "Paid Leave"
    },
    reason: "",
    subject: "",
    sn: 0,
    leaveID: '',
    designation: '',
    image: '',
    name: '',
    status: '',
    approvedDates: [],
    declinedDates: [],
    managerStatus: ''
}

const LeaveManagement = () => {
    const { empId } = useParams();
    const [value, onChange] = useState<Value>(new Date());
    const [holidayList, setHolidayList] = useState<HolidayList[]>([])
    const [leaveRequestAllocation, setLeaveRequestAllocation] = useState<boolean>(false)
    const [leaveAllocationFrom, setLeaveAllocationForm] = useState<LeaveAllocationProps>(defaultLeaveAllocation)
    const [teamWorkingStatus, setTeamWorkingStatus] = useState<TeamMemberType[]>([]);
    const [leaveCounts, setLeaveCounts] = useState<LeaveBalance[]>([]);
    const [selectRevokeItem, setSelectRevokeItem] = useState<LeaveLogNodeProps>();
    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
    const [usedLeaveDate, setUsedLeaveDate] = useState<{ date: string, status: string }[]>([{ date: "", status: "" }]);
    const [senLeaveRequestButton, setSenLeaveRequestButton] = useState(false);
    const { user } = useUserContext()
    const empID = empId ? empId : ""
    const isUser = user.emp_id === empID
    const [teammatesLoading, setTeammatesLoading] = useState(true)
    const { loadingLeave, leaveLogs, getLeaveLogs } = useLeaveLogs();
    const [leaveLogsState, setLeaveLogsState] = useState<LeaveLog[]>([]);

    useEffect(() => {
        if (user.id) {
            getLeaveLogs(user.id)
        }
    }, [user]);

    useEffect(() => {
        setLeaveLogsState(leaveLogs);
        usedLeaveCount(leaveLogs)
    }, [leaveLogs]);

    useEffect(() => {
        getHolidays()
        getTeamWorkingStatus(empID)
        if (user.id) {
            getLeaveBalance(user.id)
        }
    }, [empId, user]);

    const tileClassName = useCallback(({ date }: any) => {
        const d = FormattedYMDate(date)
        const dx = new Date(date)

        for (let i = 0; i < usedLeaveDate.length; i++) {
            const status = APPROVALS_STATUS[usedLeaveDate[i].status.replace(/\s+/g, '')]?.tag?.replace(/\s+/g, '')
            if (usedLeaveDate[i].date === d && holidayFilter(holidayList, date)) {
                return ['leave-holiday', status]
            }
            if (usedLeaveDate[i].date === d) {
                return [status]
            }
        }
        if (holidayFilter(holidayList, date)) {
            return ['leave-holiday']
        }

        if (dx.getDay() === 6) {
            return ["saturday-class"]
        }
        return null
    }, [holidayList, usedLeaveDate, setUsedLeaveDate, leaveLogs, usedLeaveCount]);

    const fromDate = new Date(value?.toString().split(",")[0]!)
    const toDate = new Date(value?.toString().split(",")[1] ? value?.toString().split(",")[1] : value?.toString().split(",")[0]!)

    const handleOnChangeLeaveFrom = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 150) {
            setLeaveAllocationForm({
                ...leaveAllocationFrom,
                [e.target.name]: e.target.value
            })
        } else {
            toast.error(`Limit reached: Maximum ${150} characters allowed.`);
        }
    }

    const handleLeaveRegion = (event: any) => {
        setLeaveAllocationForm({
            ...leaveAllocationFrom,
            reason: event.target.value
        })
    }

    const handleSendLeaveRequest = () => {
        setSenLeaveRequestButton(true)
        const fDate = convertToISOFormat2(fromDate)
        const tDate = convertToISOFormat2(toDate)
        if (fDate === "" || tDate === "" || leaveAllocationFrom.reason === "" || leaveAllocationFrom.subject === "") {
            setSenLeaveRequestButton(false)
            toast.error("Please fill the mandatory fields", { autoClose: 5000 });
            return
        }
        const setLeaveApproval = async () => {
            const leaveForm = {
                employee_id: user.id,
                subject: leaveAllocationFrom.subject,
                reason: leaveAllocationFrom.reason,
                start_date: fDate,
                end_date: tDate,
                leave_type: leaveAllocationFrom.leave_type
            }
            const data: any = await hrApi.createLeave(leaveForm)
            if (data) {
                const request: LeaveLog = data.request
                request.status = "PENDING"
                request.duration = countLeaveDays(fromDate, toDate, holidayList)
                setLeaveLogsState((privet) => [...privet, request])
                onChange(new Date());
                toast.success("leave applied successfully!", { autoClose: 3000 })
                usedLeaveCount(leaveLogs)
                setLeaveAllocationForm(defaultLeaveAllocation)
                setLeaveRequestAllocation(false)
            }
            setSenLeaveRequestButton(false)
        }
        setLeaveApproval()
    }

    const getHolidays = async () => {
        const holidays: any = await hrApi.getHolidays()
        if (holidays) {
            setHolidayList(holidays.holidays)
        }
    }

    const getTeamWorkingStatus = async (empID: string) => {
        const data: any = await hrApi.getTeamMembers(empID)
        if (data && data.team_members) {
            setTeamWorkingStatus(data.team_members)
            setTeammatesLoading(false)
        } else {
            setTeammatesLoading(false)
        }
    }

    const getLeaveBalance = async (empID: string) => {
        const data: any = await hrApi.getLeaveBalance(empID)
        if (!!data) {
            setLeaveCounts(data.balance)
        }
    }

    const handleCancelLeave = () => {
        setLeaveRequestAllocation(false);
        onChange(new Date());
        setLeaveAllocationForm(defaultLeaveAllocation)
    }

    const handleLeaveTypes = (type: string) => {
        const selectedLeave = LeavesType.find(leave => leave.name === type);
        if (selectedLeave) {
            setLeaveAllocationForm({
                ...leaveAllocationFrom,
                leave_type: selectedLeave // Use the selected leave type's name
            });
        }
    }

    function usedLeaveCount(data: any) {
        let dateRange: { date: string, status: string }[] = []
        data.map((item: any) => {
            switch (item.status) {
                case APPROVALS.Declined:
                case APPROVALS.Revoked:
                    break;
                case APPROVALS.PartiallyApproved:
                    const partially = item.approvedDates.map((date: any) => {
                        return { date: FormattedYMDate(date), status: item.status }
                    })
                    dateRange = dateRange.concat(partially)
                    break;
                case APPROVALS.Approved:
                case APPROVALS.Pending:
                    const itemDateRange = getDateRange(item.start_date, item.end_date, holidayList).map((date) => {
                        return { date: FormattedYMDate(date), status: item.status }
                    })
                    dateRange = dateRange.concat(itemDateRange)
                    break;
                default:
                    break;
            }
            return null
        })
        setUsedLeaveDate(dateRange)
    }

    const handleConfirmRevokeLeave = () => {
        setIsShowConfirmDialog(false)
        const revokeLeaveRequest = async () => {
            if (selectRevokeItem) {
                const leaveInfo: any = await hrApi.revokeLeaveRequest(selectRevokeItem.id)
                if (leaveInfo) {
                    toast.success("Leave canceled successfully!", { autoClose: 3000 })
                    const revokeLeaveRemoved = leaveLogs.map((item2) => {
                        if (selectRevokeItem.id === item2.id) {
                            item2.status = APPROVALS.Revoked
                        }
                        return item2
                    })
                    setLeaveLogsState(revokeLeaveRemoved)
                    usedLeaveCount(revokeLeaveRemoved)
                }
            }
        }
        revokeLeaveRequest()
    }

    const handleRevokeLeave = (item: LeaveLogNodeProps) => {
        setIsShowConfirmDialog(true)
        setSelectRevokeItem(item)
    }

    const leaveType: string = leaveAllocationFrom.leave_type.name
    const result: LeaveBalance | undefined = leaveCounts.find(record => record.LeaveType.name === leaveType);
    const leaveRemaining = result ? result.TotalBalance - result.Balance - countLeaveDays(fromDate, toDate, holidayList) : 0

    return (
        <Layout>
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">Leave Management</h2>
                <div>
                    {isUser && <Button onClick={() => { setLeaveRequestAllocation(true); }}>Apply</Button>}
                </div>
            </div>
            <div className="leave-balance">
                {leaveCounts.length ? leaveCounts.map((item, key) => {
                    const iconName = item.LeaveType.name.replaceAll(" ", "") as keyof typeof iconsLeaveBalance;
                    return (
                        <LeaveCard
                            key={key}
                            title={item.LeaveType.name}
                            svg={iconsLeaveBalance[iconName]}
                            totalLeave={iconName === "WFH" ? item.Balance : item.TotalBalance}
                            taken={item.Balance}
                        />
                    )
                }) : [0, 1, 2, 3].map((item) => <LeaveCardSkeleton key={item} />)}
            </div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-4 min-h-60">
                    {holidayList && <UpcomingHoliday holidays={holidayList} />}
                </div>
                <div className="col-span-8 shadow-lg rounded-xl px-4 py-3 bg-white">
                    <h2 className="h2">Team Availability</h2>
                    <div className="grid grid-cols-3 justify-between gap-y-2 mt-4 max-h-64 overflow-auto scroll-bar-width">
                        {teammatesLoading ? [1, 2, 3, 4].map((item, key) => {
                            return <TeamMemberSkeleton key={key} />
                        }) :
                            teamWorkingStatus.length ? teamWorkingStatus.map((item: any, key) => {
                                return (
                                    <TeamMember key={key} name={item.name} image={item.image} workStatus={item.workStatus} designation={item.designation} />
                                )
                            }) : <div className="flex items-center justify-center h-full col-span-3">
                                <div className="text-center py-2">
                                    <h2 className="text-xl font-semibold text-gray-600-600">No data found</h2>
                                    <p className="text-gray-500">Please try again later.</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-span-12 shadow-lg rounded-xl px-4 py-3 bg-white">
                    <h2 className="h2">Current Leave Application</h2>
                    <div className='leave-grid bg-[#F7F9FCCC] p-2 border-b mt-4'>
                        {leaveTitle.map((item, key) => {
                            return (
                                <div key={key} className="text-[#687182] font-medium text-sm uppercase text-center">
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                    <div className="max-h-[500px] overflow-auto scroll-bar-width">
                        <ComponentLoading
                            isLading={loadingLeave === 'loading'}
                            error={loadingLeave === 'error'}
                            children={leaveLogsState?.map((item, key) => {
                                return (
                                    <LeaveLogNode
                                        key={key}
                                        {...item}
                                        sn={key + 1}
                                        handleRevokeLeave={handleRevokeLeave}
                                        isUser={isUser}
                                    />
                                )
                            })}
                            childrenSkeleton={<LeaveGridItemSkeleton />}
                        />
                    </div>
                </div>
            </div>

            <ModalBox isOpen={leaveRequestAllocation} onClose={() => { setLeaveRequestAllocation(false) }}>
                <div className="flex justify-between items-center mb-5">
                    <h2 className="h2 w-96 mb-1">Leave Request</h2>
                    <CrossButton onClick={handleCancelLeave} />
                </div>
                <div className="flex gap-4">
                    <form action="" className="w-[100%]">
                        <div className="mb-3">
                            <label htmlFor="LeaveType" className="text-[#344054] text-sm font-medium block mb-2">Leave Type <span className='text-red-600'>*</span></label>
                            <DropdownButton
                                title={leaveAllocationFrom.leave_type.name}
                                options={LeavesType.map(item => item.name)}
                                sendSelectOption={handleLeaveTypes}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Date" className="text-[#344054] text-sm font-medium block mb-2">Date<span className='text-red-600'>*</span></label>
                            <p className="border border-[#D0D5DD] px-3 py-1 rounded-md h-[34px] text-base font-normal text-[#667085]">
                                {FormattedDdMmDate(fromDate.toString())} - {FormattedDdMmDate(toDate.toString())}
                            </p>
                        </div>
                        <InputElement name='Subject' onChange={handleOnChangeLeaveFrom} value={leaveAllocationFrom.subject} placeholder='Subject' />
                        <label htmlFor="Reason" className="text-[#344054] text-sm font-medium block mb-2">Reason<span className='text-red-600'>*</span></label>
                        <TextAreaInput
                            name="reason"
                            rows={3}
                            maxLength={800}
                            onChange={handleLeaveRegion}
                            value={leaveAllocationFrom.reason}
                            placeholder='Write a few sentences about the leave reason..........'
                        />
                        <div className="text-right text-xs text-[#667085]">
                            {leaveAllocationFrom.reason.length}/{800} characters
                        </div>
                    </form>
                    <div className="w-[100%] border border-[#D0D5DD] rounded-md px-3 py-3">
                        <Calendar
                            className={"leave-request"}
                            calendarType='iso8601'
                            onChange={onChange}
                            value={value}
                            tileClassName={tileClassName}
                            goToRangeStartOnSelect={true}
                            selectRange={true}
                            allowPartialRange={true}
                            returnValue="range"
                            minDate={new Date()}
                        />
                    </div>
                </div>
                <div className="bg-[#AD9FFF12] p-3 mt-3 rounded-lg min-h-32">
                    <h3 className="h3 mb-1">Note</h3>
                    <p className="text-gray-600 text-sm font-normal leading-tight tracking-tight">
                        You are requesting <b>{countLeaveDays(fromDate, toDate, holidayList)}</b>  working days off from {FormattedDdMmDate(fromDate.toString())} to {FormattedDdMmDate(toDate.toString())}.
                    </p>
                    <p className="text-gray-800 text-sm font-medium leading-tight tracking-tight mt-1">
                        {countLeaveDays(fromDate, toDate, holidayList)} days of total {leaveAllocationFrom.leave_type.name}
                    </p>
                    <p className={`${leaveRemaining < 0 ? "text-red-600" : "text-gray-600"} mt-1 mb-1`}>
                        {leaveAllocationFrom.leave_type.name !== "WFH" ? leaveRemaining + " days remaining" : ""}
                    </p>
                </div>
                <div className="flex justify-end gap-4 mt-6 ">
                    <div className="flex justify-end gap-4 mt-6 ">
                        <Button variant="outline" onClick={handleCancelLeave} >Cancel</Button>
                        <Button onClick={handleSendLeaveRequest} isLoading={senLeaveRequestButton}>Send Request</Button>
                    </div>
                </div>
            </ModalBox>
            <ConfirmDialog isOpen={isShowConfirmDialog}
                onConfirm={handleConfirmRevokeLeave}
                message={`Are you sure you want to revoke your leave request?\nThis action cannot be undone.`}
                title={'Revoke Leave'}
                isCancel={() => { setIsShowConfirmDialog(false); }}
                loading={false}
            />
        </Layout>
    );
}

export default LeaveManagement;

export const HolidayTag = () => {
    return (
        <div className="absolute top-0 right-0 w-0 h-0  border-l-[12px] border-l-transparent border-t-[12px] border-t-red-600 "></div>
    )
}

const iconsLeaveBalance = {
    PaidLeave: <PaidLeave />,
    SickLeave: <SickLeave />,
    CasualLeave: <CasualLeave />,
    WFH: <WFH />,
}

const leaveTitle = ["#", "Start Date", "End date", "Leave type", "Subject", "approved status", "count", "Action"]

const LeaveGridItemSkeleton = () => {
    return (
        <div className='leave-grid p-2 border-b animate-pulse'>
            <div className='bg-gray-300 text-center py-1 flex items-center justify-center rounded-md w-full h-6'></div>
            <div className='bg-gray-300 text-center py-1 flex items-center justify-center rounded-md w-full h-6'></div>
            <div className='bg-gray-300 text-center py-1 flex items-center justify-center rounded-md w-full h-6'></div>
            <div className='bg-gray-300 text-center py-1 flex items-center justify-center rounded-md w-full h-6'></div>
            <div className='bg-gray-300 text-center py-1 flex items-center justify-center rounded-md w-full h-6'>
                <div className='bg-gray-400 py-1 px-3 rounded-md inline-block w-full h-full'></div>
            </div>
            <div className='bg-gray-300 text-center flex items-center justify-center rounded-md w-full h-6'></div>
            <div className='bg-gray-300 text-center flex items-center justify-center rounded-md w-full h-6'></div>
            <div className='bg-gray-300 text-center flex items-center justify-center rounded-md w-full h-6'>
                <div className='bg-gray-400 py-1 px-3 rounded-md inline-block w-full h-full'></div>
            </div>
        </div>
    );
};