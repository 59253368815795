import React from "react";
import { CustomCard } from "../../card";
import { DotList } from "../dot-list";
import { COLOR_CODE } from "../../../constants";
import DataTable from "../../table";
import "./index.css"
import { CircularProgressBar } from "../circular-progress-bar";
import { WorkingHistoryType } from "../../../types";
import { calculateCompletionPercentage, calculateDurationTime, convertToISOFormat2, formatTo12HourTime, getDateAndMonth } from "../../../utils/helpers";

interface WorkingHistoryProps {
    working: WorkingHistoryType[]
    loading: string
}

const WorkingHistory: React.FC<WorkingHistoryProps> = ({ working, loading }) => {

    const headers: any = [
        { key: 'date', label: 'Date' },
        { key: 'arrival', label: 'Arrival' },
        { key: 'departure', label: "Departure" },
        { key: 'effectiveTime', label: "Effective Time" },
    ];

    const EffectiveTime = (duration: string, progress: number) => {
        return (
            <div className="flex gap-3">
                <div className="w-20">
                    <p className="text-[13px] text-[#1D1027E5] font-bold">{duration} hours</p>
                    <p className="text-xs text-[#1D102766] font-semibold">/ 9 hours</p>
                </div>
                <CircularProgressBar
                    progress={progress > 100 ? 100 : progress}
                    strokeWidth={4}
                    size={45}
                    color={getProgressColor(progress)}
                />
            </div>
        )
    }

    const data = working.map((item, key) => {
        const workingDate = new Date(item.date)
        const t = new Date()
        const sameDay = getDateAndMonth(workingDate.toString()) === getDateAndMonth(t.toString())
        const checkOut: any = item.check_in === item.check_out && sameDay ? convertToISOFormat2(t) : item.check_out
        const effective_time = calculateDurationTime(item.check_in, checkOut)
        const p = calculateCompletionPercentage(item.check_in, checkOut)
        const departure = item.check_in === item.check_out ?
            sameDay ?
                "- Still in office -" : "- Absent -" : formatTo12HourTime(item.check_out)

        return {
            id: key,
            date: getDateAndMonth(item.date),
            arrival: formatTo12HourTime(item.check_in),
            departure: departure,
            effectiveTime: EffectiveTime(effective_time, p),
        }
    })

    return (
        <CustomCard className="col-span-2">
            <h2 className="heading2">Working History</h2>
            <div className="flex justify-start gap-5 items-center my-4">
                {header.map((item, key) => <DotList key={key} {...item} />)}
            </div>
            <div className="overflow-auto">
                <DataTable
                    className={""}
                    headers={headers}
                    loading={loading === "loading"}
                    data={data}
                    pages={true}
                />
            </div>
        </CustomCard >
    );
}

export default WorkingHistory;

function getProgressColor(progress: number): string {
    if (progress < 22.22) {
        return COLOR_CODE.red; // Action Needed
    } else if (progress >= 22.22 && progress < 100) {
        return COLOR_CODE.yellow; // Criteria Unmet
    } else if (progress === 100) {
        return COLOR_CODE.blue; // Meeting Criteria
    } else if (progress > 100) {
        return COLOR_CODE.orange; // Overtime
    } else {
        throw new Error("Invalid progress value");
    }
}

const header = [
    {
        name: "Meeting Criteria",
        color: COLOR_CODE.blue
    },
    {
        name: "Criteria Unmet",
        color: COLOR_CODE.yellow
    },
    {
        name: "Action Needed",
        color: COLOR_CODE.red
    },
    {
        name: "Overtime",
        color: COLOR_CODE.orange
    },
    {
        name: "Absent",
        color: COLOR_CODE.absent
    }
]