import Cookies from 'js-cookie';
import { MONTHS_SOT } from '../constants';
import { RouteConfig } from '../config/private-routes-config';
import { HolidayList } from '../types';

export function UserType(user: string) {
    return user === "admin"
}

export function convertDateFormat(startDateStr: string, endDateStr: string): string {
    const targetDate = new Date(startDateStr)
    const endTargetDate = new Date(endDateStr)
    const currentDate = new Date()
    const day = targetDate.getDate()

    if (
        targetDate.getDate() === currentDate.getDate() &&
        targetDate.getMonth() === currentDate.getMonth() &&
        targetDate.getFullYear() === currentDate.getFullYear() && startDateStr === endDateStr
    ) {
        return "Today";
    }
    const isDateEqual = areDatesEqual(startDateStr, endDateStr)
    if (isDateEqual) {
        const month = MONTHS_SOT[targetDate.getMonth()]
        const year = targetDate.getFullYear().toString().slice(-2)
        const daySuffix = getDaySuffix(day)

        return `${day}<sup>${daySuffix}</sup> ${month}, ${year}`
    } else {
        const endDay = endTargetDate.getDate();

        const startSuffix = getDaySuffix(day);
        const endSuffix = getDaySuffix(endDay);

        return `${day}<sup>${startSuffix}</sup> to ${endDay}<sup>${endSuffix}</sup>`;
    }
}

function getDaySuffix(day: number): string {
    if (day >= 11 && day <= 13) {
        return "th";
    }
    switch (day % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

function areDatesEqual(dateString1: string, dateString2: string): boolean {
    const dateObject1 = new Date(dateString1);
    const dateObject2 = new Date(dateString2);

    const year1 = dateObject1.getFullYear();
    const month1 = dateObject1.getMonth();
    const day1 = dateObject1.getDate();

    const year2 = dateObject2.getFullYear();
    const month2 = dateObject2.getMonth();
    const day2 = dateObject2.getDate();

    return year1 === year2 && month1 === month2 && day1 === day2;
}

export const base64ToUint8Array = (base64: string): Uint8Array => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
};

export const setCookieForToday = (name: string, value: any): void => {
    const now = new Date();
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2, 0, 0, 0);
    const expiresInMilliseconds = midnight.getTime() - now.getTime();
    const expiresInDays = expiresInMilliseconds / (1000 * 60 * 60 * 24);

    Cookies.set(name, value, { expires: 2 });
};

export const getCookie = (name: string): string | null => {
    const cookieValue = Cookies.get(name);
    if (cookieValue) {
        return cookieValue
    }
    return null
};

export const convertToISOFormat = (dateString: Date): string => {
    const date = new Date(dateString);
    // Adjust the date to UTC by setting the hours to the local time zone offset
    date.setUTCHours(0, 0, 0, 0);
    date.setUTCDate(date.getUTCDate() + 1); // Adjust to the next day
    return date.toISOString().slice(0, 19) + "Z";
};

export const convertToISOFormat2 = (dateString: Date | string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
};

// rbac code ---

export function isArrayWithLength(arr: string | any[]) {
    return (Array.isArray(arr) && arr.length)
}

export function getAllowedRoutes(routes: RouteConfig[]): RouteConfig[] {
    const localUser = localStorage.getItem("AuxilioUser2");
    const jsonString = atob(localUser || "");
    const storedUser = localUser ? JSON.parse(jsonString) : {};
    const roles: string[] = storedUser ? [storedUser.role] : []

    return routes.filter(({ permission }) => {
        if (!permission) return true;
        if (!isArrayWithLength(permission)) return true;

        return permission.some((perm) => roles.includes(perm));
    });
}

export function getGreeting(): string {
    const currentHour: number = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
        return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
        return "Good Afternoon";
    } else {
        return "Good Evening";
    }
}

export function fullName(firstName: string, lastName: string): string {
    return firstName + " " + lastName
}

export function formatDateDMonth(inputDate: string): string {
    const date = new Date(inputDate);
    const day = date.getUTCDate(); // Get the day of the month
    const month = date.toLocaleString('default', { month: 'short' }); // Get the short month name
    return `${day} ${month}`;
}

export function formatDateDMonthY(inputDate: string): string {
    const date = new Date(inputDate);
    const day = date.getUTCDate(); // Get the day of the month
    const month = date.toLocaleString('default', { month: 'short' }); // Get the short month name
    const year = date.getUTCFullYear(); // Get the full year
    return `${day < 9 ? "0" + day : day} ${month} ${year}`;
}

export function getDateRange(startDateStr: string, endDateStr: string, holidayList: HolidayList[]): string[] {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const dateRange: string[] = [];

    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        const dateString = FormattedYMDate(date.toString());
        if (!holidayFilter(holidayList, date.toString()) && date.getDay() !== 0) {
            dateRange.push(dateString);
        }
    }

    return dateRange;
}

export const formattedDate = (d: Date) => {
    const date = new Date(d);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function countLeaveDays(startDate: Date, endDate: Date, holidays: HolidayList[] = []): number {
    let count = 0;
    let currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
        const dayOfWeek = currentDate.getDay();
        // if (dayOfWeek !== 0 && dayOfWeek !== 6 && !holidayFilter(holidays, currentDate.toString())) {
        //     count++; Saturdays  not include
        // }
        if (dayOfWeek !== 0 && !holidayFilter(holidays, currentDate.toString())) {
            count++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return count;
}

export const FormattedDdMmDate = (date: string) => {
    const d = new Date(date)
    const formattedDate = `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;

    return formattedDate
}

export const FormattedYMDate = (date: string) => {
    const d = new Date(date)
    const formattedDate = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;

    return formattedDate
}

export const holidayFilter = (holidayList: HolidayList[], date: string): boolean => {
    const d = new Date(date)
    for (let i = 0; i < holidayList.length; i++) {
        const holiday = new Date(holidayList[i].date)
        const isHoliday = d.getDate() === holiday.getDate() && d.getMonth() === holiday.getMonth() && d.getFullYear() === holiday.getFullYear()
        if (isHoliday) {
            return isHoliday
        }
    }
    return false
}

export function formatToHoursAndMinutes(dateString: string): string {
    const date = new Date(dateString);

    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    return `${hours}h ${minutes} min`;
}

export function formatTo12HourTime(dateString: string): string {
    const date = new Date(dateString);

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour format

    return `${hours}:${minutes} ${amPm}`;
}

interface AllowedUserProps {
    permissions: string[];
    children: React.ReactNode; // Accept children as a prop
}

export const AllowedUser: React.FC<AllowedUserProps> = ({ permissions, children }) => {
    const localUser = localStorage.getItem("AuxilioUser2");
    const jsonString = atob(localUser || "");
    const storedUser = localUser ? JSON.parse(jsonString) : {};
    const roles: string[] = storedUser ? [storedUser.role] : [];

    const hasAccess = permissions.some((perm) => roles.includes(perm));

    return hasAccess ? <>{children}</> : null; // Render children if access is granted
};

export function calculateDurationTime(checkIn: string, checkOut: string): string {
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);

    const durationMs = checkOutDate.getTime() - checkInDate.getTime();

    const hours = Math.floor(durationMs / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
}

export function calculateCompletionPercentage(checkIn: string, checkOut: string, targetHours: number = 9): number {
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);

    const durationHours = (checkOutDate.getTime() - checkInDate.getTime()) / (1000 * 60 * 60);

    const percentage = (durationHours / targetHours) * 100;

    return parseFloat(percentage.toFixed(2));
}

export const getDateAndMonth = (d: string): string => {
    const date = new Date(d);

    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
    });
};

export function DeleteAllCookies() {
    document.cookie.split(';').forEach(cookie => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
}