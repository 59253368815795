import EmployeeBirthday from '../../components/common/employee-birthday';
import { useEffect, useState } from 'react';
import { HolidayList, MetricType, TodayStatusCounts, WorkingHistoryType } from '../../types';
import 'react-calendar/dist/Calendar.css';
import { useUserContext } from '../../context/user';
import Layout from '../../layout';
import hrApi from '../../services/hr-module';
import { TimeCard } from '../../components/common/time-card';
import { AccordionGroup } from '../../components/accordion';
import { CustomCard } from '../../components/card';
import EmployeeAttendance from '../../components/common/employee-attendance';
import { LeaveStatus, SortLeaveStatusSkeleton } from '../../components/common/leave-status';
import UpcomingHolidays from '../../components/common/upcoming-holiday';
import { formattedDate, getDateRange } from '../../utils/helpers';
import useLeaveLogs from '../../hooks/use-leaves';
import { ComponentLoading } from '../../components/component-loading';
import MarkPresent from '../../components/common/mark-present';
import WorkingHistory from '../../components/common/working-history';
import { date } from '../../constants';

const UserDashboard = () => {
    const { user } = useUserContext();
    const [holidayList, setHolidayList] = useState<HolidayList[]>([])
    const { loadingLeave, leaveLogs, getLeaveLogs } = useLeaveLogs()
    const [yearStatusCounts, setYearStatusCounts] = useState<TodayStatusCounts>({ absent: 0, late: 0, on_time: 0, wfh: 0, total: 0 })
    const [workingHistoryLog, setWorkingHistoryLog] = useState<WorkingHistoryType[]>([]);
    const [workingHistoryLoading, setWorkingHistoryLoading] = useState<'loading' | 'error' | 'success'>('loading')
    const [metric, setMetric] = useState<MetricType>({ average_check_in: "", average_check_out: "", average_hours: "", average_on_time: "" });
    const [availabilityStatusLoading, setAvailabilityStatusLoading] = useState<boolean>(true);

    const getHolidays = async () => {
        const holidays: any = await hrApi.getHolidays()
        if (!!holidays) {
            setHolidayList(holidays.holidays)
        }
    }

    const getWorkingHistory = async (id: string) => {
        const firstDateOfMonth = new Date();
        firstDateOfMonth.setDate(1);
        const today = new Date();
        const data: any = await hrApi.getWorkingHistory(id, formattedDate(firstDateOfMonth), formattedDate(today))

        if (data && data.availabilities) {
            setWorkingHistoryLoading("success")
            setWorkingHistoryLog(data.availabilities)
        } else {
            setWorkingHistoryLoading("error")
        }
    }

    const getEmployeeYearCount = async (empID: string) => {
        const data: any = await hrApi.getEmployeeYearCount(empID)
        if (data && data.availability_status) {
            setAvailabilityStatusLoading(false)
            setYearStatusCounts((prev) => ({
                ...prev,
                ...data.availability_status,
                total: 365
            }))
        } else {
            setAvailabilityStatusLoading(false)
        }
    }

    const getMetrics = async (id: string) => {
        const data: any = await hrApi.getMetrics(id)
        if (data && data.employee_metrics) {
            setMetric(data.employee_metrics)
        }
    }

    useEffect(() => {
        if (user.id) {
            getHolidays()
            getLeaveLogs(user.id)
            getEmployeeYearCount(user.id)
            getWorkingHistory(user.id)
            getMetrics(user.id)
        }
    }, [user]);

    return (
        <Layout>
            <div className="flex justify-between items-center mb-5">
                <h2 className="text-xl font-semibold capitalize">My monthly attendance</h2>
            </div>
            <div className="grid grid-cols-4 gap-6 mb-6">
                <TimeCard colorClass="fill-[#684A7E]" title="Average Hours" value={metric.average_hours} />
                <TimeCard colorClass="fill-[#14804A]" title="On-time Arrival" value={metric.average_on_time} />
                <TimeCard colorClass="fill-[#14804A]" title="Average Check-in" value={metric.average_check_in + " min"} />
                <TimeCard colorClass="fill-[#FF3D00]" title='Average Check-out' value={metric.average_check_out + " min"} />
            </div>
            <div className="grid grid-cols-12 gap-6 items-start">
                <div className="col-span-8 grid grid-cols-2 gap-6">
                    <MarkPresent />
                    {yearStatusCounts &&
                        <EmployeeAttendance
                            loading={availabilityStatusLoading}
                            tag={date.getFullYear().toString()}
                            onTime={yearStatusCounts.on_time}
                            absent={yearStatusCounts.absent}
                            totalEmp={yearStatusCounts.total}
                            wfh={yearStatusCounts.wfh}
                            lateAttendance={yearStatusCounts.late}
                        />}
                    <WorkingHistory working={workingHistoryLog} loading={workingHistoryLoading} />
                </div>
                <div className="col-span-4 grid gap-6">
                    <EmployeeBirthday />
                    <CustomCard>
                        <h2 className="heading2">Approval Status</h2>
                        <AccordionGroup accordions={[
                            {
                                title: "Leave Status",
                                content:
                                    <ComponentLoading
                                        isLading={loadingLeave === 'loading'}
                                        error={loadingLeave === 'error' || !leaveLogs.length}
                                        children={leaveLogs?.map((item, key) => {
                                            const dateRange = getDateRange(item.start_date, item.end_date, holidayList);
                                            return (
                                                <LeaveStatus key={key} leave={item} dateRange={dateRange} userId={''} isExpanded={false} isView />
                                            )
                                        })}
                                        childrenSkeleton={<SortLeaveStatusSkeleton />}
                                    />
                            }
                        ]} />
                    </CustomCard>
                    <UpcomingHolidays holidays={holidayList} />
                </div>
            </div >
        </Layout >
    );
}

export default UserDashboard;