import React from "react"
import { CustomCard } from "../../card"
import { DoughnutChart } from "../../chart/doughnut-chart"
import { Button } from "../buttons"

export type EmployeeAttendanceWeekProps = {
    on_leave: number
    wfh: number
    wfo: number
}

const EmployeeAttendanceWeek: React.FC<EmployeeAttendanceWeekProps> = (props) => {
    const loading = !props.on_leave && !props.wfh && !props.wfo;

    return (
        <CustomCard>
            <div className="flex justify-between gap-3 items-center pb-4 mb-2">
                <h2 className="heading2">Employee Attendance</h2>
                <Button variant="outline" size="small">Last Week</Button>
            </div>
            <div className="flex items-center justify-center">
                <div className="max-w-60">
                    {loading ? <ChartSkelton /> : <DoughnutChart {...props} />}
                </div>
            </div>
        </CustomCard>
    )
}

export default EmployeeAttendanceWeek;

function ChartSkelton() {
    return (
        <div className="flex items-center justify-center">
            <div className="relative w-60 h-60 animate-pulse">
                <div className="absolute inset-0 rounded-full bg-gray-300"></div>
                <div className="absolute inset-10 rounded-full bg-white"></div>
            </div>
        </div>
    )
}