import { Bars3Icon, SquaresPlusIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { getCookie, setCookieForToday } from "../../../utils/helpers";
import { Bill } from "../../../types";
import InvoiceHeader from "../../../components/invoice-header";
import DropdownButton from "../../../components/common/dropdown-button";
import { Organization, stateOfIndia } from "../../../constants";
import FormInput, { Textarea } from "../../../components/common/form-input";
import DragAndDropUpload from "../../../components/drag-and-drop-upload";
import UploadPdfPreview from "../../../components/upload-pdf-preview";
import { Button } from "../../../components/common/buttons";
import InvoiceModel from "../../../components/invoice-model";
import financeApi from "../../../services/finance";
import Datepicker from "react-tailwindcss-datepicker";

const defaultServices = {
    cgst: 0,
    igst: 0,
    sgst: 0,
    country: "India",
    state: "Gujarat",
    is_draft: false,
    services: [
        {
            no: 0,
            id: "",
            description: "",
            hsn_acs: "",
            unit_price: 0,
            quantity: 0,
            amount: 0,
            cgst: 0,
            sgst: 0,
            igst: 0
        }
    ]
}

const CreateBill = () => {
    const { billNo } = useParams()
    const { register, handleSubmit, watch, setValue, control, formState: { errors }, reset } = useForm<Bill>({
        defaultValues: defaultServices
    });
    const navigate = useNavigate()
    const [checkedState, setCheckedState] = useState([false, false, false, false])
    const { fields, append, remove, swap, } = useFieldArray({
        control,
        name: 'services',
    });
    const [isOpenBillModel, setIsOpenBillModel] = useState(false);
    const [dragIndex, setDragIndex] = useState<number | null>(null)
    const [isShowDraftModel, setIsShowDraftModel] = useState(false)
    const [isEditBill, setIsEditBill] = useState(false);
    const [isFormSubmit, setIsFormSubmit] = useState(false)
    const draftData = getCookie("bills")
    const draftBills: Bill[] = draftData ? JSON.parse(draftData) : []
    const isGj = watch("state") === "Gujarat";
    const [billDate, setBillDate] = useState({
        startDate: null,
        endDate: null
    });
    const [attachment, setAttachment] = useState({ name: "", size: 0 })

    const onDragStart = (index: number) => {
        setDragIndex(index);
    };

    const onDragOver = (index: number) => {
        if (dragIndex === null || dragIndex === index) {
            return;
        }
        swap(dragIndex, index);
        setDragIndex(index);
    };

    const onDragEnd = () => {
        setDragIndex(null);
    };

    const handleCheckboxChange = (index: number) => {
        const newCheckedState = [...checkedState];
        newCheckedState[index] = !newCheckedState[index];
        setCheckedState(newCheckedState);
    }

    const handleDateChange = (date: any) => {
        const d = new Date(date?.startDate)
        if (d) {
            setValue("bill_date", d.toISOString().split('.')[0] + 'Z')
        }
        setBillDate(date)
    }

    const handleAddNewRow = () => append({
        no: fields.length + 1,
        id: "",
        description: "",
        hsn_acs: "",
        unit_price: 0,
        quantity: 0,
        amount: 0,
        cgst: 0,
        sgst: 0,
        igst: 0
    })

    const handleConfirmBack = () => {
        if (!isEditBill) {
            navigate("/bills")
        } else {
            setIsShowDraftModel(false)
        }
    }

    const handleSaveAsDraft = () => {
        if (!isEditBill) {
            setValue("is_draft", true)
            const billForm = watch()
            if (billForm.bill_no) {
                const index = draftBills.findIndex(bill => bill.bill_no === billForm.bill_no);

                if (index !== -1) {
                    draftBills[index] = billForm;
                } else {
                    draftBills.push(billForm);
                }
                setCookieForToday("bills", JSON.stringify(draftBills))
                toast.success("Bill save as draft", { autoClose: 3000 })
                navigate("/bills")
            } else {
                setIsShowDraftModel(false)
                toast.error("Bill No is required for save as draft", { autoClose: 3000 })
            }
        } else {
            navigate("/bills")
        }
    }

    const watchFields = watch("services")

    const setBill = async (data: Bill) => {
        setIsFormSubmit(true)
        data.services.forEach((service) => {
            service.cgst = Number(service.cgst);
            service.sgst = Number(service.sgst);
        });
        data.pin_code = String(data.pin_code);
        data.contact_no = String(data.contact_no);

        const response = await financeApi.setBill(data)
        if (response) {
            setIsFormSubmit(false)
            toast.success("Create bill successfully", { autoClose: 3000 });
            setIsOpenBillModel(true)
        } else {
            setIsFormSubmit(false)
        }
    }

    const billId = watch("bill_no")

    const updateBill = async (data: Bill) => {
        setIsFormSubmit(true)
        data.services.forEach((service) => {
            service.cgst = Number(service.cgst);
            service.sgst = Number(service.sgst);
            service.igst = Number(service.igst);
        });
        data.pin_code = String(data.pin_code);
        data.contact_no = String(data.contact_no);

        const response = await financeApi.setBill(data)
        if (response) {
            setIsFormSubmit(false)
            toast.success("update bill successfully", { autoClose: 3000 });
            setIsOpenBillModel(true)
        } else {
            setIsFormSubmit(false)
        }
    }

    let totalTax = 0

    watchFields.forEach((row, index) => {
        const unitPrice = row.unit_price
        const quantity = row.quantity
        const amount = unitPrice * quantity
        const cgst = Math.floor(amount * Number(row.cgst) / 100)
        const sgst = Math.floor(amount * Number(row.sgst) / 100)
        const igst = isGj ? 0 : Math.floor(amount * Number(row.igst) / 100)

        totalTax = totalTax + cgst + sgst + igst
    })

    useEffect(() => {
        let subTotal = 0
        let totalTax = 0
        let totalCgst = 0
        let totalSgst = 0
        let totalIgst = 0

        watchFields.forEach((row, index) => {
            const unitPrice = row.unit_price
            const quantity = row.quantity
            const amount = unitPrice * quantity
            const cgst = isGj ? Math.floor(amount * Number(row.cgst) / 100) : 0
            const sgst = isGj ? Math.floor(amount * Number(row.sgst) / 100) : 0
            const igst = isGj ? 0 : Math.floor(amount * Number(row.igst) / 100)

            totalTax = totalTax + cgst + sgst + igst
            subTotal = subTotal + amount
            totalCgst = totalCgst + cgst
            totalSgst = totalSgst + sgst
            totalIgst = isGj ? 0 : totalIgst + igst

            setValue(`services.${index}.unit_price`, Number(row.unit_price))
            setValue(`services.${index}.quantity`, Number(row.quantity))
            setValue(`services.${index}.amount`, Math.floor(amount))
        })
        setValue("sub_total", subTotal)
        setValue("cgst", totalCgst)
        setValue("sgst", totalSgst)
        setValue("igst", totalIgst)
    }, [watchFields, fields, totalTax, watch, isGj]);

    const handleOnSubmit: SubmitHandler<Bill> = (data) => {
        if (isEditBill) {
            updateBill(data)
        } else {
            setBill(data)
        }
    }

    const handleViewBill = () => {
        navigate("/bill-preview/" + billId)
        reset()
    }

    const handleUploadAttachment = (files: File[]) => {
        const file = files[0]
        if (file) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
                const base64String = btoa(
                    new Uint8Array(event.target.result)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                setAttachment({
                    name: file.name,
                    size: file.size / 1024
                })
                setValue("attachment", base64String)
            };
            reader.readAsArrayBuffer(file);
        }
    }

    const onCloseModel = () => {
        reset()
        setIsFormSubmit(false)
        setIsOpenBillModel(false)
    }

    useEffect(() => {
        if (billNo) {
            setIsEditBill(true)
            let billFound = false;

            for (let i = 0; i < draftBills.length; i++) {
                const element = draftBills[i];
                if (element.bill_no === billNo) {
                    billFound = true;
                    reset(element)
                    break;
                }
            }

            if (!billFound) {
                const getBill = async () => {
                    const data: any = await financeApi.getBill(billNo)
                    if (data && data.bill) {
                        const bill: Bill = data.bill
                        bill.services = bill.services.map(({ invoice_no, bill_no, ...rest }) => rest);
                        const getBillDate: any = { startDate: new Date(bill.bill_date), endDate: new Date(bill.bill_date) }
                        setBillDate(getBillDate)
                        reset(bill)
                    } else {
                    }
                }
                getBill()
            }
        }
    }, [billNo])

    const headingOfTable = ["Description of services", "HSN ACS", "Unit Price", "Quantity", "Amount"]
    const headingOfTableData = ["description", "hsn_acs", "unit_price", "quantity", "amount"]

    if (isGj) {
        headingOfTable.push("CGST(%)", "SGST(%)")
        headingOfTableData.push("cgst", "sgst")
    } else {
        headingOfTable.push("IGST(%)")
        headingOfTableData.push("igst")
    }

    const closeHandle = () => { setIsShowDraftModel(true) }
    const bDate = watch("bill_date")?.slice(0, 10)

    const handleChangeOnState = (option: string) => {
        if (option !== "Gujarat") {
            setValue("cgst", 0)
            setValue("sgst", 0)
        }
        setValue("state", option)
    }

    return (
        <div>
            <InvoiceHeader name="Create Bill" onClose={closeHandle} />
            <div className="py-6 px-6">
                <h1 className="text-[#1D1027] font-bold text-2xl mb-3">
                    Bill Details
                </h1>
                <form onSubmit={handleSubmit(handleOnSubmit)} >
                    <div className="grid grid-cols-4 gap-x-5 gap-y-3 overflow-auto  scroll-bar-width pb-4 pr-2">
                        <div className="flex flex-col">
                            <p className="modal-div mb-2">Company</p>
                            <DropdownButton
                                title={watch("company_name") ? watch("company_name") : "Company"}
                                options={Organization}
                                sendSelectOption={(option: string) => { setValue("company_name", option) }}
                            />
                            {errors && <p className="text-red-600 text-xs">{errors.company_name?.message}</p>}
                        </div>
                        <div className="flex flex-col">
                            <p className="modal-div">Date</p>
                            <Datepicker
                                containerClassName={"relative border rounded mt-2"}
                                primaryColor={"indigo"}
                                useRange={false}
                                asSingle={true}
                                value={billDate}
                                onChange={handleDateChange}
                            />
                            {errors && <p className="text-red-600 text-xs">{errors.bill_date?.message}</p>}
                        </div>
                        {formElement.slice(0, 11).map((item, key) => {
                            const error: any = errors[item.name]
                            return (
                                <FormInput
                                    key={key}
                                    label={item.label}
                                    register={register(item.name, {
                                        required: item.required, valueAsNumber: item.isNumber
                                    })}
                                    error={error}
                                    placeholder={item.placeholder}
                                />
                            )
                        })}
                        <div className="flex flex-col">
                            <p className="modal-div mb-2">State</p>
                            <DropdownButton
                                title={watch("state") ? watch("state") : "State"}
                                options={stateOfIndia}
                                sendSelectOption={handleChangeOnState}
                            />
                            {errors && <p className="text-red-600 text-xs">{errors.state?.message}</p>}
                        </div>
                        <div className="flex flex-col">
                            <p className="modal-div mb-2">Country</p>
                            <DropdownButton
                                title={watch("country") ? watch("country") : "Country"}
                                options={["India"]}
                                sendSelectOption={(option: string) => { setValue("country", option) }}
                            />
                            {errors && <p className="text-red-600 text-xs">{errors.country?.message}</p>}
                        </div>
                        <div className={`col-span-4 px-2 mt-4 ${isGj ? " invoice-row-gj" : " invoice-row"}`}>
                            {headingOfTable.map((key) => {
                                if (key !== 'id') {
                                    return (
                                        <p key={key} className="text-sm font-medium text-[#344054]">{key}</p>
                                    )
                                }
                            })}
                        </div>
                        {fields.map((item, index) => {
                            return (
                                <div key={index} className={`col-span-4  border rounded-lg px-2 pb-2 items-center ${isGj ? " invoice-row-gj" : " invoice-row"}`}
                                    draggable
                                    onDragStart={() => onDragStart(index)}

                                    onDrop={onDragEnd}
                                >
                                    {headingOfTableData.map((key) => {
                                        const tag: any = `services.${index}.${key}`
                                        return (
                                            <FormInput
                                                key={key}
                                                register={register(tag)}
                                                placeholder={convertToTitleCase(key)}
                                                type={(key !== "hsnAcs" && key !== "description") ? "number" : "text"}
                                            />
                                        );
                                    })}
                                    <div className="flex justify-center items-center">
                                        <Bars3Icon className="t h-7 w-7 cursor-grab mt-2" onDragOver={() => onDragOver(index)} />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <TrashIcon onClick={() => { if (index !== 0) { remove(index) } }} className="t  hover:text-blue-600 mt-2 h-6 w-6 cursor-pointer" />
                                    </div>
                                </div>
                            )
                        })}
                        <div>
                            <button type="button" className="text-[#0062FFCC] flex gap-2 items-center text-sm font-medium" onClick={handleAddNewRow}>
                                <SquaresPlusIcon className="h-5 w-5" />
                                Add New Line
                            </button>
                        </div>
                        <div className="col-span-4 grid grid-cols-4 gap-4">
                            {formElement.slice(14, 17).map((item, key) => {
                                const error: any = errors[item.name]
                                return (
                                    <FormInput
                                        key={key}
                                        label={item.label}
                                        register={register(item.name)}
                                        error={error}
                                        placeholder={item.placeholder}
                                    />
                                )
                            })}
                        </div>
                        <div className="col-span-4 grid grid-cols-4 gap-x-5 gap-y-3 mt-4">
                            <h2 className="text-xl font-semibold col-span-4">Payable to</h2>
                            {formElement.slice(11, 14).map((item, key) => {
                                const error: any = errors[item.name]
                                return (
                                    <FormInput
                                        key={key}
                                        label={item.label}
                                        register={register(item.name, { required: item.required })}
                                        error={error}
                                        placeholder={item.placeholder}
                                    />
                                )
                            })}
                        </div>
                        <div className="col-span-4 mt-4">
                            <h2 className="text-xl font-semibold col-span-2 mb-2">Additional Options</h2>
                            <CheckboxItem id={1} name="Attachment" isChecked={checkedState[1]} onChange={() => { handleCheckboxChange(1) }} />
                            <div className={checkedState[1] ? "h-auto mb-3 mt-1" : "h-0 overflow-hidden"}>
                                {watch("attachment") ?
                                    <UploadPdfPreview
                                        name={attachment.name}
                                        byte={attachment.size}
                                        loading={false}
                                        onRemove={() => {
                                            reset({ "attachment": undefined })
                                        }}
                                    />
                                    :
                                    <DragAndDropUpload onChange={handleUploadAttachment} />
                                }
                            </div>
                            <CheckboxItem id={2} name="Terms & Condition" isChecked={checkedState[2]} onChange={() => { handleCheckboxChange(2) }} />
                            <div className={checkedState[2] ? "h-auto mb-3 mt-1" : "h-0 overflow-hidden"}>
                                <Textarea
                                    label="Terms & Condition"
                                    placeholder="Write a terms and condition.........." register={register("terms_and_conditions")} />
                            </div>
                            <CheckboxItem id={3} name="Notes" isChecked={checkedState[3]} onChange={() => { handleCheckboxChange(3) }} />
                            <div className={checkedState[3] ? "h-auto mb-3 mt-1" : "h-0 overflow-hidden"}>
                                <Textarea label="Notes" error={errors["notes"]}
                                    placeholder="Write a notes.........." register={register("notes")} />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end pr-4">
                        <Button
                            type="submit"
                            onClick={() => { }}
                            isLoading={isFormSubmit}
                        >
                            {isEditBill ? "Update Bill" : "Generate Bill"}
                        </Button>
                    </div>
                </form>
            </div>
            <InvoiceModel
                isOpen={isOpenBillModel}
                type={"Bill"}
                companyName={watch("company_name")}
                date={bDate}
                onClose={onCloseModel}
                onClick={handleViewBill}
            />
            {isShowDraftModel && (
                <div className="flex justify-center items-center fixed inset-0 z-[2000] backdrop-blur-sm overflow-auto py-8">
                    <div className="relative bg-white rounded-md shadow-2xl p-4 w-auto md:w-[450px] max-w-[450px] border">
                        <div className='border-b flex justify-between pb-4'>
                            <div className='font-semibold'>Do you want to cancel???</div>
                            <XMarkIcon onClick={() => { setIsShowDraftModel(false) }} className='w-5 h-5 cursor-pointer'></XMarkIcon>
                        </div>
                        <div className="mt-3">
                            {isEditBill ?
                                <div className="text-sm">
                                    <p>We noticed you haven't finished update this bill. Would you like to:</p>
                                    <ul className="list-disc">
                                        <li>Confirm: Discard all changes and exit.</li>
                                        <li>Cancel: Go back to editing</li>
                                    </ul>
                                </div>
                                :
                                <div className="text-sm">
                                    <p>We noticed you haven't finished creating this bill. Would you like to:</p>
                                    <ul className="list-disc">
                                        <li>Save as Draft: Keep the information entered for later editing.</li>
                                        <li>Cancel: Discard all changes and exit.</li>
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className='flex justify-end pt-4 mt-3 gap-4 border-t'>
                            <div>
                                <Button variant="outline" onClick={handleConfirmBack} >Cancel</Button>
                            </div>
                            <Button onClick={handleSaveAsDraft} isLoading={false} >{isEditBill ? "Confirm" : "Save as Draft"}</Button>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
}

export default CreateBill;

interface CheckboxItemProps {
    id: number;
    name: string;
    onChange: () => void;
    isChecked: boolean;
}

const CheckboxItem: React.FC<CheckboxItemProps> = (props) => {
    return (
        <div className="flex gap-2 items-center mb-1">
            <input
                type="checkbox"
                id={`checkbox-${props.id}`}
                name={props.name}
                checked={props.isChecked}
                onChange={props.onChange}
                className="h-4 w-4 cursor-pointer"
            />
            <label htmlFor={`checkbox-${props.id}`} className="text-lg cursor-pointer">
                {props.name}
            </label>
        </div>
    )
}
type formElementProps = {
    label: string;
    name: keyof Bill;
    required: string;
    isNumber?: true,
    placeholder: string;
}

const formElement: formElementProps[] = [
    {
        label: "Invoice #",
        name: "invoice_no",
        required: "Invoice is required",
        placeholder: "Invoice "
    },
    {
        label: "Bill #",
        name: "bill_no",
        required: "bill is required",
        placeholder: "Bill"
    },
    {
        label: "Bill From",
        name: "bill_from",
        required: "Bill from is required",
        placeholder: "Bill to"
    },
    {
        label: "GSTIN",
        name: "gst_in",
        required: "GSTIN is required",
        placeholder: "GSTIN"
    },
    {
        label: "POC Name",
        name: "poc_name",
        required: "POC Name is required",
        placeholder: "POC Name"
    },
    {
        label: "Address line 1*",
        name: "address_line_1",
        required: "Address is required",
        placeholder: "Address"
    },
    {
        label: "Address line 2",
        name: "address_line_2",
        required: "Address is required",
        placeholder: "Address"
    },
    {
        label: "Pincode",
        name: "pin_code",
        isNumber: true,
        required: "Pin Code required",
        placeholder: "Pin Code"
    },
    {
        label: "Contact No",
        name: "contact_no",
        required: "Contact No is required",
        placeholder: "Contact No"
    },
    {
        label: "Mail ID",
        name: "mail_id",
        required: "Mail ID is required",
        placeholder: "Mail ID"
    },
    {
        label: "Website",
        name: "website",
        required: "Website is required",
        placeholder: "Website"
    },
    {
        label: "Bank Name",
        name: "bank_name",
        required: "Bank Name is required",
        placeholder: "Bank Name"
    },
    {
        label: "IFSC Code",
        name: "ifsc_code",
        required: "IFSC Code is required",
        placeholder: "IFSC Code"
    },
    {
        label: "Account No",
        name: "bank_account_no",
        required: "Account No is required",
        placeholder: "Account No"
    },
    {
        label: "CGST",
        name: "cgst",
        required: "CGST is required",
        placeholder: "CGST"
    },
    {
        label: "SGST",
        name: "sgst",
        required: "SGST is required",
        placeholder: "SGST"
    },
    {
        label: "IGST",
        name: "igst",
        required: "IGST is required",
        placeholder: "IGST"
    }
]

function convertToTitleCase(key: string): string {
    return key
        .replace(/_/g, ' ')
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}