import { fetchData } from './utils';

const login = async (data: any) => {
    const url = `/login`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error in login");
};

const forgotPassword = async (data: any) => {
    const url = `/forgot-password`;
    return await fetchData(url,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include", body: JSON.stringify(data)
        }, "Error send forgot password email");
};

const resetPassword = async (id: string, data: any) => {
    const url = `/reset-password/${id}`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error send forgot password email");
};

const checkToken = async () => {
    const url = `/verify-token`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching holidays");
}

const authApi = {
    login,
    forgotPassword,
    resetPassword,
    checkToken,
}

export default authApi