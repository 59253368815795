import React from "react"
import { CustomCard } from "../../card"
import { CenterDoughnutChart } from "../../chart/center-doughnut-chart"
import { STATUS_CONFIG } from "../../../constants"
import { Button } from "../buttons"
import { ComponentLoading } from "../../component-loading"

export type EmployeeAttendanceProps = {
    onTime: number
    absent: number
    totalEmp: number
    wfh: number
    lateAttendance: number
    tag: string
    loading: boolean
}

const EmployeeAttendance: React.FC<EmployeeAttendanceProps> = ({ onTime, absent, totalEmp, wfh, lateAttendance, tag, loading }) => {
    const loading2 = !onTime && !absent && !wfh && !lateAttendance;
    const statusData = [
        { name: STATUS_CONFIG["onTime"].name, value: onTime, bgColor: STATUS_CONFIG["onTime"].bg },
        { name: STATUS_CONFIG["workFromHome"].name, value: wfh, bgColor: STATUS_CONFIG["workFromHome"].bg },
        { name: STATUS_CONFIG["lateAttendance"].name, value: lateAttendance, bgColor: STATUS_CONFIG["lateAttendance"].bg },
        { name: STATUS_CONFIG["absent"].name, value: absent, bgColor: STATUS_CONFIG["absent"].bg },
    ];

    return (
        <CustomCard>
            <div className="flex justify-between gap-3 items-center pb-4 mb-2">
                <h2 className="heading2">Work Patterns</h2>
                <Button variant="outline" size="small">{tag}</Button>
            </div>
            <div className="grid grid-cols-2 items-stretch min-h-32">
                <div className="flex flex-col justify-evenly">
                    {statusData.map((status, index) => (
                        <DotList key={index} name={status.name} value={status.value} bgColor={status.bgColor} />
                    ))}
                </div>
                <div className="min-w-32">
                    <ComponentLoading
                        skeletonArray={[0]}
                        children={<CenterDoughnutChart
                            onTime={onTime}
                            absent={absent}
                            totalEmp={totalEmp}
                            wfh={wfh}
                            lateAttendance={lateAttendance}
                        />}
                        childrenSkeleton={<ChartSkelton />}
                        isLading={loading}
                        error={!loading && loading2}
                    />
                </div>
            </div>
        </CustomCard>
    )
}

export default EmployeeAttendance;

type DotListType = {
    name: string,
    value?: number,
    bgColor: string
}

const DotList: React.FC<DotListType> = ({ name, value, bgColor }) => {
    return (
        <div className="flex justify-start gap-3 items-center">
            <div className={`h-3 w-3 rounded-full ${bgColor}`}></div>
            <p className="text-xs font-medium text-[#1D1027B2]">
                <span className="text-black">{value}</span>
                {" " + name}
            </p>
        </div>
    )
}

const centerTextPlugin = (text: string) => ({
    id: 'centerText',
    beforeDraw: (chart: { width: any; height: any; ctx: any }) => {
        const { width, height, ctx } = chart;

        const fontSize = (height / 10).toFixed(2); // Adjust font size based on chart height

        ctx.restore();
        ctx.font = `${fontSize}px sans-serif`;
        ctx.textBaseline = 'middle';

        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
    },
});

function ChartSkelton() {
    return (
        <div className="flex items-center justify-center">
            <div className="relative w-32 h-32 animate-pulse">
                <div className="absolute inset-0 rounded-full bg-gray-300"></div>
                <div className="absolute inset-4 rounded-full bg-white"></div>
            </div>
        </div>
    )
}