import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import "./index.css"

interface AccordionProps {
    title: string;
    children: any;
    isOpen: boolean;
    onToggle: () => void;
}

const Accordion: React.FC<AccordionProps> = ({ title, children, isOpen, onToggle }) => {
    const notNull = !!children?.props?.children?.length

    return (
        <div>
            <div
                className="flex justify-between items-center bg-white py-1 mt-2 cursor-pointer"
                onClick={onToggle}
            >
                <h2 className={`text-sm flex items-center gap-2 font-bold py-2 leading-tight capitalize relative add-red-icon ${isOpen ? "text-[#1C1C1C]" : "text-[#1D1027B2]"}`}>
                    {title}
                    {notNull && <span className="bg-gray-300 h-4 w-4 text-black rounded-full flex justify-center items-center text-[11px] font-normal">
                        {children.props.children.length}
                    </span>}
                </h2>
                <div onClick={onToggle}>
                    <ChevronDownIcon
                        className={`h-5 w-5 text-[#888a96] cursor-pointer T transform transition-transform  ${isOpen ? 'rotate-180 text-black' : 'rotate-0'}`}
                        onClick={onToggle}
                    />
                </div>
            </div>
            <div className={`bg-white  max-h-80 overflow-auto T ${isOpen ? "acc-content" : "h-0"}`}>
                {children}
            </div>
        </div>
    );
};


interface AccordionGroupProps {
    accordions: { title: string; content: any }[];
}

export const AccordionGroup: React.FC<AccordionGroupProps> = ({ accordions }) => {
    const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(null); // Changed initial state to null

    const handleAccordionToggle = (index: number) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index)); // Set to null to close all
    };

    return (
        <div className="bg-white overflow-hidden">
            {accordions.map((accordion, index) => (
                <Accordion
                    key={index}
                    title={accordion.title}
                    children={accordion.content}
                    isOpen={index === openAccordionIndex}
                    onToggle={() => handleAccordionToggle(index)}
                />
            ))}
        </div>
    );
};