import { useEffect, useState } from "react";
import "./index.css";
import { useParams } from "react-router-dom";
import { BaseURL } from "../../constants";
const IdCard = () => {
    const [user, setUser] = useState<Employee>({
        id: "",
        emp_id: "",
        first_name: "",
        last_name: "",
        image: "",
        date_of_joining: "",
        designation: "",
        organization: "",
        gender: "",
        dob: "",
        location: "",
        contact_no: "",
        email: "",
        current_address: "",
        permanent_address: "",
        employee_type: "",
        role: "",
        type: "",
        status: "",
        emergency_contact_no: "",
        created_at: "",
        updated_at: "",
        department_id: "",
        team_id: "",
        company_id: "",
        blood_group: "",
        qr_code_path: "",
    });
    const [company, setCompany] = useState<Company>({
        id: "",
        company_id: "",
        company_name: "",
        logo: "",
        website: "",
    });
    const [logo, setLogo] = useState<string>("/adp.png");
    const [companyAddress, setCompanyAddress] = useState<string>("B1/601-603, Palladium, Corporate road, Ahmedabad - 380015");
    const [colorCode, setColorCode] = useState<string>("#A086CC");
    const [ringColor, setRingColor] = useState<string>("#A086CC");

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            getEmployeeInfo(id as string)
        }
    }, [id]);

    const getEmployeeInfo = async (id: string) => {
        try {
            const apiUrl = `${BaseURL}/employees/idcard/${id}`;
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const responseData: any = await response.json();
                const data: Data = responseData.data
                if (data && data.company) {
                    setCompany(data.company)
                }
                if (data.employee && data.employee[0]) {
                    setUser(data.employee[0])
                }
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error verifying token:", error);
        }
    };

    const handleSaveContact = () => {
        const vCardData = `
BEGIN:VCARD
VERSION:2.1
N:${user.last_name};${user.first_name};;;
FN:${user.first_name} ${user.last_name}
ORG:${user.organization}
TITLE:${user.designation}
TEL;CELL;PREF:+91${user.contact_no}
EMAIL:${user.email}
ADR:;;${""};;;
URL:${""}
END:VCARD
        `;

        const blob = new Blob([vCardData], { type: 'text/vcard' });
        const link = document.createElement('a');
        link.download = `${user.first_name}_${user.last_name}.vcf`;
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if (company.company_id) {
            const companyDetails: { [key: string]: { logo: string; address: string; ringColor: string; bgColor: string } } = {
                "COMP001": {
                    logo: "/adp.png",
                    address: "B1/601-603, Palladium, Corporate road, Ahmedabad - 380015",
                    ringColor: "#A086CC",
                    bgColor: "bg-[#A086CC3B]",
                },
                "COMP002": {
                    logo: "/vd.png",
                    address: "B1/601-603, Palladium, Corporate road, Ahmedabad - 380015",
                    ringColor: "#A086CC",
                    bgColor: "bg-[#A086CC3B]",
                },
                "COMP003": {
                    logo: "/kn.png",
                    address: "B1/604-606, Palladium, Corporate road, Ahmedabad - 380015",
                    ringColor: "#E84F21",
                    bgColor: "bg-[#FFECE6]",
                },
            };

            const details = companyDetails[company.company_id] || {
                logo: "/default.png",
                address: "/default.png",
                ringColor: "#A086CC",
                bgColor: "bg-[#A086CC3B]",
            };

            setLogo(details.logo);
            setCompanyAddress(details.address);
            setRingColor(details.ringColor);
            setColorCode(details.bgColor);
        }
    }, [company.company_id]);

    const domain = extractDomain(company.website);

    const borderColor = `border-[${ringColor}]`

    return (
        <div className="montserrat">
            <div className="max-w-[430px] mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
                <div className={`h-32 relative ${colorCode} mb-16`}>
                    <div className="flex justify-between img w-full items-center px-4">
                        <div>
                            <img src={user.image} alt="" className={`h-32 w-32 rounded-full object-cover border-[3px] ${borderColor}`} />
                        </div>
                        <div>
                            <img src={logo} alt="" className="h-12" />
                        </div>
                    </div>
                </div>
                <div className="px-4 py-3">
                    <h1 className="capitalize font-medium text-[28px] text-[#212121]">{user.first_name} {user.last_name}</h1>
                    <p className="text-[#606060] text-xl font-medium">{user.designation}</p>
                    <p className="text-[#9F9F9F] text-base font-medium">{user.organization}</p>
                </div>
                <div className="px-4 pb-8">
                    {user.first_name === "Parva" &&
                        <div className="flex justify-center border-b border-[#EDEDED] pb-4 pt-3">
                            <button className="text-[#967CC2] text-xl font-semibold bg-[#E9E3F3] rounded-3xl border-[#A086CC33] border px-6 py-1 inter"
                                onClick={handleSaveContact}
                            >
                                Add Contact
                            </button>
                        </div>
                    }
                    {/* <div className="flex items-center justify-between border-b border-[#EDEDED] pb-4 pt-7">
                        <div className="flex items-center">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8">
                                <path d="M18.3333 14.1V16.6C18.3343 16.8321 18.2867 17.0618 18.1937 17.2745C18.1008 17.4871 17.9644 17.678 17.7934 17.8349C17.6224 17.9918 17.4205 18.1113 17.2006 18.1856C16.9808 18.26 16.7478 18.2876 16.5167 18.2667C13.9523 17.9881 11.4892 17.1118 9.32498 15.7084C7.31151 14.4289 5.60443 12.7219 4.32499 10.7084C2.91663 8.53438 2.04019 6.0592 1.76665 3.48337C1.74583 3.25293 1.77321 3.02067 1.84707 2.80139C1.92092 2.58211 2.03963 2.38061 2.19562 2.20972C2.35162 2.03883 2.54149 1.9023 2.75314 1.80881C2.9648 1.71532 3.1936 1.66692 3.42499 1.66671H5.92498C6.32941 1.66273 6.72148 1.80594 7.02812 2.06965C7.33476 2.33336 7.53505 2.69958 7.59165 3.10004C7.69717 3.9001 7.89286 4.68565 8.17499 5.44171C8.2871 5.73998 8.31137 6.06414 8.24491 6.37577C8.17844 6.68741 8.02404 6.97347 7.79998 7.20004L6.74165 8.25837C7.92795 10.3447 9.65536 12.0721 11.7417 13.2584L12.8 12.2C13.0266 11.976 13.3126 11.8216 13.6243 11.7551C13.9359 11.6887 14.26 11.7129 14.5583 11.825C15.3144 12.1072 16.0999 12.3029 16.9 12.4084C17.3048 12.4655 17.6745 12.6694 17.9388 12.9813C18.203 13.2932 18.3435 13.6914 18.3333 14.1Z" stroke="#A086CC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p className="w-[326px] text-[#606060] text-sm font-medium px-2">+91 {user.phone}</p>
                        </div>
                        <a href={"tel:+91" + user.phone}><ArrowIcon /></a>
                    </div> */}
                    <div className="flex items-center justify-between border-b border-[#EDEDED] pb-4 pt-7">
                        <div className="flex items-center justify-between ">
                            <div>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8">
                                    <g clipPath="url(#clip0_363_36)">
                                        <path d="M13.3334 6.66672V10.8334C13.3334 11.4964 13.5968 12.1323 14.0656 12.6012C14.5344 13.07 15.1703 13.3334 15.8334 13.3334C16.4964 13.3334 17.1323 13.07 17.6011 12.6012C18.07 12.1323 18.3334 11.4964 18.3334 10.8334V10.0001C18.3332 8.11924 17.6969 6.29378 16.5278 4.8205C15.3586 3.34721 13.7255 2.31274 11.8939 1.8853C10.0623 1.45786 8.13997 1.66259 6.43948 2.46619C4.739 3.2698 3.36036 4.62502 2.52774 6.31149C1.69513 7.99796 1.4575 9.9165 1.8535 11.7551C2.2495 13.5938 3.25584 15.2444 4.70889 16.4386C6.16193 17.6328 7.97622 18.3003 9.85675 18.3327C11.7373 18.365 13.5735 17.7603 15.0667 16.6167M13.3334 10.0001C13.3334 11.841 11.841 13.3334 10 13.3334C8.15908 13.3334 6.6667 11.841 6.6667 10.0001C6.6667 8.1591 8.15908 6.66672 10 6.66672C11.841 6.66672 13.3334 8.1591 13.3334 10.0001Z" stroke={ringColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_363_36">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className=" text-[#606060] text-sm font-medium px-2">
                                {user.email}
                            </p>
                        </div>
                        <a href={`mailto:${user.email}`}><ArrowIcon colorCode={ringColor} /></a>
                    </div>
                    <div className="flex items-center justify-between border-b border-[#EDEDED] pb-4 pt-7">
                        <div className="flex items-center justify-between">
                            <div>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8">
                                    <g clipPath="url(#clip0_363_44)">
                                        <path d="M18.3334 9.99996C18.3334 14.6023 14.6024 18.3333 10 18.3333M18.3334 9.99996C18.3334 5.39759 14.6024 1.66663 10 1.66663M18.3334 9.99996H1.66669M10 18.3333C5.39765 18.3333 1.66669 14.6023 1.66669 9.99996M10 18.3333C12.0844 16.0513 13.269 13.0899 13.3334 9.99996C13.269 6.90999 12.0844 3.94859 10 1.66663M10 18.3333C7.91562 16.0513 6.73106 13.0899 6.66669 9.99996C6.73106 6.90999 7.91562 3.94859 10 1.66663M1.66669 9.99996C1.66669 5.39759 5.39765 1.66663 10 1.66663" stroke={ringColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_363_44">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className=" text-[#606060] text-sm font-medium px-2">{domain}</p>
                        </div>
                        <div>
                            <a target="_blank" href={company.website}><ArrowIcon colorCode={ringColor} /></a>
                        </div>
                    </div>
                    <div className="flex items-center justify-between pb-4 pt-7">
                        <div className="flex items-center justify-between">
                            <div>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8">
                                    <g clipPath="url(#clip0_363_52)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.705 4.29168L4.72751 8.24335L9.09834 9.95418C9.31246 10.0379 9.50694 10.165 9.66957 10.3274C9.8322 10.4899 9.95946 10.6843 10.0433 10.8983L11.7533 15.2692L15.705 4.29168ZM16.4733 2.24418C17.27 1.95751 18.04 2.72751 17.7533 3.52335L12.72 17.5042C12.4075 18.3733 11.185 18.39 10.8483 17.5292L8.49084 11.5058L2.46751 9.14918C1.60751 8.81251 1.62417 7.59001 2.49251 7.27668L16.4733 2.24335V2.24418Z" fill={ringColor} />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_363_52">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className=" text-[#606060] text-sm font-medium px-2">{companyAddress}</p>
                        </div>
                        <div>
                            <ArrowIcon colorCode={ringColor} />
                        </div>
                    </div>
                    <div className="mt-2">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229.54165067659207!2d72.50131687479934!3d22.99929130000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9adc8dc164cf%3A0xb67567e144a2521b!2sPALLADIUM%20TOWER%2C%20Makarba%2C%20Ahmedabad%2C%20Gujarat%20380015!5e0!3m2!1sen!2sin!4v1723637274893!5m2!1sen!2sin"
                            height="390"
                            style={{ border: 0, width: "100%" }}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
                <div className={`h-5 ${colorCode}`}></div>
            </div>
        </div >
    );
}

export default IdCard;

const ArrowIcon = (props: { colorCode: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8">
            <path d="M7.05026 16.9497L16.9498 7.05022M16.9498 7.05022L9.87869 7.05022M16.9498 7.05022L16.9498 14.1213" stroke={props.colorCode} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

function extractDomain(url: string) {
    try {
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
            url = "https://" + url;
        }
        const domain = new URL(url).hostname;
        return domain;
    } catch (error) {
        return null;
    }
}

export interface Data {
    company: Company
    employee: Employee[]
}

export interface Company {
    id: string
    company_id: string
    company_name: string
    logo: string
    website: string
}

export interface Employee {
    id: string
    emp_id: string
    first_name: string
    last_name: string
    image: string
    date_of_joining: string
    designation: string
    organization: string
    gender: string
    dob: string
    location: string
    contact_no: string
    email: string
    current_address: string
    permanent_address: string
    employee_type: string
    role: string
    type: string
    status: string
    emergency_contact_no: string
    created_at: string
    updated_at: string
    department_id: string
    team_id: string
    company_id: string
    blood_group: string
    qr_code_path: string
}
