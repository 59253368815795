import React from "react";
import { HolidayList } from "../../../types";
import { formatDateDMonth } from "../../../utils/helpers";
import { CustomCard } from "../../card";

export interface UpcomingHolidaysProps {
    holidays: HolidayList[]
}

const UpcomingHolidays: React.FC<UpcomingHolidaysProps> = (props) => {
    const d = new Date()

    const calculateDaysLeft = (date: string): number => {
        const holidayDate = new Date(date)
        const d = new Date();
        return Math.ceil((holidayDate.getTime() - d.getTime()) / (1000 * 3600 * 24));
    }

    const holidays = props.holidays?.filter((item) => {
        const holidayDate = new Date(item.date)
        const isCurrentMonth = d.getDate() <= holidayDate.getDate() && holidayDate.getMonth() === d.getMonth()
        return isCurrentMonth
    }).sort((a, b) => calculateDaysLeft(a.date) - calculateDaysLeft(b.date)); // Sort by days left

    return (
        <CustomCard className="h-full">
            <h2 className="heading2">Upcoming Holidays</h2>
            <div className="max-h-[373px] overflow-auto scroll-bar-width mt-2">
                {holidays?.length ? holidays.map((item, key) => {
                    const d: number = calculateDaysLeft(item.date)

                    return (
                        <div key={key}
                            className={`flex justify-between items-center py-3  ${key === holidays.length - 1 ? '' : 'border-b border-b-[#E6EDFF]'}`}
                        >
                            <div className="text-[#1D1027] text-xs font-medium">
                                {formatDateDMonth(item.date)}
                            </div>
                            <div className="text-xs font-medium text-[#1D1027B2]">{item.occasion}</div>
                            <div className="text-xs font-medium text-[#1D1027B2]">
                                {d === 0 ? "Today" : d === 1 ? "1 day left" : `${d} days left`}
                            </div>
                        </div>
                    )
                }) : <div className="no-data">No holiday found in this month</div>}
            </div>
        </CustomCard>
    );
}

export default UpcomingHolidays;