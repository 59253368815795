import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoutesConfig } from '../config';
import { RouteConfig } from '../config/private-routes-config';
import { getAllowedRoutes } from '../utils/helpers';
import NotFound from '../pages/not-found';
import { useIsLoggedIn } from '../services/utils';

function PrivateRoutes() {
    const isAuthenticated = useIsLoggedIn();
    let allowedRoutes: RouteConfig[] = [];

    if (isAuthenticated) {
        allowedRoutes = getAllowedRoutes(PrivateRoutesConfig);
    } else {
        return <Navigate to="/app/login" />
    }

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            {allowedRoutes?.map((route) => {
                const { path, component: Component, children, title, permission, ...rest } = route;

                return (
                    <Route {...rest} key={path} path={`${path}`} element={<Component />} />
                )
            })}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default PrivateRoutes;