import { ReactNode } from "react";
import { LeaveLogNodeProps } from "../../../types";
import { APPROVALS } from "../../../constants";
import { FormattedDdMmDate } from "../../../utils/helpers";
import { LeaveBadge } from "../../badges/leave";

export const LeaveCard: React.FC<{ title: string, svg: ReactNode, totalLeave: number, taken: number }> = (props) => {
    return (
        <div className="shadow p-5 rounded-xl bg-white flex-1">
            <div className="flex gap-3 items-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {props.svg}
                    <defs>
                        <clipPath id="clip0_1062_12094">
                            <rect width="24" height="24" rx="2" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <p className="font-medium text-base text-[#242533B2]">
                    {props.title}
                </p>
            </div>
            <div className="flex mt-4 gap-4 justify-between">
                <LeaveCount count={props.totalLeave} type='Total' />
                <LeaveCount count={props.taken} type={'Taken'} />
                <LeaveCount count={props.totalLeave - props.taken} type="Remaining" />
            </div>
        </div>
    );
}

export const LeaveCardSkeleton: React.FC = () => {
    return (
        <div className="shadow p-5 rounded-xl bg-white flex-1 animate-pulse">
            <div className="flex gap-3 items-center">
                <div className="bg-gray-300 w-6 h-6 rounded-md"></div>
                <div className="bg-gray-300 h-5 w-1/2 rounded-md"></div>
            </div>
            <div className="flex mt-4 gap-4 justify-between">
                <div className="bg-gray-300 h-6 w-1/4 rounded-md"></div>
                <div className="bg-gray-300 h-6 w-1/4 rounded-md"></div>
                <div className="bg-gray-300 h-6 w-1/4 rounded-md"></div>
            </div>
        </div>
    );
}

const LeaveCount: React.FC<{ count: number, type: string }> = (props) => {
    return (
        <div className="flex flex-col items-center">
            <p className="font-semibold text-lg text-[#242533]">
                {props.count}
            </p>
            <p className="text-[#242533B2] text-sm font-normal">
                {props.type}
            </p>
        </div>
    );
}


export const LeaveLogNode: React.FC<LeaveLogNodeProps> = (props) => {
    const date: Date = new Date()
    const startDate: Date = new Date(props.start_date)
    const isRevokeLeave = startDate >= date
    const isStatus = props.status !== APPROVALS.Declined && props.status !== "CANCELLED"
    let approvedBy = ""

    if (props.status === APPROVALS.Pending || props.status === APPROVALS.Declined) {
        approvedBy = "Manager"
    } else if ((props.status === APPROVALS.Approved || props.status === APPROVALS.PartiallyApproved) && startDate < date) {
        approvedBy = "Manager"
    } else {
        approvedBy = "Admin"
    }

    return (
        <div className='leave-grid p-2 border-b'>
            <div className='leaveNoteTag'>{props.sn}</div>
            <div className='leaveNoteTag'>{FormattedDdMmDate(props.start_date)}</div>
            <div className='leaveNoteTag'>{FormattedDdMmDate(props.end_date)}</div>
            <div className='leaveNoteTag'>{props.leave_type.name}</div>
            <div className='leaveNoteTag'>{props.subject}</div>
            <div className='leaveNoteTag'>
                <LeaveBadge status={props.status} />
            </div>
            {/* <div className='text-sm font-medium text-[#242533] text-center flex items-center justify-center'>{approvedBy}</div> */}
            <div className='text-sm font-medium text-[#242533] text-center flex items-center justify-center'>
                {props.duration}
            </div>
            <div className='text-sm font-medium text-[#242533] text-center flex items-center justify-center'>
                {isStatus && isRevokeLeave && props.isUser &&
                    <div className="bg-[#EDEDFC] text-[#5E5ADB] py-1 px-3 rounded-md inline-block cursor-pointer" onClick={() => { props.handleRevokeLeave(props) }}>Cancel Leave</div>
                }
            </div>
        </div >
    )
}
