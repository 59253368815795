import { Route, Routes } from 'react-router-dom';
import Login from '../pages/login';
import NotFound from '../pages/not-found';
import ResetPassword from '../pages/reset-password';
import IdCard from '../pages/id-card';

function PublicRoutes() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/i/:id" element={<IdCard />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default PublicRoutes;
