import { useState } from 'react';
import hrApi from '../services/hr-module';
import { LeaveLog } from '../types';

const useLeaveLogs = () => {
    const [loadingLeave, setLoadingState] = useState<'loading' | 'error' | 'success'>('loading');
    const [leaveLogs, setLeaveLogs] = useState<LeaveLog[]>([]);

    const getLeaveLogs = async (empID: string) => {
        if (empID) {
            setLoadingState('loading');
            const data: any = await hrApi.getLeaveLogs(empID);
            if (data && data.leaves) {
                setLeaveLogs(data.leaves);
                setLoadingState('success');
            } else {
                setLoadingState('error');
            }
        }
    }

    return { loadingLeave, leaveLogs, getLeaveLogs };
}

export default useLeaveLogs;