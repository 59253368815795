import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from './private-routes';
import PublicRoutes from './public-routes';

function AppRoutes() {
    return (
        <Routes>
            <Route path="/app/*" element={<PublicRoutes />} />
            <Route path="/*" element={<PrivateRoutes />} />
        </Routes>
    )
}

export default AppRoutes;

