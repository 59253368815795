import { useEffect, useState } from "react";
import "./index.css"
import FormInput from "../../components/common/form-input";
import appApi from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { convertToISOFormat, convertToISOFormat2, getCookie, setCookieForToday } from "../../utils/helpers";
import { date } from "../../constants";
import { Button } from "../../components/common/buttons";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { QuestionMarkCircleIcon, SquaresPlusIcon, TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";
import DropdownButton from "../../components/common/dropdown-button";
import { useUserContext } from "../../context/user";
import { EodReportFormProps } from "../../types";

const defaultEodReport: EodReportFormProps = {
    id: "",
    emp_id: "",
    report_date: "",
    first_name: "",
    last_name: "",
    department: "",
    allocated_by: "",
    summary: "",
    challenges: "",
    tomorrow_plan: "",
    daily_task_progress: [{ task: "", status: "In Progress" }]
}

const TaskEdit = () => {
    const { user } = useUserContext();
    const { empId, d, id } = useParams()
    const navigate = useNavigate()
    const cd = new Date()
    const eodDate = new Date(cd.setDate(Number(d)))
    const reportDate = convertToISOFormat2(eodDate)
    const [saveButtonLoading, setSaveButtonLoading] = useState(false)
    const [isEditEod, setIsEditEod] = useState(false)
    const cookieName = (empId && d) ? empId + "-" + d : ""
    const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm<EodReportFormProps>({
        defaultValues: defaultEodReport
    });
    const { fields, append, remove, } = useFieldArray({
        control,
        name: 'daily_task_progress',
    });

    function cancelHandle() {
        navigate(`/task-stats/${empId}`)
    }

    const handleAddNewRow = () => append({
        task: "",
        status: "In Progress"
    })

    const handleOnSubmit: SubmitHandler<EodReportFormProps> = async (data) => {
        setSaveButtonLoading(true)
        const eodReportFormString = JSON.stringify(data);
        setCookieForToday(cookieName.toString(), eodReportFormString)
        if (empId) {
            const response: any = await appApi.setEodReports(empId, data)
            if (response) {
                toast.success(response.message, { autoClose: 3000 })
                setSaveButtonLoading(false)
            } else {
                setSaveButtonLoading(false)
            }
        }
    }

    useEffect(() => {
        if (d) {
            const dateValid = date.getDate();
            let previousDate = new Date(date);
            previousDate.setDate(date.getDate() - 1);
            if (!(Number(d) === dateValid || Number(d) === previousDate.getDate())) {
                navigate("/task-stats/" + empId);
            }
        }
    }, [d]);

    useEffect(() => {
        if (user) {
            reset({
                emp_id: user.emp_id,
                report_date: reportDate,
                first_name: user.first_name,
                last_name: user.last_name,
                department: user.designation,
                allocated_by: "",
                summary: "",
                challenges: "",
                tomorrow_plan: "",
                daily_task_progress: [{ task: "", status: "In Progress" }]
            })
        }
    }, [user])

    useEffect(() => {
        if (cookieName && d) {
            const todayEod = getCookie(cookieName)
            if (todayEod) {
                const jsonOfEod: EodReportFormProps = JSON.parse(todayEod)
                reset(jsonOfEod)
                setIsEditEod(true)
            }
        }
    }, [cookieName, d, user]);

    return (
        <div>
            <div className="bg-white header py-3 px-4 flex justify-between sticky top-0 z-50 border-b border-[#1D10271A] ">
                <div className="flex gap-3 items-center">
                    <img src="/assets/logo.png" alt="Auxilio" className="h-7 w-7" />
                    <div className="text-zinc-800 text-base font-bold">AUXILIO</div>
                    <div className="w-[1px] bg-[#00000033] h-6"></div>
                    <div className="text-base font-semibold text-[#1C1C1C]">
                        Create EOD Report
                    </div>
                </div>
                <div className="flex gap-4 h-9 items-center">
                    <div className="flex gap-1 items-center">
                        <QuestionMarkCircleIcon className="h-6 w-6 text-[#1C1C1CB2] cursor-pointer" />
                        <p className="text-[#1C1C1CB2] text-sm">
                            Do you need any help?
                        </p>
                    </div>
                    <div className="w-[1px] bg-[#00000033] h-6"> </div>
                    <XCircleIcon className="h-6 w-6 cursor-pointer" onClick={cancelHandle} />
                </div>
            </div>
            <h2 className="font-bold text-2xl text-[#1D1027] p-6">End of the day Details</h2>
            <form className="px-6 grid grid-cols-2 items-start gap-6 pb-6" onSubmit={handleSubmit(handleOnSubmit)}>
                <div className="grid grid-cols-2 gap-x-10 gap-y-2">
                    <FormInput label="First Name" placeholder="First Name" register={register("first_name")} />
                    <FormInput label="Last Name" placeholder="Last Name" register={register("last_name")} />
                    <FormInput label="Department" placeholder="Department" register={register("department")} />
                    <FormInput label="Task Allocation Done by" placeholder="Task Allocation Done by" register={register("allocated_by")} mandatory={true} />
                    <div className='bg-[#0000001A] h-[1px] col-span-2 my-4'></div>
                    <div className="col-span-2">
                        <label className="labels-task">Daily Report Summary <span className='text-red-600'>*</span></label>
                        <textarea
                            rows={3}
                            maxLength={800}
                            {...register("summary")}
                            className="text-area mb-4"
                        />
                        <label className="labels-task">Challenges Faced <span className='text-red-600'>*</span></label>
                        <textarea
                            rows={3}
                            maxLength={800}
                            {...register("challenges")}
                            className="text-area mb-4"
                        />
                        <label className="labels-task">Planned Tasks for Tomorrow <span className='text-red-600'>*</span></label>
                        <textarea
                            {...register("tomorrow_plan")}
                            rows={3}
                            maxLength={800}
                            className="text-area mb-4"
                        />
                    </div>
                </div>
                <div>
                    <h1 className="text-[#1D1027] text-xl font-bold mb-4">Daily Progress</h1>
                    <div className="grid grid-cols-12 ">
                        <div className="col-span-8 labels-task">
                            Daily Task
                            <span className='text-red-600'>*</span>
                        </div>
                        <div className="col-span-3 labels-task">
                            Status
                        </div>
                        <div className="col-span-1 labels-task"> </div>
                    </div>
                    {fields?.map((item, index) => {
                        const tag: any = `daily_task_progress.${index}.task`
                        const status: any = `daily_task_progress.${index}.status`

                        return (
                            <div key={index} className="grid grid-cols-12 gap-4">
                                <div className="col-span-8">
                                    <FormInput placeholder="Enter daily task" register={register(tag)} />
                                </div>
                                <div className="col-span-3 items-stretch mt-2">
                                    <DropdownButton
                                        title={item.status}
                                        options={["In Progress", "Completed"]}
                                        sendSelectOption={(option: string) => {
                                            setValue(status, option)
                                        }}
                                    />
                                </div>
                                <div className="flex justify-center items-center">
                                    <TrashIcon onClick={() => { if (index !== 0) { remove(index) } }} className="t  hover:text-red-500 mt-2 h-6 w-6 cursor-pointer" />
                                </div>
                            </div>
                        )
                    })}
                    <div className="mt-3">
                        <button type="button" className="text-[#0062FFCC] flex gap-2 items-center text-sm font-medium" onClick={handleAddNewRow}>
                            <SquaresPlusIcon className="h-5 w-5" />
                            Add New Line
                        </button>
                    </div>
                </div>
                <div className="col-span-2 flex justify-end gap-3">
                    {isEditEod ?
                        <>
                            <Button variant="outline" onClick={cancelHandle} >Discard</Button>
                            <Button variant="primary" type="submit" isLoading={saveButtonLoading} >Save Change</Button>
                        </>
                        :
                        <>
                            <Button variant="outline" onClick={cancelHandle} >Close</Button>
                            <Button variant="primary" type="submit" isLoading={saveButtonLoading} >Save</Button>
                        </>
                    }
                </div>
            </form>
            <ToastContainer />
        </div>
    );
}

export default TaskEdit;