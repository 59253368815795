import { DocumentArrowDownIcon, DocumentIcon, XCircleIcon } from "@heroicons/react/24/outline"

const UploadPdfPreview: React.FC<{ name: string, byte: number, loading: boolean, onRemove?: () => void, isDownload?: boolean }> = (props) => {
    return (
        <div className="flex gap-4 items-start bg-[#EEF1F7] rounded-lg p-4">
            <svg width="54" height="52" viewBox="0 0 54 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.8139 50H24.6976C19.1748 50 14.6977 45.5228 14.6977 40V12C14.6977 6.47715 19.1748 2 24.6977 2H35.753L51.8139 18.061V40C51.8139 45.5229 47.3368 50 41.8139 50Z" fill="#EEF1F7" stroke="#CBD0DC" strokeWidth="4" />
                <rect y="22.9766" width="36.2791" height="21.1628" rx="7" fill="#D82042" />
                <path d="M6.01285 38.6981V28.584H9.61799C10.4049 28.584 11.0568 28.7272 11.5737 29.0136C12.0906 29.3001 12.4774 29.6919 12.7342 30.189C12.991 30.6829 13.1194 31.2393 13.1194 31.8582C13.1194 32.4805 12.9894 33.0402 12.7293 33.5374C12.4725 34.0312 12.084 34.423 11.5638 34.7127C11.0469 34.9992 10.3966 35.1424 9.61306 35.1424H7.1339V33.8485H9.47478C9.97192 33.8485 10.3752 33.7629 10.6847 33.5917C10.9942 33.4172 11.2214 33.1801 11.3662 32.8805C11.5111 32.5809 11.5835 32.2402 11.5835 31.8582C11.5835 31.4763 11.5111 31.1372 11.3662 30.8409C11.2214 30.5446 10.9926 30.3125 10.6798 30.1446C10.3703 29.9767 9.96205 29.8927 9.45502 29.8927H7.53886V38.6981H6.01285Z" fill="white" />
                <path d="M18.1827 38.6981H14.9084V28.584H18.2864C19.2774 28.584 20.1285 28.7865 20.8396 29.1914C21.5508 29.5931 22.0957 30.1709 22.4743 30.9249C22.8562 31.6755 23.0472 32.576 23.0472 33.6263C23.0472 34.6798 22.8546 35.5852 22.4694 36.3425C22.0874 37.0997 21.5343 37.6825 20.81 38.0907C20.0857 38.4957 19.2099 38.6981 18.1827 38.6981ZM16.4344 37.3647H18.0987C18.8691 37.3647 19.5095 37.2199 20.0198 36.9301C20.5301 36.6371 20.9121 36.2141 21.1656 35.6609C21.4191 35.1045 21.5458 34.4263 21.5458 33.6263C21.5458 32.8328 21.4191 32.1595 21.1656 31.6064C20.9154 31.0533 20.5417 30.6335 20.0445 30.347C19.5474 30.0606 18.9301 29.9174 18.1926 29.9174H16.4344V37.3647Z" fill="white" />
                <path d="M24.9448 38.6981V28.584H31.2168V29.8976H26.4708V32.9793H30.7674V34.288H26.4708V38.6981H24.9448Z" fill="white" />
            </svg>
            <div className="w-full">
                <h2 className="text-base font-semibold text-[#292D32]">{props.name}</h2>
                <p className="text-[#A9ACB4] font-normal text-sm flex gap-3 items-center">
                    {Math.floor(props.byte) + " KB"} •
                    <span className="text-[#292D32] flex items-center gap-1">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.31392 1.91699C5.41101 1.91699 2.23059 5.09741 2.23059 9.00033C2.23059 12.9032 5.41101 16.0837 9.31392 16.0837C13.2168 16.0837 16.3973 12.9032 16.3973 9.00033C16.3973 5.09741 13.2168 1.91699 9.31392 1.91699ZM12.6998 7.37116L8.68351 11.3874C8.58434 11.4866 8.44976 11.5432 8.30809 11.5432C8.16642 11.5432 8.03184 11.4866 7.93267 11.3874L5.92809 9.38283C5.72267 9.17741 5.72267 8.83741 5.92809 8.63199C6.13351 8.42658 6.47351 8.42658 6.67892 8.63199L8.30809 10.2612L11.9489 6.62033C12.1543 6.41491 12.4943 6.41491 12.6998 6.62033C12.9052 6.82574 12.9052 7.15866 12.6998 7.37116Z" fill="#3EBF8F" />
                        </svg>
                        Completed
                    </span>
                </p>
            </div>
            {props.onRemove && !props.isDownload && <XCircleIcon className="w-6 h-6 text-[#292D32] cursor-pointer" onClick={props.onRemove} />}
            {props.isDownload && <DocumentArrowDownIcon className="w-7 h-7 text-[#292D32] cursor-pointer" onClick={props.onRemove} />}
        </div>
    )
}

export default UploadPdfPreview