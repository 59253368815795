import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { useUserContext } from "../../context/user";
import { InputElement, PasswordInput } from "../../components/common/form-input";
import LoginLayout from "../../layout/Login-layout";
import { toast } from "react-toastify";
import { Button } from "../../components/common/buttons";
import authApi from "../../services/auth";

const Login = () => {
    const { setUser } = useUserContext();
    const navigate = useNavigate()
    const [passwordType, setPasswordType] = useState(true);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false)
    const [pageContent, setPageContent] = useState({ isForgotPassword: true, name: "Welcome Back 👋", description: "Enter your credentials to access your account" });

    const handleEmailChange = (e: any) => {
        setFormData({
            ...formData,
            email: e.target.value.trim()
        });
    };

    const handlePasswordChange = (e: any) => {
        setFormData({
            ...formData,
            password: e.target.value.trim()
        });
    };

    const handlePasswordHide = () => { setPasswordType(!passwordType) }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true)
        const response = await authApi.login({ email: formData.email.toLowerCase(), password: formData.password })
        if (response) {
            const jsonString = JSON.stringify(response.user_data)
            const encodedJson = btoa(jsonString);
            localStorage.setItem("AuxilioUser2", encodedJson);
            setUser(response.user_data)
            toast.success(response.user_data.email + " Login successful", { autoClose: 5000 });
            setFormData({
                email: "",
                password: ""
            });
            setLoading(false)
            navigate('/dashboard');
        } else {
            setLoading(false)
        }
    };

    const handleForgotPassword = () => {
        setPageContent({
            isForgotPassword: false,
            name: "Reset Password 🔐",
            description: "If you want to reset you password please enter your mail id to sent you the reset password mail"
        })
    }

    const handleSubmitEmail = () => {
        setLoading(true)
        const forgotPassword = async () => {
            const data: any = await authApi.forgotPassword({ "email": formData.email.toLowerCase() })
            if (data) {
                setLoading(false)
                toast.success(data.message, { autoClose: 3000 });
            }
            setLoading(false)
        }
        forgotPassword()
    }

    const handleBackButton = () => {
        setPageContent({
            isForgotPassword: true,
            name: "Welcome Back 👋",
            description: "Enter your credentials to access your account"
        })
    }

    return (
        <LoginLayout name={pageContent.name} description={pageContent.description}>
            {pageContent.isForgotPassword ?
                <form className="space-y-6 mt-6" onSubmit={handleSubmit}>
                    <InputElement name={"Email"} placeholder={"Enter email address"} value={formData.email} onChange={handleEmailChange} />
                    <PasswordInput onChange={handlePasswordChange} value={formData.password} name={"Password"} placeholder={"Enter password"} />
                    <div className="text-sm text-end flex justify-end">
                        <div className="t cursor-pointer text-[#111111] underline mt-1 hover:text-blue-400" onClick={handleForgotPassword}>Forgot password?</div>
                    </div>
                    <Button variant="primary" type="submit" isLoading={loading} className="w-full">
                        Login
                    </Button>
                </form>
                :
                <div className="mt-6">
                    <InputElement name={"Email"} placeholder={"Enter email address"} value={formData.email} onChange={handleEmailChange} />
                    <Button
                        variant="primary"
                        onClick={handleSubmitEmail}
                        isLoading={loading}
                        className="w-full mb-3"
                    >
                        Send
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={handleBackButton}
                        isLoading={false}
                        className="w-full"
                    >
                        Back
                    </Button>
                </div>
            }
        </LoginLayout>
    )
}

export default Login