import React, { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

type DropdownButtonProps = {
    title: string;
    options: string[];
    iconSvg?: React.ReactNode;
    sendSelectOption: (option: string) => void;
    className?: string
    disabled?: boolean
};

const DropdownButton: React.FC<DropdownButtonProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option: string) => {
        setSelectedOption(option);
        props.sendSelectOption(option);
        setIsOpen(false);
    };

    const activeButton = isOpen ? " border-zinc-200" : "";

    const handleClickOutside = (e: MouseEvent) => {
        if (!(e.target as HTMLElement).closest(".dropdown-container")) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative  text-left dropdown-container w-[100%] h-full">
            <div className="h-full w-[100%]">
                <button
                    type="button"
                    disabled={props.disabled}
                    className={`h-full px-2 py-1 flex justify-between  items-center gap-1  text-[#667085] dark:text-white text-sm font-normal tracking-wider border border-transparent border-zinc-200 duration-300 transition-all  w-[100%]  ${activeButton} ${props.className ? props.className : "rounded-[5px]"}`}
                    onClick={toggleDropdown}
                >
                    {props.iconSvg && props.iconSvg}
                    {selectedOption || props.title}
                    <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </button>
            </div>

            {isOpen && (
                <div className="origin-top-right absolute right-0 min-w-full max-w-fit  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[100]">
                    <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {props.options.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => selectOption(option)}
                                className={`block px-4 py-2 cursor-pointer text-sm whitespace-nowrap ${selectedOption === option
                                    ? 'bg-gray-200 text-gray-900'
                                    : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                    }`}
                                role="menuitem"
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DropdownButton;