import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    ChartOptions,
    Plugin
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface CenterDoughnutChartProps {
    plugin?: any
    onTime: number
    absent: number
    totalEmp: number
    wfh: number
    lateAttendance: number
}

export const CenterDoughnutChart: React.FC<CenterDoughnutChartProps> = ({ plugin, totalEmp, onTime, absent, wfh, lateAttendance }) => {
    const data = {
        labels: ['On Time', 'Work From Home', 'Late Attendance', 'Absent'],
        datasets: [
            {
                label: 'Today',
                data: [onTime, wfh, lateAttendance, absent],
                backgroundColor: [
                    '#3279ED',
                    '#FFD023',
                    '#DE380E',
                    '#BDBDBD'
                ],
                borderWidth: 0
            }
        ]
    };
    const options: ChartOptions<'doughnut'> = {
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false
            }
        },
        cutout: '80%',
    };

    return (
        <div className="relative">
            <Doughnut data={data} options={options} plugins={[plugin ? plugin : {}]} />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="text-2xl text-[#1D1027] font-medium flex flex-col items-center">
                    {wfh + onTime + absent + lateAttendance}
                    <span className="text-sm font-medium text-[#1D102780] inline-block -mt-1"> /{totalEmp}</span>
                </div>
            </div>
        </div>
    )
};