import { useEffect, useState } from "react";
import NotificationModel from "../notification-model";
import { toast } from 'react-toastify';
import hrApi from "../../services/hr-module";
import { PencilSquareIcon, PlusCircleIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { AllowedUser } from "../../utils/helpers";
import { Roles } from "../../config";
import { Button } from "../common/buttons";
import { useUserContext } from "../../context/user";
import { read } from "fs";
import { date } from "../../constants";

type notification = {
    id?: string
    start_date: string
    end_date: string
    subject: string
    description: string
    created_at?: string
    updated_at?: string
    author_email: string
    readReceipts: boolean
}

const defaultNotice = {
    subject: "",
    description: "",
    start_date: "",
    end_date: "",
    author_email: "",
    readReceipts: false
}

type NoticeProps = {
    isOpen: boolean
    isNoticeGet: (flag: boolean) => void
    onClose: () => void
}

export const Notice = ({ isOpen, onClose, isNoticeGet }: NoticeProps) => {
    const { user } = useUserContext()
    const [notifications, setNotifications] = useState<notification[]>([])
    const [isNotificationModel, setIsNotificationModel] = useState(false);
    const [notificationForm, setNotificationForm] = useState<notification>(defaultNotice);
    const [valueDatePicker, setValueDatePicker] = useState({ startDate: date, endDate: date });

    const handleNotificationDateChange = (newValue: any) => {
        const st = new Date(newValue.startDate).toISOString()
        const ed = new Date(newValue.endDate).toISOString()
        setValueDatePicker(newValue);
        setNotificationForm({
            ...notificationForm,
            start_date: st,
            end_date: ed
        });
    };

    const handleSendNotification = () => {
        if (notificationForm.description === "" || notificationForm.subject === "" || notificationForm.start_date === "" || notificationForm.end_date === "") {
            toast.error("Please fill the mandatory fields", { autoClose: 5000 });
            return
        }
        const notificationFormSend = async () => {
            const data: any = await hrApi.createNotice(notificationForm)
            if (data) {
                const existingNotificationIndex = notifications.findIndex(item => item.id === data.notice.id);
                if (existingNotificationIndex !== -1) {
                    const updatedNotifications = [...notifications];
                    updatedNotifications[existingNotificationIndex] = data.notice;
                    setNotifications(updatedNotifications);
                } else {
                    setNotifications([...notifications, data.notice]);
                }
                setIsNotificationModel(false)
                setNotificationForm(defaultNotice)
                setValueDatePicker({ startDate: date, endDate: date })
                toast.success(data.message, { autoClose: 3000 });
            }
        }
        notificationFormSend()
    }

    const handleNotificationChange = (e: any) => {
        setNotificationForm({
            ...notificationForm,
            [e.target.name]: e.target.value,
            author_email: user.email
        });
    };

    const handleNotificationDescription = function (content: string): void {
        setNotificationForm({
            ...notificationForm,
            ["description"]: content
        });
    }

    const handleDeleteNotification = (id: string) => {
        const deleteNotification = async () => {
            const data: any = await hrApi.deleteNotice(id)
            if (data) {
                const updateNotices = notifications.filter(item => item.id != id)
                setNotifications(updateNotices)
                toast.success(data.message, { autoClose: 3000 });
            }
        }
        deleteNotification()
    }

    const handleAddNotice = () => {
        setIsNotificationModel(true);
        onClose()
    }

    const handleNoticeEdit = (item: notification) => {
        setIsNotificationModel(true)
        setNotificationForm(item)
        onClose()
    }

    useEffect(() => {
        async function getNotification() {
            const data = await hrApi.getNotices()
            if (data && data.notices) {
                const notices = data.notices.map((item: notification) => ({
                    ...item,
                    readReceipts: false,
                }));

                const storedNotices = localStorage.getItem('notices');
                if (storedNotices) {
                    const parsedNotices: notification[] = JSON.parse(storedNotices);

                    const updatedNotices = notices.map((notice: { id: string | undefined; }) => {
                        const isExisting = parsedNotices.some((storedNotice) => storedNotice.id === notice.id);
                        return {
                            ...notice,
                            readReceipts: isExisting ? parsedNotices.find((n) => n.id === notice.id)?.readReceipts ?? false : false,
                        };
                    });

                    const isSame = parsedNotices.length === updatedNotices.length &&
                        parsedNotices.every((notice, index) => notice.id === updatedNotices[index].id);

                    if (!isSame) {
                        setNotifications(updatedNotices);
                        localStorage.setItem('notices', JSON.stringify(updatedNotices));
                    } else {
                        setNotifications(updatedNotices);
                    }
                } else {
                    setNotifications(notices);
                    localStorage.setItem('notices', JSON.stringify(notices));
                }
            }
        }
        getNotification()
    }, []);

    function markAsRead(notificationId: string) {
        setNotifications((prevNotifications) => {
            const updatedNotifications = prevNotifications.map((notification) =>
                notification.id === notificationId ? { ...notification, readReceipts: true } : notification
            );
            localStorage.setItem('notices', JSON.stringify(updatedNotifications));

            return updatedNotifications;
        });
    }

    useEffect(() => {
        const hasUnreadReceipts = notifications.some(item => item.readReceipts === false);
        isNoticeGet(hasUnreadReceipts);
    }, [notifications, isNoticeGet]);

    return (
        <div>
            {isOpen &&
                <div className="flex justify-end items-start  fixed inset-0 z-[2000] backdrop-blur-[2px] overflow-auto">
                    <div className="bg-[#F3F4F6] p-8  rounded-xl h-full w-1/3">
                        <div className="inline-flex items-center justify-between w-full mb-4">
                            <h3 className="font-bold text-xl sm:text-2xl text-gray-800 dark:text-white">Notifications</h3>
                            <div className="flex gap-3 items-center">
                                <AllowedUser permissions={[Roles.ADMIN, Roles.HR_ADMIN]}>
                                    <Button variant='primary' className="flex justify-between items-center gap-1" size='small' onClick={handleAddNotice}>
                                        <PlusCircleIcon className="size-5" />
                                        Add
                                    </Button>
                                </AllowedUser>
                                <Button variant='outline' className="flex justify-between items-center gap-1" size='small' onClick={onClose}>
                                    <XMarkIcon className="size-5" />
                                    Close
                                </Button>
                            </div>
                        </div>
                        {notifications.map((item, key) => {
                            const color = item.readReceipts ? "" : "after:bg-red-500"

                            return (
                                <div className={`mt-2 px-6 py-4 bg-white rounded-lg shadow w-full flex justify-between gap-2 notification-item relative  after:content-[' '] ${color} after:h-[8px] after:w-[8px] after:absolute after:rounded-full after:top-[-2px] after:left-[-2px]`}
                                    key={key}
                                    onMouseEnter={() => { if (item.id) markAsRead(item.id) }}
                                >
                                    <div className="w-full">
                                        <div className="flex items-center justify-between w-full">
                                            <div className="inline-flex items-center">
                                                <img
                                                    src="https://cdn-icons-png.flaticon.com/512/893/893257.png"
                                                    alt="Messages Icon" className="w-6 h-6 mr-3" />
                                                <h3 className="font-bold text-base text-gray-800">{item.subject}</h3>
                                            </div>
                                            <p className="text-xs text-gray-500">
                                                {item.created_at ? getTimeDifference(item.created_at) : 'Unknown'}
                                            </p>
                                        </div>
                                        <div className="mt-1 text-sm" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                    </div>
                                    <AllowedUser permissions={[Roles.ADMIN, Roles.HR_ADMIN]}>
                                        <div>
                                            <div className="extra-button">
                                                <PencilSquareIcon className="size-5 cursor-pointer hover:text-blue-600"
                                                    onClick={() => { if (item.id) handleNoticeEdit(item) }}
                                                />
                                                <TrashIcon className="hover:text-red-600 size-5 cursor-pointer"
                                                    onClick={() => { if (item.id) handleDeleteNotification(item.id) }}
                                                />
                                            </div>
                                        </div>
                                    </AllowedUser>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            <NotificationModel
                isOpen={isNotificationModel}
                isClose={() => { setIsNotificationModel(false) }}
                isDone={handleSendNotification}
                subject={notificationForm.subject}
                content={notificationForm.description}
                sendContent={handleNotificationDescription}
                handleSubject={handleNotificationChange}
                dateRange={valueDatePicker}
                handleDateRange={handleNotificationDateChange}
                buttonLoading={false}
            />
        </div>
    );
}


function getTimeDifference(timestamp: string): string {
    const [date, time] = timestamp.split('T');
    const [hour, minute] = time.split(':');

    const [year, month, day] = date.split('-').map(Number); // Convert date parts to numbers
    const pastDate = new Date(year, month - 1, day, Number(hour), Number(minute));

    const currentDate = new Date();

    const diffMs = currentDate.getTime() - pastDate.getTime();

    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
        return `${diffDays} day(s) ago`;
    } else if (diffHours > 0) {
        return `${diffHours} hour(s) ago`;
    } else if (diffMinutes > 1) {
        return `${diffMinutes} minute(s) ago`;
    } else {
        return `Just now`;
    }
}
