/*
* These are the Roles
*/
export default {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'admin',
    FINANCE_ADMIN: 'FINANCE_ADMIN',
    HR_ADMIN: 'HR Admin',
    MANAGER: 'manager',
    USER: 'employee',
    GUEST: 'GUEST'
};