import React, { memo, ReactNode, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import Sidebar from "./sidebar";
import ConfirmDialog from "../components/confirm-dialog";
import CustomHeader from "./header";

interface BodyParentProps {
    children: ReactNode;
    width?: string;
}

const Layout: React.FC<BodyParentProps> = (props) => {
    const navigate = useNavigate()
    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        clearCookies()
        navigate('/app/login');
        navigate(0)
    }

    return (
        <div className="bg-white flex dark:bg-[#1C1C1C]">
            <div>
                <Sidebar logout={() => { setIsShowConfirmDialog(true) }} />
            </div>
            <div className="min-h-[100vh] bg-[#FAFAFA] w-full dark:bg-[#1C1C1C]">
                <CustomHeader />
                <div className="py-6 px-6 dark:bg-[#1C1C1C]">
                    {props.children}
                </div>
            </div>
            <ToastContainer />
            <ConfirmDialog isOpen={isShowConfirmDialog}
                onConfirm={handleLogout}
                message={'Are you sure you want to log out?'}
                title={'Logout'}
                isCancel={() => { setIsShowConfirmDialog(false); }}
                loading={false}
            />
        </div>
    );
};

export default memo(Layout)

function clearCookies() {
    document.cookie.split(';').forEach((cookie) => {
        const name = cookie.split('=')[0].trim();
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
    });
}
