import { APPROVALS, APPROVALS_STATUS } from "../../constants"

type LeaveBadgeProps = {
    status: string
}
export const LeaveBadge = ({ status }: LeaveBadgeProps) => {
    const formattedStatus = status.replace(/\s+/g, '')
    const approval = APPROVALS_STATUS[formattedStatus].color + " " + APPROVALS_STATUS[formattedStatus].bg

    return (
        <div className={`py-1 px-3 rounded-md inline-block text-xs ${approval}`}>
            {status === APPROVALS.PartiallyApproved ? "Partially" : status}
        </div>
    );
}