import { useEffect } from "react";
import "./index.css"
import FormInput from "../../components/common/form-input";
import appApi from "../../services/api";
import { useNavigate, useParams } from 'react-router-dom'
import { useFieldArray, useForm } from "react-hook-form";
import { QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useUserContext } from "../../context/user";
import { EodReportFormProps } from "../../types";
import { formatDateDMonth } from "../../utils/helpers";

const defaultEodReport: EodReportFormProps = {
    id: "",
    emp_id: "",
    report_date: "",
    first_name: "",
    last_name: "",
    department: "",
    allocated_by: "",
    summary: "",
    challenges: "",
    tomorrow_plan: "",
    daily_task_progress: [{ task: "", status: "In Progress" }]
}

const TaskView = () => {
    const { user } = useUserContext();
    const { empId, id } = useParams()
    const navigate = useNavigate()
    const { register, control, reset, watch } = useForm<EodReportFormProps>({
        defaultValues: defaultEodReport
    });
    const { fields } = useFieldArray({
        control,
        name: 'daily_task_progress',
    });

    function cancelHandle() {
        navigate(`/task-stats/${empId}`)
    }

    useEffect(() => {
        const getEodReports = async () => {
            if (empId && id) {
                const data: any = await appApi.getEodReport(empId, id)
                if (!!data.reports) {
                    reset(data.reports[0])
                }
            }
        }
        getEodReports()
    }, [user, empId, id])


    return (
        <div>
            <div className="bg-white header py-3 px-4 flex justify-between sticky top-0 z-50 border-b border-[#1D10271A] ">
                <div className="flex gap-3 items-center">
                    <img src="/assets/logo.png" alt="Auxilio" className="h-7 w-7" />
                    <div className="text-zinc-800 text-base font-bold">AUXILIO</div>
                    <div className="w-[1px] bg-[#00000033] h-6"></div>
                    <div className="text-base font-semibold text-[#1C1C1C]">
                        {formatDateDMonth(watch("report_date"))} EOD Report
                    </div>
                </div>
                <div className="flex gap-4 h-9 items-center">
                    <div className="flex gap-1 items-center">
                        <QuestionMarkCircleIcon className="h-6 w-6 text-[#1C1C1CB2] cursor-pointer" />
                        <p className="text-[#1C1C1CB2] text-sm">
                            Do you need any help?
                        </p>
                    </div>
                    <div className="w-[1px] bg-[#00000033] h-6"> </div>
                    <XCircleIcon className="h-6 w-6 cursor-pointer" onClick={cancelHandle} />
                </div>
            </div>
            <h2 className="font-bold text-2xl text-[#1D1027] p-6">End of the day Details</h2>
            <form className="px-6 grid grid-cols-2 items-start gap-6">
                <div className="grid grid-cols-2 gap-x-10 gap-y-2">
                    <FormInput label="First Name" placeholder="First Name" register={register("first_name")} disabled />
                    <FormInput label="Last Name" placeholder="Last Name" register={register("last_name")} disabled />
                    <FormInput label="Department" placeholder="Department" register={register("department")} disabled />
                    <FormInput label="Task Allocation Done by" placeholder="Task Allocation Done by" register={register("allocated_by")} disabled />
                    <div className='bg-[#0000001A] h-[1px] col-span-2 my-4'></div>
                    <div className="col-span-2">
                        <label className="labels-task">Daily Report Summary</label>
                        <textarea
                            rows={3}
                            maxLength={800}
                            {...register("summary")}
                            className="text-area mb-4"
                            disabled
                        />
                        <label className="labels-task">Challenges Faced</label>
                        <textarea
                            rows={3}
                            maxLength={800}
                            {...register("challenges")}
                            className="text-area mb-4"
                            disabled
                        />
                        <label className="labels-task">Planned Tasks for Tomorrow</label>
                        <textarea
                            {...register("tomorrow_plan")}
                            rows={3}
                            maxLength={800}
                            className="text-area mb-4"
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <h1 className="text-[#1D1027] text-xl font-bold mb-4">Daily Progress</h1>
                    <div className="grid grid-cols-12 ">
                        <div className="col-span-8 labels-task">
                            Daily Task
                        </div>
                        <div className="col-span-3 labels-task">
                            Status
                        </div>
                        <div className="col-span-1 labels-task"> </div>
                    </div>
                    {fields?.map((item, index) => {
                        const tag: any = `daily_task_progress.${index}.task`
                        const status: any = `daily_task_progress.${index}.status`
                        return (
                            <div key={index} className="grid grid-cols-12 gap-4">
                                <div className="col-span-8">
                                    <FormInput placeholder="Enter daily task" register={register(tag)} disabled />
                                </div>
                                <div className="col-span-3 items-stretch">
                                    <FormInput placeholder="Enter daily task" register={register(status)} disabled />
                                </div>
                                <div className="flex justify-center items-center">

                                </div>
                            </div>
                        )
                    })}
                </div>
            </form>
        </div>
    );
}

export default TaskView;