import { formatDateDMonthY, FormattedDdMmDate } from "../../../utils/helpers"
import { ApproveIcon, CancelIcon } from "../../../assets/icons"
import { LeaveLog } from "../../../types"
import { ModalBox } from "../../modal-box"
import { toast } from "react-toastify"
import DropdownButton from "../dropdown-button"
import { useState } from "react"
import hrApi from "../../../services/hr-module"
import { LeaveBadge } from "../../badges/leave"
import './index.css';
import { Button, CrossButton } from "../buttons"

type LeaveStatusProps = {
    leave: LeaveLog
    dateRange: string[]
    userId: string
    isLeaveApproval?: boolean
    isExpanded: boolean
    isView?: boolean
}

type loadingType = {
    approve: boolean,
    reject: boolean
}

const defaultLoading: loadingType = {
    approve: false,
    reject: false
}

export const LeaveStatus = ({
    leave, dateRange, userId, isLeaveApproval: isLeaveApproval, isExpanded, isView
}: LeaveStatusProps) => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<loadingType>(defaultLoading)
    const [isVisible, setIsVisible] = useState<boolean>(true);

    const closeModal = () => {
        setIsOpenModal(false);
    }

    const showModal = () => {
        setIsOpenModal(true);
    }

    const handleLeaveReject = () => {
        leaveReject()
    }

    const handleLeaveApprove = () => {
        leaveApproval()
    }

    const leaveApproval = async () => {
        setLoading({ ...defaultLoading, approve: true })
        const data: any = await hrApi.leaveApproval(leave.id, userId)
        if (data) {
            toast.success("leave approved successfully!", { autoClose: 3000 })
            setIsVisible(false)
            setIsOpenModal(false)
        }
        setLoading({ ...defaultLoading, approve: false })
    }

    const leaveReject = async () => {
        setLoading({ ...defaultLoading, reject: true })
        const data: any = await hrApi.leaveReject(leave.id, userId)
        if (data) {
            toast.success("leave rejected successfully!", { autoClose: 3000 })
            setIsVisible(false);
            setIsOpenModal(false)
        }
        setLoading({ ...defaultLoading, reject: false })
    }

    if (!isVisible) return null;

    return (
        <>
            {isExpanded ?
                <div className="grid gap-[0.66%] leave-grid-status items-center w-full py-3 px-1" >
                    <div className="flex items-center gap-2">
                        <div>
                            <img
                                src={leave.image ? leave.image : "/assets/profile-pitcher.png"} alt=""
                                className="h-10 w-10 rounded-lg overflow-hidden object-cover"
                            />
                        </div>
                        <div>
                            <h1 className="text-sm font-bold text-[#324054] capitalize mb-1">{leave.employee_name}</h1>
                            <p className="text-[#1D102766] text-xs font-semibold">{leave.designation}</p>
                        </div>
                    </div>
                    <div>
                        <h1 className="text-sm font-bold text-[#324054] capitalize mb-1">{formatDateDMonthY(leave.start_date)}</h1>
                        <p className="text-[#1D102766] text-xs font-semibold">
                            {leave.subject.length > 12 ? leave.subject.substring(0, 12) + '...' : leave.subject}
                        </p>
                    </div>
                    <div>
                        <h1 className="text-sm font-bold text-[#324054] capitalize mb-1">{leave.leave_type.name}</h1>
                        <p className="text-[#1D102766] text-xs font-semibold">Leave Type</p>
                    </div>
                    <div>
                        <h1 className="text-sm font-bold text-[#324054] capitalize mb-1">{leave.duration < 9 ? "0" + leave.duration : leave.duration} Day</h1>
                    </div>
                    <div className="flex justify-center gap-2 items-center">
                        {isLeaveApproval ? <>
                            <ApproveIcon onClick={handleLeaveApprove} />
                            <CancelIcon onClick={handleLeaveReject} />
                        </> : <LeaveBadge status={leave.status} />
                        }
                    </div>
                    <button className="text-[#684A7E] text-sm font-bold " onClick={showModal}>
                        View
                    </button>
                </div >
                :
                <div className="flex justify-between items-center w-full py-3 px-1" >
                    <div className="flex items-center gap-2">
                        <div>
                            <img
                                src={leave.image ? leave.image : "/assets/profile-pitcher.png"} alt=""
                                className="h-10 w-10 rounded-lg overflow-hidden object-cover"
                            />
                        </div>
                        <div>
                            <h1 className="text-sm font-bold text-[#324054] capitalize mb-1">{leave.employee_name}</h1>
                            <p className="text-[#1D102766] text-xs font-semibold">{leave.designation}</p>
                        </div>
                    </div>
                    <div className="flex gap-2 items-center">
                        <div className="flex justify-center gap-2 items-center">
                            {isLeaveApproval ? <>
                                <ApproveIcon onClick={handleLeaveApprove} />
                                <CancelIcon onClick={handleLeaveReject} />
                            </> : <LeaveBadge status={leave.status} />
                            }
                        </div>
                        <button className="text-[#684A7E] text-sm font-bold " onClick={showModal}>
                            View
                        </button>
                    </div>
                </div >
            }

            <ModalBox isOpen={isOpenModal} onClose={closeModal}>
                <div className="flex justify-between items-center mb-5">
                    <h2 className="h2 w-96 mb-1">Leave Request of {leave.employee_name}</h2>
                    <CrossButton onClick={closeModal} />
                </div>
                <div className="flex gap-4">
                    <form action="" className="w-[100%] min-w-60">
                        <div className="mb-3">
                            <label htmlFor="LeaveType" className="text-[#344054] text-sm font-medium block mb-2">Leave Type</label>
                            <DropdownButton
                                disabled={true}
                                title={leave.leave_type.name}
                                options={[]}
                                sendSelectOption={() => { }}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Date" className="text-[#344054] text-sm font-medium block mb-2">Date</label>
                            <p className="border border-[#D0D5DD] px-3 py-1 rounded-md h-[34px] text-base font-normal text-[#667085]">
                                {FormattedDdMmDate(leave.start_date)} - {FormattedDdMmDate(leave.end_date)}
                            </p>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="subject" className="text-[#344054] text-sm font-medium block mb-2">Subject</label>
                            <input
                                type="text" name="subject" id="" readOnly
                                value={leave.subject}
                                className="border border-[#D0D5DD] px-3 py-1 rounded-md focus:outline-none h-[34px] text-base font-normal text-[#667085] w-full"
                                placeholder='Subject' />
                        </div>

                        <label htmlFor="Reason" className="text-[#344054] text-sm font-medium block mb-2">Reason</label>
                        <textarea name="reason" id="reason"
                            value={leave.reason}
                            rows={3}
                            readOnly
                            placeholder='Write a few sentences about the leave reason..........'
                            className="text-area-class"></textarea>
                    </form>
                    <div className="w-[100%] rounded-md px-3 py-3 bg-[#AD9FFF12] min-w-60">
                        <h2 className="text-sm font-medium text-[#242533]">
                            Requested Days
                        </h2>
                        {dateRange.map((date, index) => {
                            return (
                                <div className="flex items-center gap-2 mt-2 mb-2" key={index}>
                                    <input
                                        id={`checkbox-${date}`}
                                        type="checkbox"
                                        checked={true}
                                        readOnly
                                    // disabled={!!declinedDatesRange.includes(date)}
                                    // onChange={() => handleCheckboxChange(date)}
                                    // className="check-box-class"
                                    />
                                    <label
                                        htmlFor={`checkbox-${date}`}
                                        className={`ms-2 cursor-pointer text-sm  text-[#242533] dark:text-gray-300`}>
                                        {date}
                                    </label>
                                </div>
                            )
                        })}
                        <hr className="mt-4" />
                        <p className="text-gray-600 text-sm font-normal leading-tight tracking-tight mb-6">
                            {/* {approvedDates.length} days of  {props.leaveType.toLowerCase()} {props.leaveType !== "WFH" ? "leave" : ""} */}
                        </p>
                    </div>
                </div>
                {!isView &&
                    <div className="flex justify-end gap-4 mt-6 ">
                        <Button variant="outline" onClick={handleLeaveReject} isLoading={loading.reject} >Reject</Button>
                        <Button
                            variant="primary"
                            onClick={handleLeaveApprove}
                            isLoading={loading.approve}
                        >
                            Approve
                        </Button>
                    </div>
                }
            </ModalBox>
        </>
    );
}

function LeaveTagSkeleton() {
    return (
        <div>
            <div className="h-4 bg-gray-300 rounded mb-1 w-20"></div>
            <div className="h-3 bg-gray-300 rounded w-14"></div>
        </div>
    )
}

export const LeaveStatusSkeleton = () => {
    return (
        <div className="flex justify-between gap-3 items-center w-full py-3">
            <div className="flex justify-between items-center gap-2 animate-pulse">
                <div className="h-10 w-10 rounded-lg bg-gray-300"></div>
                <LeaveTagSkeleton />
            </div>
            <LeaveTagSkeleton />
            <LeaveTagSkeleton />
            <div className="animate-pulse">
                <div className="h-4 bg-gray-300 rounded mb-1 w-10"></div>
            </div>
            <div className="flex justify-between gap-2 items-center animate-pulse">
                <div className="h-6 w-6 bg-gray-300 rounded-full"></div>
                <div className="h-6 w-6 bg-gray-300 rounded-full"></div>
            </div>
            <div className="animate-pulse">
                <div className="h-4 bg-gray-300 rounded w-16"></div>
            </div>
        </div>
    )
}

export const SortLeaveStatusSkeleton = () => {
    return (
        <div className="flex justify-between gap-3 items-center w-full py-3">
            <div className="flex justify-between items-center gap-2 animate-pulse">
                <div className="h-10 w-10 rounded-lg bg-gray-300"></div>
                <LeaveTagSkeleton />
            </div>
            <div className="animate-pulse">
                <div className="h-4 bg-gray-300 rounded mb-1 w-10"></div>
            </div>
            <div className="animate-pulse">
                <div className="h-4 bg-gray-300 rounded w-16"></div>
            </div>
        </div>
    )
}
