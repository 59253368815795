
const TeamMember: React.FC<{ name: string, image: string, workStatus: string, designation: string }> = (props) => {
    const color = props.workStatus === "On Leave" ? "after:bg-[#FB1616]" : "after:bg-[#03CA2F]"

    return (
        <div className="flex justify-start gap-2 items-center w-[100%] mb-[10px]">
            <div
                className={`w-12 relative after:content-[' '] ${color} after:h-[10px] after:w-[10px] after:absolute after:rounded-full after:border-2 after:border-white after:bottom-0 after:right-[-1px]`}
            >
                <img
                    src={!!props.image ? props.image : "/assets/logo.png"} alt="emp"
                    className="w-9 h-9 rounded-full border object-contain"
                />
            </div>
            <div className="w-[100%]">
                <p className="h4-dark ">{props.name}</p>
                <p className=" text-[#242533B2] text-xs font-normal tracking-wide break-keep">{props.designation}</p>
            </div>
        </div>
    )
}

export default TeamMember;

export const TeamMemberSkeleton = () => {
    return (
        <div className="flex justify-start gap-2 items-center w-[100%] mb-[10px] animate-pulse">
            <div className="w-11 relative">
                <div className="w-9 h-9 rounded-full bg-gray-300 border"></div>
                <div className="after:content-[' '] bg-gray-400 h-[10px] w-[10px] absolute rounded-full border-2 border-white bottom-0 right-[-1px]"></div>
            </div>
            <div className="w-[100%]">
                <p className="h4-dark bg-gray-300 h-3 w-1/2 rounded-md mb-2"></p>
                <p className="text-[#242533B2] text-xs font-normal tracking-wide break-keep bg-gray-300 h-2 w-3/4 rounded-md"></p>
            </div>
        </div>
    );
};