import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface ButtonProps {
    type?: 'button' | 'submit';
    variant?: 'primary' | 'secondary' | 'outline';
    size?: 'small' | 'medium' | 'large';
    isLoading?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
    className?: string;
}

export const Button: React.FC<ButtonProps> = ({
    type = 'button',
    variant = 'primary',
    size = 'medium',
    isLoading = false,
    disabled = false,
    onClick,
    children,
    className = ''
}) => {
    const baseStyle = 'text-base font-medium rounded-lg focus:outline-none transition-all';

    // Define styles for different button variants
    const variants = {
        primary: 'bg-[#684A7E] text-white hover:bg-[#5f3e77] border ',
        secondary: 'bg-gray-500 text-white hover:bg-gray-600',
        outline: 'border border-gray-500 text-gray-500 hover:bg-gray-100',
    };

    // Define styles for different button sizes
    const sizes = {
        small: 'px-2 py-1 text-sm',
        medium: 'px-4 py-2 text-base',
        large: 'px-6 py-3 text-lg',
    };

    const disabledStyles = {
        primary: 'bg-gray-400 text-white cursor-not-allowed',
        secondary: 'bg-gray-300 text-white cursor-not-allowed',
        outline: 'border border-gray-300 text-gray-300 cursor-not-allowed',
    };

    const appliedStyles = disabled ? disabledStyles[variant] : variants[variant];
    const icons = {
        primary: <LoadingIcon />,
        secondary: <LoadingSpinner />,
        outline: <LoadingSpinner />,
    }

    return (
        <button
            type={type}
            onClick={onClick}
            disabled={isLoading || disabled}
            className={`${baseStyle} ${appliedStyles} ${sizes[size]} ${className}`}
        >
            {isLoading ? <Loading icon={icons[variant]}>{children}</Loading> : children}
        </button>
    );
};

function Loading({ children, icon }: { children: React.ReactNode, icon: React.ReactNode }) {
    return (
        <div    >
            <p className="h-[1px] overflow-hidden">{children}</p>
            <div className="flex justify-center gap-1 items-center text-base">
                {icon}
            </div>
        </div>
    )
}

export const LoadingIcon: React.FC = () => {
    return (
        <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    )
}

export const ToggleSwitch: React.FC<{ onClick: () => void, checked: boolean }> = ({ onClick, checked }) => {
    return (
        <label className="relative inline-flex cursor-pointer items-center">
            <input id="switch" type="checkbox" className="peer sr-only" onClick={onClick} checked={checked} readOnly />
            <div className="peer h-6 w-11 rounded-full border bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-slate-800 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
        </label>
    );
}

interface CrossButtonProps {
    onClick: () => void;
}

export const CrossButton = ({ onClick }: CrossButtonProps) => {
    return (
        <XMarkIcon
            className="T w-6 h-6 text-[#667085] cursor-pointer hover:text-[#684A7E] hover:shadow hover:rotate-90 transition-transform rounded-full"
            onClick={onClick}
        />
    );
}


const LoadingSpinner = () => (
    <svg className="animate-spin  h-5 w-5 text-[#5F46D4]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
);