import React, { useEffect } from "react";
import { Button, CrossButton } from "../common/buttons";

interface ConfirmDialogProps {
    isOpen: boolean;
    isCancel: () => void;
    onConfirm: () => void;
    message: string;
    title: string;
    loading: boolean
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
    const { isOpen, isCancel, onConfirm, message, title } = props;

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0]
        if (props.isOpen) {
            body.style.overflow = "hidden"
        } else {
            body.style.overflow = "unset"
        }
    }, [props.isOpen]);

    return (
        <>
            {isOpen && (
                <div className="flex justify-center items-center fixed inset-0 z-[2000] backdrop-blur-sm overflow-auto py-8">
                    <div className="relative bg-white rounded-md shadow-2xl p-4 w-auto md:w-[400px] max-w-[400px] border">
                        <div className='border-b flex justify-between pb-4'>
                            <div className='font-semibold'>{title}</div>
                            <CrossButton onClick={isCancel} />
                        </div>
                        <p className="mt-3">{message}</p>
                        <div className='flex justify-end pt-4 mt-3 gap-4 border-t'>
                            <Button onClick={onConfirm} >Confirm</Button>
                            <Button variant="outline" onClick={isCancel} >Cancel</Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ConfirmDialog;
