import React from "react"
import "./index.css";

type ProjectCardProps = {
    projectName: string
    manager: employee
    teamMembers: employee[]
}

type employee = {
    empID: string
    firstName: string
    image: string
    lastName: string
}

const ProjectCard: React.FC<ProjectCardProps> = (props) => {
    return (
        <div className="border-[#D0D5DD33] border p-3 shadow-sm rounded-xl bg-[#FFFFFF] mb-4">
            <h2 className="text-black text-sm font-medium">{props.projectName}</h2>
            <div className="flex items-center justify-between py-2">
                <p className="text-[#5A5A5A] text-xs font-medium">Manager</p>
                <p className="text-[#5A5A5A] text-xs font-medium">{props.manager.firstName + " " + props.manager.lastName}</p>
            </div>
            <p className="text-[#5A5A5A] text-xs font-medium">Team</p>
            <div className="flex gap-3 flex-wrap mt-2">
                {props.teamMembers?.map((item, key) => {
                    const img: string = !!item.image ? item.image : "/assets/logo.png"

                    return (
                        <div className="tooltip" key={key}>
                            <img src={img} alt="emp" className="w-8 h-8 rounded-full object-contain" />
                            <span className="tooltip-text">
                                {item.firstName + " " + item.lastName}
                            </span>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default ProjectCard;

export const SkeletonProjectCard = () => {
    return (
        <div className="border-[#D0D5DD33] border p-3 shadow-sm rounded-xl bg-[#FFFFFF] mb-4">
            <div className="skeleton skeleton-text "></div>
            <div className="flex items-center justify-between py-2">
                <div className="skeleton skeleton-manager"></div>
                <div className="skeleton skeleton-manager"></div>
            </div>
            <div className="skeleton skeleton-team"></div>
            <div className="flex gap-3 flex-wrap mt-2">
                {[1, 2, 3, 4].map((_, index) => (
                    <div key={index} className="skeleton skeleton-img w-8 h-8"></div>
                ))}
            </div>
        </div>
    );
};