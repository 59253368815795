import { Bars3Icon, SquaresPlusIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import FormInput, { Textarea } from "../../../components/common/form-input";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import DropdownButton from "../../../components/common/dropdown-button";
import React, { useEffect, useState } from "react";
import DragAndDropUpload from "../../../components/drag-and-drop-upload";
import { useNavigate, useParams } from "react-router-dom";
import InvoiceHeader from "../../../components/invoice-header";
import InvoiceModel from "../../../components/invoice-model";
import { Invoice } from "../../../types";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { getCookie, setCookieForToday } from "../../../utils/helpers";
import { Organization, SACCodes, stateOfIndia } from "../../../constants";
import financeApi from "../../../services/finance";
import UploadPdfPreview from "../../../components/upload-pdf-preview";
import { Button } from "../../../components/common/buttons";
import Datepicker from "react-tailwindcss-datepicker";

const defaultServices = {
    cgst: 0,
    igst: 0,
    sgst: 0,
    country: "India",
    state: "Gujarat",
    is_draft: false,
    services: [
        {
            no: 0,
            id: "",
            hsn_acs: "",
            description: "",
            unit_price: 0,
            quantity: 0,
            amount: 0,
            cgst: 0,
            sgst: 0,
            igst: 0,
        }
    ]
}

const CreateInvoice = () => {
    const { register, handleSubmit, watch, setValue, control, formState: { errors }, reset } = useForm<Invoice>({
        defaultValues: defaultServices
    });
    const navigate = useNavigate()
    const [checkedState, setCheckedState] = useState([false, false, false, false])
    const { fields, append, remove, swap } = useFieldArray({
        control,
        name: 'services',
    });
    const [isOpenInvoiceModel, setIsOpenInvoiceModel] = useState(false);
    const [dragIndex, setDragIndex] = useState<number | null>(null)
    const [isShowDraftModel, setIsShowDraftModel] = useState(false)
    const [isEditInvoice, setIsEditInvoice] = useState(false);
    const { invoiceNo } = useParams()
    const [isFormSubmit, setIsFormSubmit] = useState(false)
    const draftData = getCookie("invoices")
    const draftInvoices: Invoice[] = draftData ? JSON.parse(draftData) : []
    const isGj = watch("state") === "Gujarat"
    const [isAdp, setIsAdp] = useState(false)
    const [attachment, setAttachment] = useState({ name: "", size: 0 })
    const [invoiceDate, setInvoiceDate] = useState({
        startDate: null,
        endDate: null
    });
    const [updateFlag, setUpdateFlag] = useState<number>(0);

    const onDragStart = (index: number) => {
        setDragIndex(index);
    };

    const onDragOver = (index: number) => {
        if (dragIndex === null || dragIndex === index) {
            return;
        }
        swap(dragIndex, index);
        setDragIndex(index);
    };

    const onDragEnd = () => {
        setDragIndex(null);
    };

    const handleCheckboxChange = (index: number) => {
        const newCheckedState = [...checkedState];
        newCheckedState[index] = !newCheckedState[index];
        setCheckedState(newCheckedState);
    }

    const handleDateChange = (date: any) => {
        const d = new Date(date?.startDate)
        if (d) {
            setValue("invoice_date", d.toISOString().split('.')[0] + 'Z')
        }
        setInvoiceDate(date)
    }

    const handleAddNewRow = () => append({
        no: fields.length + 1,
        id: "",
        description: "",
        hsn_acs: "",
        unit_price: 0,
        quantity: 0,
        amount: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
    })

    const handleConfirmBack = () => {
        if (!isEditInvoice) {
            navigate("/invoices")
        } else {
            setIsShowDraftModel(false)
        }
    }

    const handleSaveAsDraft = () => {
        if (!isEditInvoice) {
            setValue("is_draft", true)
            const invoiceForm = watch()
            const index = draftInvoices.findIndex(invoice => invoice.invoice_no === invoiceForm.invoice_no);
            if (invoiceForm.invoice_no) {
                if (index !== -1) {
                    draftInvoices[index] = invoiceForm;
                } else {
                    draftInvoices.push(invoiceForm);
                }
                setCookieForToday("invoices", JSON.stringify(draftInvoices))
                toast.success("Invoice save as draft", { autoClose: 3000 })
                navigate("/invoices")
            } else {
                setIsShowDraftModel(false)
                toast.error("Invoice  No is required for save as draft", { autoClose: 3000 })
            }
        } else {
            navigate("/invoices")
        }
    }

    const watchFields = watch("services")

    const setInvoice = async (data: any) => {
        setIsFormSubmit(true)
        const response = await financeApi.setInvoice(data)
        if (response) {
            setIsFormSubmit(false)
            toast.success("create invoice successfully", { autoClose: 3000 });
            setIsOpenInvoiceModel(true)
        } else {
            setIsFormSubmit(false)
        }
    }

    const updateInvoice = async (data: any) => {
        updateDataOfService()
        setIsFormSubmit(true)
        const response = await financeApi.setInvoice(data)

        if (response) {
            setIsFormSubmit(false)
            toast.success("update invoice successfully", { autoClose: 3000 });
            setIsOpenInvoiceModel(true)
        } else {
            setIsFormSubmit(false)
        }
    }

    const invoiceId = watch("invoice_no")

    const getSACCodeByDescription = (description: string): string => {
        const item = SACCodes.find((code) => code.description === description);
        return item ? item.SACCode : "";
    };

    const handleOnSubmit: SubmitHandler<Invoice> = (data) => {
        data.services.forEach((service) => {
            service.quantity = Number(service.quantity);
        });
        if (isEditInvoice) {
            updateInvoice(data)
        } else {
            setInvoice(data)
        }
    }

    const handleViewInvoice = () => {
        navigate("/invoice-preview/" + invoiceId)
        reset()
    }

    const onCloseModel = () => {
        reset()
        setIsFormSubmit(false)
        setIsOpenInvoiceModel(false)
    }

    const handleUploadAttachment = (files: File[]) => {
        const file = files[0]
        if (file) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
                const base64String = btoa(
                    new Uint8Array(event.target.result)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                setAttachment({
                    name: file.name,
                    size: file.size / 1024
                })
                setValue("attachment", base64String)
            };
            reader.readAsArrayBuffer(file);
        }
    }

    useEffect(() => {
        if (invoiceNo) {
            setIsEditInvoice(true)
            let invoiceFound = false;

            for (let i = 0; i < draftInvoices.length; i++) {
                const element = draftInvoices[i];
                if (element.invoice_no === invoiceNo) {
                    invoiceFound = true;
                    reset(element)
                    break;
                }
            }
            if (!invoiceFound) {
                getInvoice(invoiceNo)
            }
        }
    }, [invoiceNo])

    const getInvoice = async (no: string) => {
        const data: any = await financeApi.getInvoice(no)
        if (data && data.invoice) {
            const invoice: Invoice = data.invoice
            invoice.services = invoice.services.map(({ invoice_no, bill_no, ...rest }) => rest);
            const getInvoiceDate: any = { startDate: new Date(invoice.invoice_date), endDate: new Date(invoice.invoice_date) }
            setInvoiceDate(getInvoiceDate)
            const pdfBlob: Uint8Array = invoice.attachment
            const pdfSizeInBytes = pdfBlob?.length || 0;
            const pdfSizeInKB = (pdfSizeInBytes / 1024).toFixed(2);
            setAttachment({
                name: "Attachment",
                size: Number(pdfSizeInKB)
            })
            reset(invoice)
        }
    }

    const headingOfTable = ["Description of services", "HSN ACS", "Unit Price", "Quantity", "Amount"]
    if (isGj) {
        headingOfTable.push("CGST (9%)", "SGST(9%)")
    } else {
        headingOfTable.push("IGST (18%)")
    }

    const handleCompanyName = (option: string) => {
        if (option === "Arun Data Processing") {
            setIsAdp(true)
        } else {
            setIsAdp(false)
        }
        setValue("company_name", option)
    }

    const closeHandle = () => {
        setIsShowDraftModel(true)
    }

    const iDate = watch("invoice_date")?.slice(0, 10)

    let subTotal = 0
    let totalTax = 0
    let totalCgst = 0
    let totalSgst = 0
    let totalIgst = 0
    watchFields.forEach((row) => {
        const unitPrice = row.unit_price
        const quantity = row.quantity
        const amount = unitPrice * quantity
        const taxRate = 0.18;
        const cgst = isGj ? Math.floor(amount * taxRate / 2) : 0
        const sgst = isGj ? Math.floor(amount * taxRate / 2) : 0
        const igst = isGj ? 0 : Math.floor(amount * taxRate)

        totalTax = totalTax + cgst + sgst + igst
        subTotal = subTotal + amount
        totalCgst = totalCgst + cgst
        totalSgst = totalSgst + sgst
        totalIgst = isGj ? 0 : totalIgst + igst
    })

    function updateDataOfService() {
        let subTotal = 0
        let totalTax = 0
        let totalCgst = 0
        let totalSgst = 0
        let totalIgst = 0
        watchFields.forEach((row, index) => {
            const unitPrice = row.unit_price
            const quantity = row.quantity
            const amount = unitPrice * quantity
            const taxRate = 0.18;
            const cgst = isGj ? Math.floor(amount * taxRate / 2) : 0
            const sgst = isGj ? Math.floor(amount * taxRate / 2) : 0
            const igst = isGj ? 0 : Math.floor(amount * taxRate)

            totalTax = totalTax + cgst + sgst + igst
            subTotal = subTotal + amount
            totalCgst = totalCgst + cgst
            totalSgst = totalSgst + sgst
            totalIgst = isGj ? 0 : totalIgst + igst
            const hsnAcs = isAdp ? getSACCodeByDescription(row.description) :
                getSACCodeByDescription(row.description).slice(2);

            setValue(`services.${index}.hsn_acs`, hsnAcs)
            setValue(`services.${index}.unit_price`, Number(row.unit_price))
            setValue(`services.${index}.quantity`, Number(row.quantity))
            setValue(`services.${index}.amount`, Math.floor(amount))
            setValue(`services.${index}.cgst`, cgst)
            setValue(`services.${index}.sgst`, sgst)
            setValue(`services.${index}.igst`, igst)
        })
        setValue("sub_total", subTotal)
        setValue("total_tax", totalTax)
        setValue("cgst", totalCgst)
        setValue("sgst", totalSgst)
        setValue("igst", totalIgst)
    }

    useEffect(() => {
        updateDataOfService()
    }, [watch, totalTax]);

    useEffect(() => {
        watchFields.forEach((row, index) => {
            const hsnAcs = isAdp ? getSACCodeByDescription(row.description) :
                getSACCodeByDescription(row.description).slice(2);

            setValue(`services.${index}.hsn_acs`, hsnAcs)
        })
    }, [watchFields, fields, setValue, updateFlag]);

    return (
        <div>
            <InvoiceHeader name="Create Invoice" onClose={closeHandle} />
            <div className="py-6 px-6">
                <h1 className="text-[#1D1027] font-bold text-2xl mb-3">
                    Invoice Details
                </h1>
                <form onSubmit={handleSubmit(handleOnSubmit)} >
                    <div className="grid grid-cols-4 gap-x-5 gap-y-3 overflow-auto  scroll-bar-width pb-4 pr-2">
                        <div className="flex flex-col">
                            <p className="modal-div mb-2">Company</p>
                            <DropdownButton
                                title={watch("company_name") ? watch("company_name") : "Company"}
                                options={Organization}
                                sendSelectOption={handleCompanyName}
                            />
                            {errors.company_name && <p className="text-red-600 text-xs">Company Name is required</p>}
                        </div>
                        <div className="flex flex-col">
                            <p className="modal-div">Date</p>
                            <Datepicker
                                containerClassName={"relative border rounded mt-2"}
                                primaryColor={"indigo"}
                                useRange={false}
                                asSingle={true}
                                value={invoiceDate}
                                onChange={handleDateChange}
                            />
                            {errors.invoice_date && <p className="text-red-600 text-xs">Select date</p>}
                        </div>
                        {formElement.slice(0, 11).map((item, key) => {
                            const error: any = errors[item.name]
                            return (
                                <FormInput
                                    key={key}
                                    label={item.label}
                                    register={register(item.name, { required: item.required })}
                                    error={error}
                                    placeholder={item.placeholder}
                                />
                            )
                        })}
                        <div className="flex flex-col">
                            <p className="modal-div mb-2">State</p>
                            <DropdownButton
                                title={watch("state") ? watch("state") : "State"}
                                options={stateOfIndia}
                                sendSelectOption={(option: string) => { setValue("state", option) }}
                            />
                            {errors.state && <p className="text-red-600 text-xs">Select State</p>}
                        </div>
                        <div className="flex flex-col">
                            <p className="modal-div mb-2">Country</p>
                            <DropdownButton
                                title={watch("country") ? watch("country") : "Country"}
                                options={["India"]}
                                sendSelectOption={(option: string) => { setValue("country", option) }}
                            />
                            {errors.country && <p className="text-red-600 text-xs">Select country</p>}
                        </div>
                        <div className={`col-span-4 px-2 mt-4 ${isGj ? " invoice-row-gj" : " invoice-row"}`}>
                            {headingOfTable.map((key) => {
                                return (
                                    <p key={key} className="text-sm font-medium text-[#344054]">{key}</p>
                                )
                            })}
                        </div>

                        {fields.map((item2, index) => {
                            const unitPrice = watch(`services.${index}.unit_price`) || 0;
                            const quantity = watch(`services.${index}.quantity`) || 0;
                            const amount = unitPrice * quantity
                            const taxRate = 0.18;
                            const cgst = isGj ? Math.floor(amount * taxRate / 2) : 0
                            const sgst = isGj ? Math.floor(amount * taxRate / 2) : 0
                            const igst = isGj ? 0 : Math.floor(amount * taxRate)

                            return (
                                <div key={index} className={`col-span-4  border rounded-lg px-2 pb-2 items-center ${isGj ? " invoice-row-gj" : " invoice-row"}`}
                                    draggable
                                    onDragStart={() => onDragStart(index)}
                                    onDrop={onDragEnd}
                                >
                                    <select
                                        {...register(`services.${index}.description`)}
                                        className="profile-input mt-2"
                                        value={watch(`services.${index}.description`) || ""}
                                        onClick={() => { setUpdateFlag((prev) => prev + 1) }}
                                    >
                                        <option value="" disabled hidden>Select a description</option>
                                        {SACCodes.map((item, key) => (
                                            <option key={key} value={item.description}>
                                                {item.description}
                                            </option>
                                        ))}
                                    </select>
                                    <FormInput
                                        register={register(`services.${index}.hsn_acs` as const)}
                                        placeholder={"HSN ACS"}
                                        type={"text"}
                                    />
                                    <FormInput
                                        register={register(`services.${index}.unit_price` as const)}
                                        placeholder={"0"}
                                        type={"number"}
                                    />
                                    <FormInput
                                        register={register(`services.${index}.quantity` as const)}
                                        placeholder={"0"}
                                        type={"number"}
                                    />
                                    <FormInput
                                        register={register(`services.${index}.amount` as const)}
                                        placeholder={"0"}
                                        type={"number"}
                                    />
                                    {/* <input type="number" className="profile-input" placeholder="0" value={amount} readOnly /> */}

                                    {isGj ?
                                        <>
                                            <input type="number" className="profile-input" placeholder="0" value={cgst} readOnly />
                                            <input type="number" className="profile-input" placeholder="0" value={sgst} readOnly />
                                        </>
                                        :
                                        <input type="number" className="profile-input" placeholder="0" value={igst} readOnly />
                                    }
                                    <div className="flex justify-center items-center">
                                        <Bars3Icon className="t h-7 w-7 cursor-grab mt-2" onDragOver={() => onDragOver(index)} />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <TrashIcon onClick={() => { if (index !== 0) { remove(index) } }} className="t  hover:text-blue-600 mt-2 h-6 w-6 cursor-pointer" />
                                    </div>
                                </div>
                            )
                        })}
                        <div>
                            <button type="button" className="text-[#0062FFCC] flex gap-2 items-center text-sm font-medium" onClick={handleAddNewRow}>
                                <SquaresPlusIcon className="h-5 w-5" />
                                Add New Line
                            </button>
                        </div>
                        <div className="col-span-4 grid grid-cols-4 gap-4">
                            <div>
                                <p className="modal-div">Total CGST</p>
                                <input type="number" readOnly className="profile-input" {...register} placeholder="0" value={totalCgst} />
                            </div>
                            <div>
                                <p className="modal-div">Total  SGST</p>
                                <input type="number" readOnly className="profile-input" {...register} placeholder="0" value={totalSgst} />
                            </div>
                            <div>
                                <p className="modal-div">Total  IGST</p>
                                <input type="number" readOnly className="profile-input" {...register} placeholder="0" value={totalIgst} />
                            </div>
                        </div>
                        <div className="col-span-4 grid grid-cols-4 gap-x-5 gap-y-3 mt-4">
                            <h2 className="text-xl font-semibold col-span-4">Payable to</h2>
                            {formElement.slice(11, 14).map((item, key) => {
                                const error: any = errors[item.name]
                                return (
                                    <FormInput
                                        key={key}
                                        label={item.label}
                                        register={register(item.name, { required: item.required })}
                                        error={error}
                                        placeholder={item.placeholder}
                                    />
                                )
                            })}
                        </div>
                        <div className="col-span-4 mt-4">
                            <h2 className="text-xl font-semibold col-span-2 mb-2">Additional Options</h2>
                            <CheckboxItem id={0} name="Attachment" isChecked={checkedState[0]} onChange={() => { handleCheckboxChange(0) }} />
                            <div className={checkedState[0] ? "h-auto mb-3 mt-1" : "h-0 overflow-hidden"}>
                                {watch("attachment") ?
                                    <UploadPdfPreview
                                        name={attachment.name}
                                        byte={attachment.size}
                                        loading={false}
                                        onRemove={() => {
                                            reset({ "attachment": undefined })
                                        }}
                                    />
                                    :
                                    <DragAndDropUpload onChange={handleUploadAttachment} />
                                }
                            </div>
                            <CheckboxItem id={1} name="Terms & Condition" isChecked={checkedState[1]} onChange={() => { handleCheckboxChange(1) }} />
                            <div className={checkedState[1] ? "h-auto mb-3 mt-1" : "h-0 overflow-hidden"}>
                                <Textarea
                                    label="Terms & Condition"
                                    placeholder="Write a terms and condition.........." register={register("terms_and_conditions")} />
                            </div>
                            <CheckboxItem id={2} name="Notes" isChecked={checkedState[2]} onChange={() => { handleCheckboxChange(2) }} />
                            <div className={checkedState[2] ? "h-auto mb-3 mt-1" : "h-0 overflow-hidden"}>
                                <Textarea label="Notes" error={errors["notes"]}
                                    placeholder="Write a notes.........." register={register("notes")} />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end pr-4">
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={() => { }}
                            isLoading={isFormSubmit}
                        >
                            {isEditInvoice ? "Update Invoice" : "Generate Invoice"}
                        </Button>
                    </div>
                </form>
            </div>
            <InvoiceModel
                isOpen={isOpenInvoiceModel}
                type={"Invoice"}
                companyName={watch("company_name")}
                date={iDate}
                onClose={onCloseModel}
                onClick={handleViewInvoice}
            />
            {isShowDraftModel && (
                <div className="flex justify-center items-center fixed inset-0 z-[2000] backdrop-blur-sm overflow-auto py-8">
                    <div className="relative bg-white rounded-md shadow-2xl p-4 w-auto md:w-[450px] max-w-[450px] border">
                        <div className='border-b flex justify-between pb-4'>
                            <div className='font-semibold'>Do you want to cancel???</div>
                            <XMarkIcon onClick={() => { setIsShowDraftModel(false) }} className='w-5 h-5 cursor-pointer'></XMarkIcon>
                        </div>
                        <div className="mt-3">
                            {isEditInvoice ?
                                <div className="text-sm">
                                    <p>We noticed you haven't finished update this invoice. Would you like to:</p>
                                    <ul className="list-disc">
                                        <li>Confirm: Discard all changes and exit.</li>
                                        <li>Cancel: Go back to editing</li>
                                    </ul>
                                </div> :
                                <div className="text-sm">
                                    <p>We noticed you haven't finished creating this invoice. Would you like to:</p>
                                    <ul className="list-disc">
                                        <li>Save as Draft: Keep the information entered for later editing.</li>
                                        <li>Cancel: Discard all changes and exit.</li>
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className='flex justify-end pt-4 mt-3 gap-4 border-t'>
                            <div>
                                <Button variant="secondary" onClick={handleConfirmBack} >Cancel</Button>
                            </div>
                            <Button variant="primary" onClick={handleSaveAsDraft} isLoading={false} >
                                {isEditInvoice ? "Confirm" : "Save as Draft"}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
}

export default CreateInvoice;

interface CheckboxItemProps {
    id: number;
    name: string;
    onChange: () => void;
    isChecked: boolean;
}

const CheckboxItem: React.FC<CheckboxItemProps> = (props) => {
    return (
        <div className="flex gap-2 items-center mb-1">
            <input
                type="checkbox"
                id={`checkbox-${props.id}`}
                name={props.name}
                checked={props.isChecked}
                onChange={props.onChange}
                className="h-4 w-4 cursor-pointer"
            />
            <label htmlFor={`checkbox-${props.id}`} className="text-lg cursor-pointer">
                {props.name}
            </label>
        </div>
    )
}
type formElementProps = {
    label: string;
    name: keyof Invoice;
    required: string;
    placeholder: string;
}

const formElement: formElementProps[] = [
    {
        label: "Invoice #",
        name: "invoice_no",
        required: "Invoice is required",
        placeholder: "Invoice"
    },
    {
        label: "Bill #",
        name: "bill_no",
        required: "bill is required",
        placeholder: "Bill"
    },
    {
        label: "Bill to",
        name: "bill_to",
        required: "Bill to is required",
        placeholder: "Bill to"
    },
    {
        label: "GSTIN",
        name: "gst_in",
        required: "GSTIN is required",
        placeholder: "GSTIN"
    },
    {
        label: "POC Name",
        name: "poc_name",
        required: "POC Name is required",
        placeholder: "POC Name"
    },
    {
        label: "Address line 1*",
        name: "address_line_1",
        required: "Address is required",
        placeholder: "Address"
    },
    {
        label: "Address line 2",
        name: "address_line_2",
        required: "Address is required",
        placeholder: "Address"
    },
    {
        label: "Pincode",
        name: "pin_code",
        required: "Pin Code required",
        placeholder: "Pin Code"
    },
    {
        label: "Contact No",
        name: "contact_no",
        required: "Contact No is required",
        placeholder: "Contact No"
    },
    {
        label: "Mail ID",
        name: "mail_id",
        required: "Mail ID is required",
        placeholder: "Mail ID"
    },
    {
        label: "Website",
        name: "website",
        required: "Website is required",
        placeholder: "Website"
    },
    {
        label: "Bank Name",
        name: "bank_name",
        required: "Bank Name is required",
        placeholder: "Bank Name"
    },
    {
        label: "IFSC Code",
        name: "ifsc_code",
        required: "IFSC Code is required",
        placeholder: "IFSC Code"
    },
    {
        label: "Account No",
        name: "bank_account_no",
        required: "Account No is required",
        placeholder: "Account No"
    },
    {
        label: "Total CGST",
        name: "cgst",
        required: "CGST is required",
        placeholder: "CGST"
    },
    {
        label: "Total  SGST",
        name: "sgst",
        required: "SGST is required",
        placeholder: "SGST"
    },
    {
        label: "Total  IGST",
        name: "igst",
        required: "IGST is required",
        placeholder: "IGST"
    }
]
