import { TimeCardIcon } from "../../../assets/icons";
import { CustomCard } from "../../card";
import { Button } from "../buttons";

type TimeCardProps = {
    colorClass: string
    title: string
    value: string
}

export const TimeCard = ({ colorClass, title, value }: TimeCardProps) => {
    return (
        <CustomCard className="rounded-20px">
            <TimeCardIcon className={colorClass} />
            <p className="font-medium text-base text-[#1D1027CC]">{title}</p>
            <p className="font-semibold text-2xl text-[#1D1027]">
                {value}
            </p>
        </CustomCard>
    );
}