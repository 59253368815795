import { fetchData } from "./utils";

// API functions
const getEmployeeInfo = async () => {
    const url = `/user-employees`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching employee info");
};

const getSingleEmployeeInfo = async (empID: string) => {
    const url = `/employees/${empID}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, `Error fetching info for employee ID: ${empID}`);
};

const getTeammatesOnHolidays = async (empID: string) => {
    const url = `/teammates-on-holidays`;
    const body = JSON.stringify({ empID });
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body }, "Error fetching teammates on holidays");
};

const getEmployeesOnHolidays = async () => {
    const url = `/employees-on-holidays`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching employees on holidays");
};

const teamWorkingStatus = async (empID: string) => {
    const url = `/team-working-status`;
    const body = JSON.stringify({ empID });
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body }, "Error fetching team working status");
};

const getDailyAttendanceSummary = async () => {
    const url = `/daily-attendance-summary`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching daily attendance summary");
};

const getLastWeekEmployeeAttendance = async () => {
    const url = `/lastweek-employee-attendance`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching last week employee attendance");
};

const updateEmployeeContactInformation = async (empID: string, data: any) => {
    const url = `/update-contactinfo/${empID}`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error updating employee contact information");
};

const updateBankDetails = async (empID: string, data: any) => {
    const url = `/update-bank-details/${empID}`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error updating bank details");
};

const getProjectDetails = async (empID: string) => {
    const url = `/project-details`;
    const body = JSON.stringify({ empID });
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body }, "Error fetching project details");
};

const setProfileDocuments = async (data: any) => {
    const url = `/profile-documents`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error setting profile documents");
};

const deleteDocuments = async (empID: string, fileName: string, id: any) => {
    const url = `/delete-documents/${empID}/${fileName}/${id}`;
    return await fetchData(url, { method: "DELETE", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error deleting documents");
};

const getEodReports = async (empID: string, start_date = "", end_date = "") => {
    const url = `/employees/${empID}/eod_reports?start_date=${start_date}&end_date=${end_date}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching EOD reports");
};

const getEodReport = async (empID: string, id: string) => {
    const url = `/employees/${empID}/eod_reports/${id}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching EOD reports");
};

const setEodReports = async (id: string, data: any) => {
    const url = `/employees/${id}/eod_reports`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error setting EOD reports");
};

const getQuotes = async () => {
    try {
        const apiUrl = "https://type.fit/api/quotes";
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.ok) {
            const responseData: any = await response.json();
            return responseData;
        } else {
            console.error("Request failed with status:", response.status);
        }
    } catch (error) {
        console.error("Error verifying token:", error);
    }
};

const appApi = {
    getEmployeeInfo,
    getQuotes,
    getTeammatesOnHolidays,
    teamWorkingStatus,
    getSingleEmployeeInfo,
    getEmployeesOnHolidays,
    getDailyAttendanceSummary,
    getLastWeekEmployeeAttendance,
    updateEmployeeContactInformation,
    updateBankDetails,
    getProjectDetails,
    setProfileDocuments,
    deleteDocuments,
    getEodReports,
    setEodReports,
    getEodReport
};

export default appApi;
