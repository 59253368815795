import React, { useEffect, useState } from "react"
import { CustomCard } from "../../card"
import { officeLocation } from "../../../constants"
import { CircularProgressBar } from "../circular-progress-bar"
import "./index.css"
import { Button } from "../buttons"
import hrApi from "../../../services/hr-module"
import { convertToISOFormat2, formattedDate, getCookie } from "../../../utils/helpers"
import { useUserContext } from "../../../context/user"
import { toast } from "react-toastify"
import Cookies from "js-cookie"

const MarkPresent: React.FC = () => {
    const { user } = useUserContext();
    const d: Date = new Date()
    const workingHours = 9 * 60 * 60
    const [disabled, setDisabled] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("Absent");
    const [buttonStatus, setButtonStatus] = useState<string>("Absent");
    const [countdown, setCountdown] = useState<number>(workingHours);
    const [extraTime, setExtraTime] = useState<number>(0);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const lat = position.coords.latitude
                const long = position.coords.longitude
                const accuracy = position.coords.accuracy
                const d = getDistanceFromLatLonInMeters(officeLocation.latitude, officeLocation.longitude, lat, long)
                console.log("accuracy", accuracy)
                // if (accuracy > 5000) {
                //     setDisabled(false)
                // } else if (d < officeLocation.distance + accuracy) {
                //     setDisabled(false)
                // }
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    }, []);

    const handleMarkPresent = () => {
        if (status === "Present") {
            CheckOut()
        } else {
            CheckIn(getAttendanceStatus())
        }
    }

    const midnightCheckIn = new Date();
    midnightCheckIn.setHours(24, 0, 0, 0);
    const expiresInSecondsCheckIn = Math.floor((midnightCheckIn.getTime() - new Date().getTime()) / 1000);
    const expiresInDayCheckIn = expiresInSecondsCheckIn / (60 * 60 * 24)

    async function CheckIn(status: string) {
        const data: any = await hrApi.setAttendance({
            "emp_id": user.id,
            "date": convertToISOFormat2(d),
            "status": status,
            "check_in": convertToISOFormat2(d)
        })
        if (data) {
            const time = new Date();
            setButtonStatus("Present");
            setCountdown(workingHours - 1);
            startCountdown(workingHours - 1);
            Cookies.set("checkInTime", time.toISOString(), { expires: expiresInDayCheckIn });
            setStatus("Present");
            Cookies.set("Present", "Present", { expires: expiresInDayCheckIn });
            toast.success("Check-In", { autoClose: 3000 });
        }
    }

    async function CheckOut() {
        const data: any = await hrApi.setAttendance({
            "emp_id": user.id,
            "date": convertToISOFormat2(d),
            "status": "Status",
            "check_out": convertToISOFormat2(d)
        })
        if (data) {
            setDisabled(true)
            setButtonStatus("Absent");
            setCountdown(workingHours);
            setExtraTime(0);
            Cookies.remove('checkInTime');
            localStorage.removeItem("extraTime");
            localStorage.removeItem("extraTimeStart");
            toast.success("Check-Out", { autoClose: 5000 });
        }
    }

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (countdown !== workingHours && countdown > 0) {
            interval = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown && prevCountdown > 1) {
                        return prevCountdown - 1;
                    } else {
                        clearInterval(interval);
                        setCountdown(0);
                        startExtraTimeCounter()
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [countdown]);

    const storedExtraStart = localStorage.getItem("extraTimeStart");

    const lastCheckIn = getCookie("checkInTime")
    const get_cookie = getCookie("Present")

    useEffect(() => {
        if (lastCheckIn) {
            const checkInDate = new Date(lastCheckIn);
            setButtonStatus("Present");
            const elapsedTime = Math.floor((new Date().getTime() - checkInDate.getTime()) / 1000);
            const remainingTime = Math.max(workingHours - elapsedTime, 0);
            setCountdown(remainingTime);
            if (remainingTime > 0) {
                startCountdown(remainingTime);
            } else if (storedExtraStart) {
                const extraStartDate = new Date(storedExtraStart);
                const extraElapsedTime = Math.floor((new Date().getTime() - extraStartDate.getTime()) / 1000);
                setExtraTime(extraElapsedTime)
                startExtraTimeCounter();
            }
        } else {
            const getWorkingHistory = async (id: string) => {
                const data: any = await hrApi.getWorkingHistory(id, formattedDate(d), formattedDate(d))
                if (data && data.availabilities) {
                    const check_in: checkInType = data.availabilities[0]
                    if (check_in.check_in === check_in.check_out) {
                        const [date, time] = check_in.check_in.split('T');
                        const [hour, minute] = time.split(':');
                        const [year, month, day] = date.split('-').map(Number);
                        const pastDate = new Date(year, month - 1, day, Number(hour), Number(minute));
                        Cookies.set("checkInTime", pastDate.toISOString(), { expires: expiresInDayCheckIn });

                        const midnight = new Date();
                        midnight.setHours(24, 0, 0, 0);
                        const expiresInSeconds = Math.floor((midnight.getTime() - new Date().getTime()) / 1000);
                        Cookies.set("Present", "Present", { expires: expiresInSeconds / (60 * 60 * 24) });
                    } else {
                        setDisabled(true)
                        const midnight = new Date();
                        midnight.setHours(24, 0, 0, 0);
                        const expiresInSeconds = Math.floor((midnight.getTime() - new Date().getTime()) / 1000);
                        Cookies.set("Present", "Present", { expires: expiresInSeconds / (60 * 60 * 24) });
                    }
                }
            }
            if (user.id) {
                getWorkingHistory(user.id)
            }
        }

        if (getCookie("Present")) {
            setStatus("Present");
        }
    }, [lastCheckIn, get_cookie, user.id]);

    const startCountdown = (initialTime: number) => {
        setCountdown(initialTime);
    };

    const startExtraTimeCounter = () => {
        if (!storedExtraStart) {
            const currentTime = new Date();
            localStorage.setItem("extraTimeStart", currentTime.toISOString());
        }
        const extraInterval = setInterval(() => {
            setExtraTime(prev => prev + 1);
        }, 2000);

        return () => clearInterval(extraInterval);
    };

    const percentStatus = status === "Present"
    const percentStatusButton = buttonStatus === "Present"

    function calculateTimeSpentPercentage(timeSpentInSeconds: number): number {
        return Math.floor(((workingHours - timeSpentInSeconds) / workingHours) * 100);
    }

    return (
        <CustomCard>
            <div className="flex justify-between gap-3 items-center pb-4 mb-2">
                <h2 className="heading2">Today</h2>
                {percentStatus ?
                    <Button variant="outline" size="small" className="success-button">Present</Button> :
                    <Button variant="outline" size="small" className="error-button">Absent</Button>
                }
            </div>
            <div className="grid-container">
                <div className="w-full">
                    <p className="break-words">
                        {percentStatus ? "You have successfully checked in for today." : "You have not marked yourself as present today!"}
                    </p>
                    <div className="border-l-2 border-l-red-600 h-7 flex items-center p-1 pl-2 mt-3 text-sm font-medium">
                        <span className="text-[#1D1027] pr-[2px]">{countdown ? "Time left" : "Overtime"} </span>
                        :
                        <span className="text-[#684A7E] pl-[2px] font-bold">
                            {countdown > 0 ? formatTime(countdown) : `+ ${formatTime(extraTime)}`}
                        </span>
                    </div>
                </div>
                <div className="w-full flex justify-center">
                    <CircularProgressBar size={120} progress={calculateTimeSpentPercentage(countdown)} strokeWidth={8} color="#FFD023" >
                        <div className="flex flex-col items-center">
                            <p className="text-[#1D1027] text-lg font-medium">{calculateTimeSpentPercentage(countdown)}%</p>
                            <p className="text-xs text-[#1D102780] font-semibold">in office</p>
                        </div>
                    </CircularProgressBar>
                </div>
            </div>
            <div className="flex mt-4">
                <Button
                    onClick={handleMarkPresent}
                    isLoading={false}
                    disabled={disabled}
                    variant="primary"
                    className={`w-full ${percentStatusButton && "check-out"}`}
                >
                    {percentStatusButton ? "Check Out" : "Mark Present"}
                </Button>
            </div>
        </CustomCard>
    )
}

export default MarkPresent;

function getDistanceFromLatLonInMeters(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371000;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
}

function deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
}

const formatTime = (seconds: any = 32400) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, "0")}h ${m.toString().padStart(2, "0")}m`;
};
// :${ s.toString().padStart(2, "0")} for second

function getAttendanceStatus(): "Present" | "Late" { // Specify return type more explicitly
    const now: Date = new Date(); // Explicitly type the variable

    const tenThirtyAM: { hour: number; minute: number } = { hour: 10, minute: 30 }; // Explicitly type the object

    const currentHour: number = now.getHours(); // Explicitly type the variable
    const currentMinute: number = now.getMinutes(); // Explicitly type the variable

    if (currentHour < tenThirtyAM.hour ||
        (currentHour === tenThirtyAM.hour && currentMinute < tenThirtyAM.minute)) {
        return "Present";
    } else {
        return "Late";
    }
}

export interface checkInType {
    id: string
    emp_id: string
    date: string
    status: string
    check_in: string
    check_out: string
}