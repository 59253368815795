import { BaseURL } from "../constants";
import { toast } from "react-toastify";
import { user } from "../types";
import authApi from "./auth";
import { useEffect, useState } from "react";

// Helper function to handle fetch requests
export const fetchData = async (url: string, options: RequestInit, errorMessage: string) => {
    const localUser = localStorage.getItem("AuxilioUser2");
    const jsonString = atob(localUser || "");
    const storedUser: user = localUser ? JSON.parse(jsonString) : {};

    try {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${storedUser.token}`,
        };
        const response = await fetch(BaseURL + url, options);

        if (response.ok) {
            const responseData = await response.json();
            return responseData.data;
        } else {
            const responseData = await response.json();
            toast.error(responseData.error.message, { autoClose: 3000 });
            console.error("Request failed with status:", response.status, "Response:", responseData);
        }
    } catch (error) {
        console.error(errorMessage, error);
    }
};

export function useIsLoggedIn(): boolean {
    const [isTokenValid, setIsTokenValid] = useState<boolean>(true);
    const localUser = localStorage.getItem("AuxilioUser2");
    const jsonString = atob(localUser || "");
    const storedUser: user = localUser ? JSON.parse(jsonString) : {};

    if (!storedUser) {
        return false;
    }

    const token = storedUser.token;

    const checkTokenLocal = async () => {
        const data: any = await authApi.checkToken()
        if (data) {
            setIsTokenValid(true);
        } else {
            setIsTokenValid(false);
        }
    }

    if (token) {
        checkTokenLocal()
    }

    if (!token) {
        return false
    } else {
        return isTokenValid
    }
}

export const getPastDate = (daysAgo: number): string => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);

    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
    });
};