import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface DoughnutChartProps {
    on_leave: number
    wfh: number
    wfo: number
}

export function DoughnutChart({ on_leave, wfh, wfo }: DoughnutChartProps) {
    const data = [
        {
            label: "WFO",
            value: wfo,
            color: "#4D3361",
        },
        {
            label: "WFH",
            value: wfh,
            color: "#684A7E",
        },
        {
            label: "On Leave",
            value: on_leave,
            color: "#342044",
        },
    ];

    const options: any = {
        plugins: {
            tooltip: {
                enabled: true,
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',

                },
            },
            datalabels: {
                color: '#fff',
                formatter: (value: number, context: any) => {
                    const total = context.chart.data.datasets[0].data.reduce((a: number, b: number) => a + b, 0);
                    const percentage = (value / total * 100).toFixed(0) + '%';
                    return percentage;
                },
            },
        },
        cutout: '60%',
    };

    const finalData = {
        labels: data.map((item) => item.label),
        datasets: [
            {
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                borderColor: data.map((item) => item.color),
                borderWidth: 1,
            },
        ],
    };

    return <Doughnut data={finalData} options={options} />
}
