import React, { ChangeEvent, useEffect, useState } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/solid";

type FormInputProps = {
    label?: string;
    placeholder: string;
    register: UseFormRegisterReturn;
    error?: FieldError;
    type?: string
    disabled?: boolean
    mandatory?: boolean
};

const FormInput: React.FC<FormInputProps> = ({ label, placeholder, register, error, type, disabled, mandatory }) => {

    return (
        <div>
            {label && <p className="modal-div">{label} {mandatory && <span className='text-red-600'>*</span>}</p>}
            <input type={type} className="profile-input" {...register} placeholder={placeholder} disabled={disabled} />
            {error && <p className="text-red-600 text-xs">{error.message}</p>}
        </div>
    );
};

export default FormInput;

export const InputElement: React.FC<{ name: string, placeholder: string, value: string, onChange: (e: any) => void, type?: string }> = (props) => {
    return (
        <div className="mb-3">
            <label htmlFor={props.name.toLowerCase().replaceAll(" ", "")} className="text-[#344054] text-sm font-medium block mb-2">{props.name} <span className='text-red-600'>*</span></label>
            <input
                type={props.type ? props.type : "text"} name={props.name.toLowerCase().replaceAll(" ", "")} onChange={props.onChange}
                value={props.value}
                className="border border-[#D0D5DD] px-3 py-1 rounded-md focus:outline-none h-[34px] text-base font-normal text-[#667085] w-full"
                placeholder={props.placeholder}
            />
        </div>
    )
}

interface PasswordInputProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    name: string
    placeholder: string
}

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
    const [passwordType, setPasswordType] = useState<boolean>(true);

    const handlePasswordHide = () => {
        setPasswordType(!passwordType);
    };

    return (
        <div className="mb-3">
            <div className="">
                <label htmlFor="password" className="text-sm font-medium leading-6 text-gray-900 flex items-center justify-between">
                    <p>
                        {props.name}
                        <span className="text-red-600"> *</span>
                    </p>
                    <div className="flex justify-start gap-1 items-center text-[#666666CC] cursor-pointer" onClick={handlePasswordHide}>
                        {passwordType ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                        {passwordType ? "Hide" : "Unhide"}
                    </div>
                </label>
            </div>
            <div>
                <input
                    id={props.name.replaceAll(" ", "")}
                    name="password"
                    type={passwordType ? "password" : "text"}
                    autoComplete="current-password"
                    placeholder={props.placeholder}
                    required
                    onChange={props.onChange}
                    value={props.value}
                    className="border border-[#D0D5DD] px-3 py-1 rounded-md focus:outline-none h-[34px] text-base font-normal text-[#667085] w-full"
                />
            </div>
        </div>
    );
};

export const TextAreaInput: React.FC<{
    name: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder: string;
    rows?: number;
    maxLength?: number;
}> = ({ name, value, onChange, placeholder, rows = 3, maxLength = 800 }) => {
    return (
        <textarea
            name={name}
            onChange={onChange}
            value={value}
            rows={rows}
            maxLength={maxLength}
            placeholder={placeholder}
            className="text-area"
        />
    );
}

export const Textarea: React.FC<FormInputProps> = (props) => {
    return (
        <>
            <textarea
                {...props.register}
                rows={3}
                maxLength={800}
                placeholder={props.placeholder}
                className="text-area"
            />
            {props.error && <p className="text-red-600 text-xs">{props.error.message}</p>}
        </>
    );
}