import { ChevronDownIcon, ClipboardDocumentCheckIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { memo, useEffect, useState } from 'react';
import { useUserContext } from '../../context/user';
import { MoonIcon, NotificationIcon, UserIconIn } from '../../assets/icons';
import { fullName, getGreeting } from '../../utils/helpers';
import { ToggleSwitch } from '../../components/common/buttons';
import { Notice } from '../../components/notice';

const CustomHeader = () => {
    const [isEmailHovered, setIsEmailHovered] = useState(false);
    const [isOpenProfile, setIsOpenProfile] = useState(false);
    const greeting: string = getGreeting();
    const { user } = useUserContext();
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [getNotificationFlag, setGetNotificationFlag] = useState<boolean>(false);
    const [darkMode, setDarkMode] = useState(false);

    const copyEmailToClipboard = () => {
        if (user) {
            const email = user.email;
            navigator.clipboard.writeText(email).then(() => {
                console.log("Email copied to clipboard");
            }).catch(err => {
                console.error('Failed to copy email: ', err);
            });
        }
    };

    const handleClickOutside = (e: MouseEvent) => {
        if (!(e.target as HTMLElement).closest(".profile")) {
            setIsOpenProfile(false);
        }
    };

    const full_name = fullName(user.first_name, user.last_name)

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const handleNotificationOpen = () => {
        setIsNotificationOpen(!isNotificationOpen)
        const body = document.getElementsByTagName("body")[0]
        if (!isNotificationOpen) {
            body.style.overflow = "hidden"
        } else {
            body.style.overflow = "unset"
        }
    }

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
            setDarkMode(true);
        }
    }, []);

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.add('dark');
            localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('theme', 'light');
        }
    }, [darkMode]);

    const handleDarkMode = () => {
        setDarkMode(!darkMode)
    };

    return (
        <>
            <div className="flex items-center justify-between gap-4 bg-[#FAFAFA] dark:bg-[#1C1C1C] py-4 px-7 h-[88px] sticky top-0 z-40 w-full">
                <div className="dark:text-[#FFFFFF]">
                    <p className="text-xl text-[#342044] dark:text-[#FFFFFF]">
                        <span className='font-normal'>{greeting}</span>,
                        <span className="font-semibold pl-1">{full_name}</span>
                    </p>
                    <p className="text-base text-[#342044B2] font-medium dark:text-[#FFFFFF]">
                        Have a great day at work!
                    </p>
                </div>
                <div className="flex items-center gap-2">
                    <div className="h-9 w-9  rounded-full bg-[#DFDFDF] flex justify-center  items-center" onClick={handleNotificationOpen}>
                        <NotificationIcon active={getNotificationFlag} />
                    </div>
                    <div className="bg-[#F6F6F6] h-9 w-[2px] rounded-sm"></div>
                    <div className="relative">
                        <div
                            className="profile h-9 p-[6px] rounded-full bg-[#DFDFDF] flex justify-between gap-2 items-center cursor-pointer min-w-52"
                            onClick={() => setIsOpenProfile(!isOpenProfile)}
                        >
                            <UserCircleIcon className="size-6" />
                            <p className='text-sm text-[#324054] font-semibold'>
                                {full_name}
                            </p>
                            <ChevronDownIcon className='size-4' />
                        </div>
                        <div className={`T profile absolute bg-[#F9F0FF] min-w-[200px] w-[200%] right-0 p-4 rounded-xl ${isOpenProfile ? "block" : "hidden"}`}>
                            <div className="flex gap-3 items-center w-auto">
                                <div>
                                    <img src={user.image ? user.image : "/assets/profile-pitcher.png"} alt="" className="rounded-full h-[76px] w-[76px] border border-[#D9D9D9] object-cover" />
                                </div>
                                <div className="overflow-hidden flex-1 ">
                                    <p className='profile-name'>{full_name}</p>
                                    <p className="empId">{user.emp_id}</p>
                                    <div
                                        className="flex items-center gap-1 cursor-pointer"
                                        onClick={copyEmailToClipboard}
                                        onMouseOver={() => setIsEmailHovered(true)}
                                        onMouseLeave={() => setIsEmailHovered(false)}
                                    >
                                        <p className="empEmail truncate flex-grow">{user.email}</p>
                                        <ClipboardDocumentCheckIcon className={`clipboard-icon T size-4 flex-shrink-0 ${isEmailHovered ? "opacity-100" : "opacity-0"}`} />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-[#684A7E] rounded px-3 py-2 mt-5 flex items-center gap-3 cursor-pointer">
                                <UserIconIn />
                                <p className="text-sm font-medium text-white">
                                    My Profile
                                </p>
                            </div>
                            {/* <div className="flex items-center justify-between rounded px-3 py-2 mt-2 gap-3">
                                <div className='flex items-center gap-3'>
                                    <MoonIcon />
                                    <p className="text-sm font-medium text-[#1D1027]">
                                        Switch to dark mode
                                    </p>
                                </div>
                                <ToggleSwitch onClick={handleDarkMode} checked={darkMode} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Notice
                isOpen={isNotificationOpen}
                onClose={handleNotificationOpen}
                isNoticeGet={(flag) => setGetNotificationFlag(flag)}
            />
        </>
    );
}

export default memo(CustomHeader);