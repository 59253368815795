import React, { ReactNode, useEffect } from "react";

interface ModalBoxProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
}

export const ModalBox: React.FC<ModalBoxProps> = (props) => {
    useEffect(() => {
        const body = document.getElementsByTagName("body")[0]
        if (props.isOpen) {
            body.style.overflow = "hidden"
        } else {
            body.style.overflow = "unset"
        }
    }, [props.isOpen]);

    return (
        <>
            {props.isOpen && (
                <div className="flex justify-center items-center  fixed inset-0 z-[1000] backdrop-blur-[2px] overflow-auto py-8">
                    <div className="relative bg-white rounded-md p-4 w-auto md:w-[642px] max-w-[642px] border">
                        {props.children}
                    </div>
                </div>
            )}
        </>
    );
};
