import { useNavigate, useParams } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Bill } from "../../../types";
import { base64ToUint8Array, formattedDate, getCookie } from "../../../utils/helpers";
import financeApi from "../../../services/finance";
import { LoadingScreen } from "../../../components/loading-screen";
import InvoiceHeader from "../../../components/invoice-header";
import { Button } from "../../../components/common/buttons";
import UploadPdfPreview from "../../../components/upload-pdf-preview";

interface editNote {
    isEditNote: boolean
    value: string
}

const BillPreview = () => {
    const navigate = useNavigate()
    const [noteState, setNoteState] = useState<editNote>({ isEditNote: true, value: "" })
    const [billData, setBillData] = useState<Bill>()
    const { billNo } = useParams()
    const [loadingInvoice, setLoadingInvoice] = useState(true);
    const draftData = getCookie("bills")
    const draftBills: Bill[] = draftData ? JSON.parse(draftData) : []

    const handleEditTrueNote = () => {
        if (!noteState.isEditNote && billData && billNo) {
            billData.notes = noteState.value
            billData.services = billData.services.map(({ invoice_no, bill_no, ...rest }) => rest);
            const updateBill = async () => {
                const response = await financeApi.setBill(billData)
                if (response) {
                    getBill(billNo)
                    toast.success("Note updated successfully!", { autoClose: 3000 });
                }
            }
            updateBill()
        }

        setNoteState({
            ...noteState, isEditNote: !noteState.isEditNote
        })
    }

    const handleCancelNoteChange = () => {
        setNoteState({
            ...noteState, isEditNote: !noteState.isEditNote,
            value: billData?.notes || ""
        })
    }

    const handleEditNote = (e: any) => {
        const value = e.target.value
        setNoteState({
            ...noteState, value: value
        })
    }

    useEffect(() => {
        if (billNo) {
            let billFound = false;

            for (let i = 0; i < draftBills.length; i++) {
                const element = draftBills[i];
                if (element.bill_no === billNo) {
                    billFound = true;
                    setBillData(element)
                    setNoteState({
                        ...noteState, value: element.notes
                    })
                    setLoadingInvoice(false)
                    break;
                }
            }

            if (!billFound) {
                getBill(billNo)
            }
        }
    }, [billNo]);

    const getBill = async (no: string) => {
        const data: any = await financeApi.getBill(no)
        if (data && data.bill) {
            const bill: Bill = data.bill
            setBillData(bill)
            setNoteState({
                ...noteState, value: bill.notes, isEditNote: true
            })
            setLoadingInvoice(false)
        }
    }

    if (loadingInvoice) {
        return <LoadingScreen />
    }

    const billDate = formattedDate(billData ? new Date(billData.bill_date) : new Date())
    const grandTotal = (billData?.sub_total || 0) + (billData?.cgst || 0) + (billData?.igst || 0) + (billData?.sgst || 0)

    const handleExportInvoice = () => {
        const downloadInvoice = async () => {
            const response = await financeApi.downloadBills({ bill_no: [billNo] })
            if (response) {
                const blob = await response.blob();
                const contentDisposition = response.headers.get("Content-Type");
                const filename = contentDisposition
                    ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
                    : `bill-${billNo}.zip`;

                const fileUrl = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = filename || `bill-${billNo}.zip`;
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(fileUrl);
            } else { }
        }
        downloadInvoice()
    }

    const handleDownloadAttachment = () => {
        const byteArray = base64ToUint8Array(billData?.attachment);
        const link = document.createElement('a');
        const blob = new Blob([byteArray], { type: "application/octetstream" });
        const url = window.URL;
        let l2 = url.createObjectURL(blob);
        link.href = l2;
        link.download = billNo + "-attachment" + ".pdf"
        link.dispatchEvent(new MouseEvent('click'));
    }

    const pdfBlob: Uint8Array = billData?.attachment || null
    const pdfSizeInBytes = pdfBlob?.length || 0;
    const pdfSizeInKB = (pdfSizeInBytes / 1024).toFixed(2)

    return (
        <div className="bg-white">
            <InvoiceHeader name="" onClose={() => { navigate("/bills") }} />
            <div className="py-6 px-6 grid grid-cols-3 gap-x-[5%] gap-y-6">
                <h1 className="text-[#1D1027] font-bold text-3xl col-span-3">{billData?.invoice_no}</h1>
                <div className="col-span-3 flex justify-end gap-2 py-3">
                    <Button variant="outline" onClick={handleExportInvoice} >Export Bill</Button>
                    <Button onClick={() => { navigate("/edit-bill/" + billNo) }} >Edit Bills</Button>
                </div>
                <div className="col-span-2 bg-[#F4F6F8] px-5 py-5 rounded-xl">
                    <div className="flex justify-between">
                        <div>
                            <h2 className="text-xl font-semibold mb-1">Bill</h2>
                            <p className="primary-text text-">No: {billData?.invoice_no}</p>
                            <div className="flex ">
                                <p className="light-text w-32">Bill No.</p>
                                <p>{billData?.bill_no}</p>
                            </div>
                            <div className="flex ">
                                <p className="light-text w-32">Bill Date</p>
                                <p>{billDate}</p>
                            </div>
                        </div>
                        <div className="w-80">
                            <h2 className="text-lg text-[#3E63DD] mb-1">
                                {billData?.company_name}
                            </h2>
                            <p className="primary-text text-">TAX - {billData?.gst_in}</p>
                            <p className="light-text">
                                {billData?.address_line_1} <br />
                                {billData?.address_line_2}
                            </p>
                            <p className="light-text">hr@arundataprocessing.co.in</p>
                            <p className="light-text">+91 62015 25013</p>
                        </div>
                    </div>
                    <div className="flex justify-between mt-4">
                        <div>
                            <p className="light-text font-semibold">Customer Details</p>
                            <p>{billData?.bill_from}</p>
                            <p>{billData?.mail_id}</p>
                            <p>{billData?.contact_no}</p>
                        </div>
                        <div className="w-80">
                            <p className="light-text font-semibold">Billing Address</p>
                            <p>
                                {billData?.address_line_1} <br />
                                {billData?.address_line_2}
                            </p>
                        </div>
                    </div>
                    <table className="w-full mt-6 text-xs">
                        <thead>
                            <tr className="py-2 bg-[#F8F9FA]">
                                <th className="light-text py-2 px-2">#</th>
                                <th className="light-text py-2 px-2 text-left">Item Name</th>
                                <th className="light-text py-2 px-2">HSN ACS</th>
                                <th className="light-text py-2 px-2">Qty</th>
                                <th className="light-text py-2 px-2">Price</th>
                                <th className="light-text py-2 px-2 text-end">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {billData?.services.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="py-2 px-2 text-center">{index + 1}</td>
                                        <td className="py-2 px-2">
                                            <p >{item.description}</p>
                                            <p className="text-xs light-text">{item.hsn_acs}</p>
                                        </td>
                                        <td className="py-2 px-2 text-center">{item.hsn_acs}</td>
                                        <td className="py-2 px-2 text-center">{item.quantity}</td>
                                        <td className="py-2 px-2 text-center">₹{item.unit_price}</td>
                                        <td className="py-2 px-2 text-end">₹{item.unit_price * item.quantity}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={6} className="py-4"></td>
                            </tr>
                            <tr>
                                <td colSpan={4}></td>
                                <td className="light-text">
                                    Sub Total
                                </td>
                                <td className="text-end">
                                    ₹{billData?.sub_total}
                                </td>
                            </tr>
                            {!!billData?.cgst &&
                                <tr>
                                    <td colSpan={4}></td>
                                    <td className="light-text">
                                        CGST
                                    </td>
                                    <td className="text-end">
                                        ₹{billData.cgst}
                                    </td>
                                </tr>
                            }
                            {!!billData?.sgst &&
                                <tr>
                                    <td colSpan={4}></td>
                                    <td className="light-text">
                                        SGST
                                    </td>
                                    <td className="text-end">
                                        ₹{billData?.sgst}
                                    </td>
                                </tr>
                            }
                            {!!billData?.igst &&
                                <tr>
                                    <td colSpan={4}></td>
                                    <td className="light-text">
                                        IGST
                                    </td>
                                    <td className="text-end">
                                        ₹{billData?.igst}
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td colSpan={4}></td>
                                <td>
                                    Grand Total
                                </td>
                                <td className="text-end">
                                    ₹{grandTotal}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <p className="light-text font-medium text-xs my-4">
                        Thank you for doing business with us. Have a good day!
                    </p>
                    {billData?.attachment &&
                        <div className="max-w-80">
                            <p>Attachments</p>
                            <UploadPdfPreview name={"Attachments"} byte={Number(pdfSizeInKB)} loading={false} isDownload={true} onRemove={handleDownloadAttachment} />
                        </div>
                    }
                </div>
                <div>
                    <div className="flex justify-between items-center">
                        <label htmlFor="note" className="text-[#344054] text-sm font-medium block mb-2">Note</label>
                        <div className="flex gap-6 items-center">
                            {noteState.isEditNote ? "" :
                                <button type="button" className="text-[#0062FFCC] flex gap-2 items-center text-sm font-medium mb-2" onClick={handleCancelNoteChange} >
                                    Cancel
                                </button>}
                            <button type="button" className="text-[#0062FFCC] flex gap-2 items-center text-sm font-medium mb-2" onClick={handleEditTrueNote} >
                                <PencilSquareIcon className="h-5 w-5" />
                                {noteState.isEditNote ? "Edit Note" : "Save Note"}
                            </button>
                        </div>
                    </div>
                    <textarea name="note" id="note"
                        onChange={handleEditNote}
                        value={noteState.value}
                        rows={3}
                        disabled={noteState.isEditNote}
                        maxLength={800}
                        placeholder='Please ensure payment is made by the due date to avoid any late fees.'
                        className="text-area"></textarea>

                    <h2 className="text-[#344054] text-base font-semibold mt-2">Logs</h2>
                    <div className="max-h-96 overflow-scroll">
                        {billData?.updated_at.map((item, index) => {
                            const [date, time] = item.split('T');
                            const [hour, minute] = time.split(':');
                            const isLastElement = index === billData.updated_at.length - 1;
                            const circleClass = isLastElement ? '' : 'green-circle';

                            return (
                                <div className="flex items-center gap-3 mt-3" key={index}>
                                    <div className={`h-8 w-8 rounded-full bg-green-700 ${circleClass}`}></div>
                                    <div>
                                        <h3 className="text-sm font-medium text-[#333333]">
                                            {index === 0 ? 'Bill was generated' : 'Changes made in Bill'}
                                        </h3>
                                        <p className="text-[#888888] text-xs">{date}, {hour}:{minute}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default BillPreview;