import React, { useEffect, useState } from "react";
import { CustomCard } from "../../card";
import DataTable from "../../table";
import { DotList, DotListType } from "../dot-list";
import { COLOR_CODE } from "../../../constants";
import { getPastDate } from "../../../services/utils";
import hrApi from "../../../services/hr-module";
import { formattedDate, formatTo12HourTime } from "../../../utils/helpers";
import { TeamMemberType } from "../../../types";


interface TeamsProps {
    teamMembers: TeamMemberType[],
    loading: string
}

interface WorkingHistory {
    id: string
    emp_id: string
    date: string
    status: string
    check_in: string
    check_out: string
}

const Teams: React.FC<TeamsProps> = ({ teamMembers, loading }) => {
    const [memberData, setMemberData] = useState<Record<string, WorkingHistory[]>>({});

    const fetchMemberData = async () => {
        const dataPromises = teamMembers.map(async (item) => {
            const data = await getDataForTeam(item.id);
            return { [item.id]: data };
        });

        const results = await Promise.all(dataPromises);
        const dataMap = results.reduce((acc, cur) => ({ ...acc, ...cur }), {});
        setMemberData(dataMap);
    };

    useEffect(() => {
        fetchMemberData();
    }, [teamMembers]);

    const headers: any = [
        { key: 'members', label: 'Members' },
        { key: 'today', label: 'Today' },
        { key: 'today2', label: getPastDate(1) },
        { key: 'today3', label: getPastDate(2) },
        { key: 'today4', label: getPastDate(3) },
    ];

    const data = teamMembers.map((item, key) => {
        const memberHistory = memberData[item.id];
        const todayData = memberHistory?.find(record => dateChange(record.date) === getPastDate(0));
        const todayData1 = memberHistory?.find(record => dateChange(record.date) === getPastDate(1));
        const todayData2 = memberHistory?.find(record => dateChange(record.date) === getPastDate(2));
        const todayData3 = memberHistory?.find(record => dateChange(record.date) === getPastDate(3));

        const codes: any = {
            Present: `bg-[${COLOR_CODE.blue}]`,
            Wfh: `bg-[${COLOR_CODE.yellow}]`,
            OnLeave: `bg-[${COLOR_CODE.red}]`,
            Absent: `bg-[${COLOR_CODE.gray}]`,
            Holiday: `bg-[${COLOR_CODE.lightGray}]`,
            Late: `bg-[${COLOR_CODE.blue}]`
        }

        function getStatusColor(status?: any): string {
            return codes[status as any] ?? `bg-[${COLOR_CODE.gray}]`;
        }

        return {
            id: key,
            members: <div className="flex items-center gap-2">
                <div className="w-10">
                    <img
                        src={item.image ? item.image : "/assets/profile-pitcher.png"} alt=""
                        className="h-10 w-10 border-2 border-[#684A7E] rounded-full overflow-hidden object-cover"
                    />
                </div>
                <div>
                    <h1 className="text-sm font-bold text-[#324054] capitalize mb-1">{item.name}</h1>
                    <p className="text-[#1D102766] text-xs font-semibold">{item.designation}</p>
                </div>
            </div>,
            today: <TableDataDot name={todayData?.check_in ? formatTo12HourTime(todayData.check_in) : 'Absent'} color={getStatusColor(todayData?.status)} />,
            today2: <TableDataDot name={todayData1?.check_in ? formatTo12HourTime(todayData1.check_in) : 'Absent'} color={getStatusColor(todayData1?.status)} />,
            today3: <TableDataDot name={todayData2?.check_in ? formatTo12HourTime(todayData2.check_in) : 'Absent'} color={getStatusColor(todayData2?.status)} />,
            today4: <TableDataDot name={todayData3?.check_in ? formatTo12HourTime(todayData3.check_in) : 'Absent'} color={getStatusColor(todayData3?.status)} />
        }
    })

    return (
        <CustomCard className="col-span-2">
            <h2 className="heading2">My Team</h2>
            <div className="flex justify-start gap-5 items-center my-4">
                {header.map((item, key) => <DotList key={key} {...item} />)}
            </div>
            <div className="overflow-auto">
                <DataTable
                    className={""}
                    headers={headers}
                    loading={loading === "loading"}
                    data={data}
                    pages
                />
            </div>
        </CustomCard >
    );
}

export default Teams;

function TableDataDot({ color, name }: DotListType) {
    return (
        <div className="flex justify-start gap-2 items-center">
            <div className={`h-3 w-3 rounded-full ${color}`}></div>
            <p className="text-xs font-medium text-[#1D1027B2]">
                {name}
            </p>
        </div>
    )
}

const getDataForTeam = async (id: string): Promise<any> => {
    const firstDateOfMonth = new Date();
    firstDateOfMonth.setDate(firstDateOfMonth.getDate() - 3);
    const today = new Date();

    const data: any = await hrApi.getWorkingHistory(id, formattedDate(firstDateOfMonth), formattedDate(today));
    return data.availabilities ? data.availabilities : [];
};

function dateChange(d: string): string {
    const date = new Date(d);

    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
    });
}

const header = [
    {
        name: "In Office",
        color: COLOR_CODE.blue
    },
    {
        name: "Work from Home",
        color: COLOR_CODE.yellow
    },
    // {
    //     name: "On Leave",
    //     color: COLOR_CODE.red
    // },
    {
        name: "Absent",
        color: COLOR_CODE.gray
    },
    {
        name: "Holiday",
        color: COLOR_CODE.lightGray
    }
]
//COLOR_CODE.lightGray