import { BaseURL } from "../constants";
import { user } from "../types";
import { fetchData } from "./utils";

const getInvoices = async () => {
    const url = `/finance/invoices`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching invoices");
};

const getInvoice = async (invoiceNo: string) => {
    const url = `/finance/invoices/${invoiceNo}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching EOD reports");
};

const setInvoice = async (data: any) => {
    const url = `/finance/invoices`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error send invoice");
}

const getBills = async () => {
    const url = `/finance/bills`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching Bills");
};

const getBill = async (billNo: string) => {
    const url = `/finance/bills/${billNo}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching Bills");
};

const setBill = async (data: any) => {
    const url = `/finance/bills`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error send invoice");
}

const downloadInvoice = async (data: any) => {
    const localUser = localStorage.getItem("AuxilioUser2");
    const jsonString = atob(localUser || "");
    const storedUser: user = localUser ? JSON.parse(jsonString) : {};

    const url = `${BaseURL}/finance/invoices/download`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${storedUser.token}`,
        },
        credentials: "include",
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error("Error downloading bills");
    }

    return response;
}

const downloadBills = async (data: any) => {
    const localUser = localStorage.getItem("AuxilioUser2");
    const jsonString = atob(localUser || "");
    const storedUser: user = localUser ? JSON.parse(jsonString) : {};

    const url = `${BaseURL}/finance/bills/download`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${storedUser.token}`,
        },
        credentials: "include",
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error("Error downloading bills");
    }

    return response;
}


const financeApi = {
    getInvoices,
    getInvoice,
    setInvoice,
    getBill,
    getBills,
    setBill,
    downloadInvoice,
    downloadBills
}

export default financeApi