import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css'
import React, { useEffect, useState } from 'react';

interface textEditorProps {
    content: string
    sendContent: (content: any) => void
    className: string
}

const TextEditor: React.FC<textEditorProps> = (props) => {
    const [value, setValue] = useState<string>(props.content)

    const handleChange = (content: string) => {
        setValue(content);
        props.sendContent(content)
    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ font: [] }],
            [{ align: ["right", "center", "justify"] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            // [{ color: ["red", "#785412"] }],
            ['clean']
            // [{ background: ["red", "#785412"] }]
        ],
        clipboard: {
            matchVisual: false,
        }
    };

    const editorStyles = {
        fontFamily: 'Arial, sans-serif', // Set default font family
        fontSize: '16px', // Set default font size
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "background",
        "align",
        "font"
    ];

    useEffect(() => {
        setValue(props.content);
    }, [props.content]);

    return (
        <ReactQuill
            className={`w-full col-span-9 rounded-md ${props.className}`}
            theme="snow"
            value={value}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            style={editorStyles}
        />
    );

}

export default TextEditor;