import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import React, { useState, useEffect, useRef } from 'react';

type OptionMenuItem = {
    name: string;
    onClick: () => void;
};

type OptionMenuProps = {
    items: OptionMenuItem[];
    className: string
};

const OptionMenu: React.FC<OptionMenuProps> = ({ items, className }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = (onClick: () => void) => {
        onClick();
        setIsOpen(false); // Hide the menu after the item is clicked
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className="relative inline-block text-left" ref={menuRef}>
            <EllipsisVerticalIcon
                onClick={toggleMenu}
                className="h-5 w-5 self-center text-[#5A5A5A] cursor-pointer"
            />
            {isOpen && (
                <div
                    className={`origin-top-right absolute  mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${className}`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    <div className="py-1" role="none">
                        {items.map((item, key) => (
                            <div
                                key={key}
                                onClick={() => handleItemClick(item.onClick)}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                                role="menuitem"
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default OptionMenu;
