import { StatusConfigType } from "../types"

// export const BaseURL = "http://localhost:8080"
export const BaseURL = "https://auxilio.co.in/api"

export const date = new Date()

export const MONTHS_SOT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const APPROVALS = {
    WFH: "WFH",
    Sick: "Sick",
    Casual: "Casual",
    Paid: "Paid",
    Approved: "APPROVED",
    Pending: "PENDING",
    Declined: "REJECTED",
    PartiallyApproved: "PARTIALLY_APPROVED",
    Revoked: "CANCELLED"
}

export const LEAVE_DETAILS = {
    Sick: 7,
    Casual: 9,
    Paid: 7,
    WFH: 0
}

export const APPROVALS_STATUS: any = {
    WFH: {
        color: "text-green-700",
        bg: "",
        tag: "WFH",
    },
    Sick: {
        color: "text-red-700",
        bg: "",
        tag: "Sick",
    },
    Casual: {
        color: "text-red-700",
        bg: "",
        tag: "Casual",
    },
    Paid: {
        color: "text-green-700",
        bg: "",
        tag: "Paid",
    },
    APPROVED: {
        color: "text-[#14804A]",
        bg: "bg-[#E1FCEF]",
        tag: "Approved",
    },
    PENDING: {
        color: "text-[#AA5B00]",
        bg: "bg-[#FCF2E6]",
        tag: "Pending",
    },
    REJECTED: {
        color: "text-[#D1293D]",
        bg: "bg-[#FFEDEF]",
        tag: "Declined",
    },
    PARTIALLY_APPROVED: {
        color: "text-[#441480]",
        bg: "bg-[#EBE1FC]",
        tag: "Partially Approved"
    },
    CANCELLED: {
        color: "text-[#5E5ADB]",
        bg: "bg-[#EDEDFC]",
        tag: "Revoked",
    }
}

export const COLOR_CODE = {
    blue: "#3279ED",
    yellow: "#FFD023",
    red: "#DE380E",
    gray: "#8D8D8D",
    lightGray: "#CFCFCF",
    orange: "#FF834F",
    absent: "#D3D3D3"
}

export const officeLocation = {
    distance: 100,
    latitude: 23.0025429,
    longitude: 72.5025843
}

export const STATUS_CONFIG: StatusConfigType = {
    onTime: {
        name: "On Time",
        color: "text-green-600",
        backgroundColor: "bg-green-100",
        bg: "bg-[#3279ED]"
    },
    workFromHome: {
        name: "Work From Home",
        color: "text-blue-600",
        backgroundColor: "bg-blue-100",
        bg: "bg-[#FFD023]"
    },
    lateAttendance: {
        name: "Late Attendance",
        color: "text-yellow-600",
        backgroundColor: "bg-yellow-100",
        bg: "bg-[#DE380E]"
    },
    absent: {
        name: "Absent",
        color: "text-red-600",
        backgroundColor: "bg-red-100",
        bg: "bg-[#8D8D8D]"
    },
    approved: {
        name: "Approved",
        color: "text-green-500",
        backgroundColor: "bg-green-200",
        bg: "bg-green-500"
    },
    pending: {
        name: "Pending",
        color: "text-orange-500",
        backgroundColor: "bg-orange-100",
        bg: "bg-orange-500"
    },
    cancel: {
        name: "Cancelled",
        color: "text-gray-500",
        backgroundColor: "bg-gray-200",
        bg: "bg-gray-500"
    },
    reject: {
        name: "Rejected",
        color: "text-red-700",
        backgroundColor: "bg-red-200",
        bg: "bg-red-600"
    },
};

export const LeavesType = [
    {
        "id": "22222222-2222-2222-2222-222222222222",
        "name": "Sick Leave",
        "description": "Sick Leave"
    },
    {
        "id": "33333333-3333-3333-3333-333333333333",
        "name": "Paid Leave",
        "description": "Paid Leave"
    },
    {
        "id": "44444444-4444-4444-4444-444444444444",
        "name": "WFH",
        "description": "Work From Home"
    },
    {
        "id": "55555555-5555-5555-5555-555555555555",
        "name": "Casual Leave",
        "description": "Casual Leave"
    }
]

export const Organization = ["Arun Data Processing", "Virtual Dot", "KAI Nest"]

export const stateOfIndia = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Maharashtra",
    "Madhya Pradesh",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Tripura",
    "Telangana",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman & Nicobar(UT)",
    "Chandigarh(UT)",
    "Dadra & Nagar Haveli and Daman & Diu(UT)",
    "Delhi",
    "Jammu & Kashmir(UT)",
    "Ladakh(UT)",
    "Lakshadweep(UT)",
    "Puducherry(UT)"
]

export const SACCodes = [
    {
        SACCode: "998311",
        description: "Management consulting and management services including financial, strategic, human resources, marketing, operations and supply chain management."
    },
    {
        SACCode: "998312",
        description: "Business consulting services including pubic relations services"
    },
    {
        SACCode: "998313",
        description: "Information technology(IT) consulting and support services"
    },
    {
        SACCode: "998314",
        description: "Information technology(IT) design and development services"
    },
    {
        SACCode: "998315",
        description: "Hosting and information technology(IT) infrastructure provisioning services"
    },
    {
        SACCode: "998316",
        description: "IT infrastructure and network management services"
    },
    {
        SACCode: "998319",
        description: "Other information technology services"
    },
]