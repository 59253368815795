import { ReactNode } from "react";

type Props = {
    className?: string
    children: ReactNode;
}

export const CustomCard = ({ className, children }: Props) => {
    return (
        <div className={`bg-white dark:bg-[#323232] border border-[#0000001A] p-6 rounded-xl ${className}`}>
            {children}
        </div>
    );
}