import React from "react";
import { CustomCard } from "../../card";
import TeamMember, { TeamMemberSkeleton } from "../team-member";
import { LeaveLog } from "../../../types";
import { ComponentLoading } from "../../component-loading";
import { Button } from "../buttons";
import { convertDateFormat } from "../../../utils/helpers";

export type EmployeesHolidayProps = {
    employeesLoading: string
    employees: LeaveLog[]
}

const EmployeesHoliday: React.FC<EmployeesHolidayProps> = ({ employees, employeesLoading }) => {

    return (
        <CustomCard>
            <div className="flex justify-between gap-3 items-center pb-4 mb-2">
                <h2 className="heading2">Upcoming Absences</h2>
                <Button variant="outline" size="small">Week</Button>
            </div>
            <div className={`grid grid-cols-1 justify-between gap-y-2 mt-4 `}>
                <ComponentLoading
                    isLading={employeesLoading === "loading"}
                    error={employeesLoading === "error"}
                    children={employees.map((item, key) => {
                        return (
                            <EmployeesHolidayItem key={key} {...item} />
                        )
                    })}
                    childrenSkeleton={<TeamMemberSkeleton />}
                />
            </div>
        </CustomCard>
    )
}

export default EmployeesHoliday;

export const EmployeesHolidayItem = (props: LeaveLog) => {
    const color = props.leave_type.name === "WFH" ? "after:bg-[#03CA2F]" : "after:bg-[#FB1616]"

    return (
        <div className="flex justify-between gap-2 items-center w-[100%] mb-[10px]">
            <div className="flex items-center justify-between gap-2">
                <div
                    className={`w-9 relative after:content-[' '] ${color} after:h-[10px] after:w-[10px] after:absolute after:rounded-full after:border-2 after:border-white after:bottom-0 after:right-[0px]`}
                >
                    <img
                        src={!!props.image ? props.image : "/assets/logo.png"} alt="emp"
                        className="w-9 h-9 rounded-full border object-cover"
                    />
                </div>
                <div className="">
                    <p className="h4-dark ">{props.employee_name}</p>
                    <p className=" text-[#242533B2] text-xs font-normal tracking-wide break-keep">{props.designation}</p>
                </div>
            </div>
            <div>
                <p
                    className="text-nowrap text-orange-600 text-sm font-medium leading-4"
                    dangerouslySetInnerHTML={{ __html: convertDateFormat(props.start_date, props.end_date) }} >
                </p>
                <p className=" text-[#242533B2] text-xs font-normal tracking-wide break-keep">{props.leave_type.name}</p>
            </div>
        </div>
    );
}