import { ArrowLongDownIcon, ArrowLongUpIcon, ArrowRightIcon, ArrowsUpDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

interface DataItem {
    [key: string]: any;
    id: number;
}

interface SortConfig {
    key: keyof DataItem;
    direction: 'ascending' | 'descending' | '';
}

interface Header {
    key: keyof DataItem;
    label: string;
}

interface Props {
    className: string;
    loading: boolean;
    data: DataItem[];
    headers: Header[];
    pages?: boolean
}

const DataTable: React.FC<Props> = ({ data, headers, className, loading, pages }) => {
    const [filter, setFilter] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: '' });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 6;

    const getComparableValue = (value: any): string => {
        if (React.isValidElement(value)) {
            const extractTextFromChildren = (children: React.ReactNode): string => {
                if (typeof children === 'string' || typeof children === 'number') {
                    return children.toString();
                }
                if (Array.isArray(children)) {
                    return children.map(extractTextFromChildren).join('');
                }
                if (React.isValidElement(children)) {
                    return extractTextFromChildren(children.props.children);
                }
                return '';
            };
            const value1: any = value.props //value.props.children
            return extractTextFromChildren(value1.children);
        } else if (typeof value === 'object' && value !== null) {
            return (value.name as string) || '';
        }
        return value?.toString() || '';
    };

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];
        if (sortConfig.direction !== '') {
            sortableData.sort((a, b) => {
                let aValue = getComparableValue(a[sortConfig.key]);
                let bValue = getComparableValue(b[sortConfig.key]);

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const handleSort = (key: keyof DataItem) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === 'ascending') {
                setSortConfig({ key, direction: 'descending' });
            } else if (sortConfig.direction === 'descending') {
                setSortConfig({ key, direction: '' }); // Reset to no sort
            } else {
                setSortConfig({ key, direction: 'ascending' }); // First click
            }
        } else {
            setSortConfig({ key, direction: 'ascending' }); // New sort key
        }
    };

    const filteredData = sortedData.filter(item =>
        headers.some(header =>
            getComparableValue(item[header.key]).toString().toLowerCase().includes(filter.toLowerCase())
        )
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const renderPageButtons = () => {
        const pageButtons = [];
        for (let i = 1; i <= totalPages; i++) {
            if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageButtons.push(
                    <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        className={`h-7 w-7 flex justify-center items-center text-sm rounded ${currentPage === i ? 'bg-black text-white' : 'bg-[#F5F5F5] border'}`}
                    >
                        {i}
                    </button>
                );
            } else if (i === 2 && currentPage > 3) {
                pageButtons.push(
                    <span key="dots-1" className="px-1">
                        ...
                    </span>
                );
            } else if (i === totalPages - 1 && currentPage < totalPages - 2) {
                pageButtons.push(
                    <span key="dots-2" className="px-1">
                        ...
                    </span>
                );
            }
        }
        return pageButtons;
    }

    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, filteredData.length);

    return (
        <div className={className}>
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        {headers.map(header => (
                            <th
                                key={header.key}
                                onClick={() => handleSort(header.key)}
                                className="px-6 py-3 text-left tracking-wider cursor-pointer text-[13px] text-[#000000] font-medium"
                            >
                                <div className="flex items-center ">
                                    {header.label}
                                    {header.label !== '' &&
                                        <span className='w-6'>
                                            {(sortConfig.key === header.key && sortConfig.direction !== '') ? sortConfig.key === header.key && (
                                                sortConfig.direction === 'ascending' ?
                                                    <ArrowLongUpIcon className="h-4 w-4 ml-1 text-[#7C8DB5]" /> :
                                                    <ArrowLongDownIcon className="h-4 w-4 ml-1 text-[#7C8DB5]" />
                                            ) : <ArrowsUpDownIcon className="h-4 w-4 ml-1 text-[#7C8DB5]" />}
                                        </span>
                                    }
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white ">
                    {loading ?
                        [0, 1, 2, 3].map((key) => {
                            return (
                                <tr key={key} className='animate-pulse border-b border-gray-200'>
                                    {Object.keys(headers).map((id) => {
                                        return (
                                            <td key={id} className="px-6 py-3">
                                                <div className="skeleton-text skeleton"></div>
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                        :
                        paginatedData.length ? paginatedData?.map(item => (
                            <tr key={item.id} className="border-b border-gray-200 hover:bg-slate-100">
                                {headers.map(header => (
                                    <td key={header.key as string} className="px-6 py-2 whitespace-nowrap text-[13px] text-[#000000] font-medium">
                                        {item[header.key]}
                                    </td>
                                ))}
                            </tr>
                        )) :
                            <tr>
                                <td colSpan={Object.keys(headers).length}>
                                    <div className="no-data">No data found</div>
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
            {pages && !!paginatedData.length &&
                <div className="flex justify-between items-center">
                    <div className="mt-4 font-medium text-sm text-[#B5B7C0]">
                        Showing data {startIndex} to {endIndex} of {filteredData.length} entries
                    </div>
                    <div className="flex justify-end items-center space-x-2 mt-4">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="h-7 w-7 bg-[#F5F5F5] border flex justify-center items-center text-sm rounded disabled:opacity-50"
                        >
                            <ChevronLeftIcon className="h-4 w-4" />
                        </button>
                        {renderPageButtons()}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="h-7 w-7 bg-[#F5F5F5] border flex justify-center items-center text-sm rounded disabled:opacity-50"
                        >
                            <ChevronRightIcon className="h-4 w-4" />
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default DataTable;

// extra code
// const handleSort = (key: keyof DataItem) => {
//     if (sortConfig.key === key) {
//         if (sortConfig.direction === 'ascending') {
//             setSortConfig({ key, direction: 'descending' });
//         } else if (sortConfig.direction === 'descending') {
//             setSortConfig({ key, direction: '' });
//         }
//     } else {
//         setSortConfig({ key, direction: 'ascending' });
//     }
// };

// const sortedData = React.useMemo(() => {
//     let sortableData = [...data];
//     if (sortConfig.direction !== '') {
//         sortableData.sort((a, b) => {
//             if (a[sortConfig.key] < b[sortConfig.key]) {
//                 return sortConfig.direction === 'ascending' ? -1 : 1;
//             }
//             if (a[sortConfig.key] > b[sortConfig.key]) {
//                 return sortConfig.direction === 'ascending' ? 1 : -1;
//             }
//             return 0;
//         });
//     }
//     return sortableData;
// }, [data, sortConfig]);
// const filteredData = sortedData.filter(item =>
//     headers.some(header =>
//         item[header.key].toString().toLowerCase().includes(filter.toLowerCase())
//     )
// );