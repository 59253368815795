import { useEffect, useState } from "react";
import LoginLayout from "../../layout/Login-layout";
import { InputElement, PasswordInput } from "../../components/common/form-input";
import { LoadingIcon } from "../../components/common/buttons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authApi from "../../services/auth";

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = getQueryParams(location.search);
    const [formData, setFormData] = useState({ username: queryParams.email || "", password: "", confirmPassword: "" })
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState({ error: false, massage: 'Use 8 or more characters with a mix of letters, numbers & symbols' });
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,18}$/;

    const handlePasswordChange = (e: any) => {
        const password = e.target.value
        setFormData({
            ...formData,
            password: password
        });
        if (!regex.test(password)) {
            setErrorMessage({
                error: true,
                massage: 'Password must be at least 8 characters long, include one uppercase letter, one number, and one special character.'
            });
        } else {
            setErrorMessage({
                error: false,
                massage: 'Use 8 or more characters with a mix of letters, numbers & symbols'
            });
        }
    };

    const handleConfirmPasswordChange = (e: any) => {
        if (!regex.test(formData.password)) {
            setErrorMessage({
                error: true,
                massage: 'Password must be at least 8 characters long, include one uppercase letter, one number, and one special character.'
            });
            return
        }
        const password = e.target.value
        setFormData({
            ...formData,
            confirmPassword: password
        });
        if (password === formData.password) {
            setErrorMessage({
                error: false,
                massage: 'Use 8 or more characters with a mix of letters, numbers & symbols'
            });
        } else {
            setErrorMessage({
                error: true,
                massage: 'Passwords do not match.'
            });
        }
    };

    const submitForgotPassword = () => {
        setLoading(true)
        if (formData.username === "" || formData.password === "" || formData.confirmPassword === "") {
            toast.error("Please fill the mandatory fields", { autoClose: 3000 });
            setLoading(false)
            return
        }
        if (formData.password !== formData.confirmPassword) {
            toast.error("Passwords do not match.", { autoClose: 3000 });
            setLoading(false)
            return
        }
        if (!regex.test(formData.password)) {
            setErrorMessage({
                error: true,
                massage: 'Password must be at least 8 characters long, include one uppercase letter, one number, and one special character.'
            });
            setLoading(false)
            return
        }
        const resetPassword = async () => {
            if (queryParams.user_id) {
                const data: any = await authApi.resetPassword(queryParams.user_id, {
                    "reset_token": queryParams.token || "",
                    "new_password": formData.password || "",
                })
                if (data) {
                    setFormData({ username: "", password: "", confirmPassword: "" })
                    setLoading(false)
                    toast.success(data.message, { autoClose: 3000 })
                    setTimeout(() => {
                        navigate("/app/login")
                    }, 1000);
                }
                setLoading(false)
            }
        }
        resetPassword()
    }

    useEffect(() => {
        if (!queryParams.email) {
            navigate('/dashboard');
        }
    }, [queryParams]);

    return (
        <LoginLayout name={"Reset Password 🔐"} description={""}>
            <div className="flex flex-col mt-6">
                <InputElement name={"Email"} placeholder={"Enter email address"} value={formData.username} onChange={() => {
                    setFormData({
                        ...formData,
                        password: formData.username.trim()
                    });
                }} />
                <PasswordInput onChange={handlePasswordChange} value={formData.password} name={"Password"} placeholder={"Enter password"} />
                <PasswordInput onChange={handleConfirmPasswordChange} value={!regex.test(formData.password) ? "" : formData.confirmPassword} name={"Confirm password"} placeholder={"Confirm password"} />
                <p className={`font-normal h-10 leading-tight text-sm ${errorMessage.error ? "text-red-600" : "text-[#666666]"}`}>
                    {errorMessage.massage}
                </p>
                <button
                    type="button"
                    className="submit-btn"
                    onClick={submitForgotPassword}
                >
                    {loading ? <LoadingIcon /> : "Save Password"}
                </button>
                <Link
                    to="/app/login"
                    className="submit-btn"
                >
                    Back
                </Link>
            </div>
        </LoginLayout>
    );
}

export default ResetPassword;

function getQueryParams(query: string): Record<string, string> {
    return query
        .substring(1)
        .split('&')
        .reduce((params: Record<string, string>, param: string) => {
            const [key, value] = param.split('=');
            params[key] = decodeURIComponent(value);
            return params;
        }, {});
}