import EmployeeBirthday from '../../components/common/employee-birthday';
import { useEffect, useState } from 'react';
import { HolidayList, LeaveLog, MetricType, TeamMemberType, TodayStatusCounts, WorkingHistoryType } from '../../types';
import 'react-calendar/dist/Calendar.css';
import { useUserContext } from '../../context/user';
import Layout from '../../layout';
import hrApi from '../../services/hr-module';
import { TimeCard } from '../../components/common/time-card';
import { AccordionGroup } from '../../components/accordion';
import { CustomCard } from '../../components/card';
import EmployeeAttendance from '../../components/common/employee-attendance';
import { LeaveStatus, LeaveStatusSkeleton, SortLeaveStatusSkeleton } from '../../components/common/leave-status';
import UpcomingHolidays from '../../components/common/upcoming-holiday';
import { formattedDate, getDateRange } from '../../utils/helpers';
import useLeaveLogs from '../../hooks/use-leaves';
import { ComponentLoading } from '../../components/component-loading';
import MarkPresent from '../../components/common/mark-present';
import WorkingHistory from '../../components/common/working-history';
import Teams from '../../components/common/teams';
import { date } from '../../constants';

const ManagerDashboard = () => {
    const { user } = useUserContext();
    const [holidayList, setHolidayList] = useState<HolidayList[]>([])
    const [pendingApproval, setPendingApproval] = useState<LeaveLog[]>([])
    const [loadingPendingApproval, setLoadingPendingApproval] = useState<'loading' | 'error' | 'success'>('loading')
    const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([])
    const [teamMembersLoading, SetTeamMembersLoading] = useState<'loading' | 'error' | 'success'>('loading')
    const { loadingLeave, leaveLogs, getLeaveLogs } = useLeaveLogs()
    const [leavesHistory, setLeavesHistory] = useState<LeaveLog[]>([])
    const [LeavesHistoryLoading, setLeavesHistoryLoading] = useState<'loading' | 'error' | 'success'>('loading')
    const [workingHistoryLog, setWorkingHistoryLog] = useState<WorkingHistoryType[]>([]);
    const [workingHistoryLoading, setWorkingHistoryLoading] = useState<'loading' | 'error' | 'success'>('loading')
    const [yearStatusCounts, setYearStatusCounts] = useState<TodayStatusCounts>({ absent: 0, late: 0, on_time: 0, wfh: 0, total: 0 })
    const [metric, setMetric] = useState<MetricType>({ average_check_in: "", average_check_out: "", average_hours: "", average_on_time: "" });
    const [availabilityStatusLoading, setAvailabilityStatusLoading] = useState<boolean>(true);

    const getHolidays = async () => {
        const holidays: any = await hrApi.getHolidays()
        if (!!holidays) {
            setHolidayList(holidays.holidays)
        }
    }

    const getEmployeesLeaveStatus = async (id: string, index: number) => {
        const data: any = await hrApi.getEmployeesLeaveStatus(id, index)
        if (data) {
            setPendingApproval(data.leaves)
            setLoadingPendingApproval("success")
        } else {
            setLoadingPendingApproval("error")
        }
    }

    const getLeavesHistory = async (empID: string) => {
        const data: any = await hrApi.getLeavesHistory(empID)
        if (data) {
            setLeavesHistory(data.leaves)
            setLeavesHistoryLoading("success")
        } else {
            setLeavesHistoryLoading("error")
        }
    }

    const getTeamMembers = async (empID: string) => {
        const data: any = await hrApi.getTeamMembers(empID)
        if (data && data.team_members) {
            setTeamMembers(data.team_members)
            SetTeamMembersLoading("success")
        } else {
            SetTeamMembersLoading("error")
        }
    }

    const getWorkingHistory = async (id: string) => {
        const firstDateOfMonth = new Date();
        firstDateOfMonth.setDate(1);
        const today = new Date();

        const data: any = await hrApi.getWorkingHistory(id, formattedDate(firstDateOfMonth), formattedDate(today))
        if (data && data.availabilities) {
            setWorkingHistoryLoading("success")
            setWorkingHistoryLog(data.availabilities)
        } else {
            setWorkingHistoryLoading("error")
        }
    }

    const getEmployeeYearCount = async (empID: string) => {
        const data: any = await hrApi.getEmployeeYearCount(empID)
        if (data && data.availability_status) {
            setAvailabilityStatusLoading(false)
            setYearStatusCounts((prev) => ({
                ...prev,
                ...data.availability_status,
                total: 365
            }))
        } else {
            setAvailabilityStatusLoading(false)
        }
    }

    const getMetrics = async (id: string) => {
        const data: any = await hrApi.getMetrics(id)
        if (data && data.employee_metrics) {
            setMetric(data.employee_metrics)
        }
    }

    useEffect(() => {
        getHolidays()
        if (user.id) {
            getLeaveLogs(user.id)
            getLeavesHistory(user.id)
            getEmployeesLeaveStatus(user.id, 0)
            getTeamMembers(user.emp_id)
            getWorkingHistory(user.id)
            getEmployeeYearCount(user.id)
            getMetrics(user.id)
        }
    }, [user]);

    return (
        <Layout>
            <div className="flex justify-between items-center mb-5">
                <h2 className="text-xl font-semibold capitalize">My monthly attendance</h2>
            </div>
            <div className="grid grid-cols-4 gap-6 mb-6">
                <TimeCard colorClass="fill-[#684A7E]" title="Average Hours" value={metric.average_hours} />
                <TimeCard colorClass="fill-[#14804A]" title="On-time Arrival" value={metric.average_on_time} />
                <TimeCard colorClass="fill-[#14804A]" title="Average Check-in" value={metric.average_check_in + " min"} />
                <TimeCard colorClass="fill-[#FF3D00]" title='Average Check-out' value={metric.average_check_out + " min"} />
            </div>
            <div className="grid grid-cols-12 gap-6 items-start">
                <div className="col-span-8 grid grid-cols-2 gap-6">
                    <MarkPresent />
                    <EmployeeAttendance
                        loading={availabilityStatusLoading}
                        tag={date.getFullYear().toString()}
                        onTime={yearStatusCounts.on_time}
                        absent={yearStatusCounts.absent}
                        totalEmp={yearStatusCounts.total}
                        wfh={yearStatusCounts.wfh}
                        lateAttendance={yearStatusCounts.late} />
                    <Teams teamMembers={teamMembers} loading={teamMembersLoading} />
                    <WorkingHistory working={workingHistoryLog} loading={workingHistoryLoading} />
                </div>
                <div className="col-span-4 grid gap-6">
                    <EmployeeBirthday />
                    <CustomCard>
                        <h2 className="heading2">Approval Status</h2>
                        <AccordionGroup accordions={[{
                            title: "Leave Status",
                            content:
                                <ComponentLoading
                                    isLading={loadingLeave === 'loading'}
                                    error={loadingLeave === 'error'}
                                    children={leaveLogs.length && leaveLogs.map((item, key) => {
                                        const dateRange = getDateRange(item.start_date, item.end_date, holidayList);
                                        return (
                                            <LeaveStatus key={key} leave={item} dateRange={dateRange} userId={''} isExpanded={false} isView={true} />
                                        )
                                    })}
                                    childrenSkeleton={<SortLeaveStatusSkeleton />}
                                />
                        }]}
                        />
                        <div className='bg-[#F6F6F6] h-[2px]'></div>
                        <AccordionGroup accordions={[{
                            title: "Pending Approval",
                            content:
                                <ComponentLoading
                                    isLading={loadingPendingApproval === 'loading'}
                                    error={loadingPendingApproval === 'error'}
                                    children={
                                        pendingApproval?.map((item, key) => {
                                            const dateRange = getDateRange(item.start_date, item.end_date, holidayList);
                                            return (
                                                <LeaveStatus
                                                    key={key}
                                                    leave={item}
                                                    dateRange={dateRange}
                                                    userId={user.id}
                                                    isLeaveApproval={true}
                                                    isExpanded={false}
                                                />
                                            )
                                        })
                                    }
                                    childrenSkeleton={<LeaveStatusSkeleton />}
                                />
                        }]}
                        />
                        <div className='bg-[#F6F6F6] h-[2px]'></div>
                        <AccordionGroup accordions={[{
                            title: "History",
                            content:
                                <ComponentLoading
                                    isLading={LeavesHistoryLoading === 'loading'}
                                    error={LeavesHistoryLoading === 'error'}
                                    children={
                                        leavesHistory?.map((item, key) => {
                                            const dateRange = getDateRange(item.start_date, item.end_date, holidayList);
                                            return (
                                                <LeaveStatus
                                                    key={key}
                                                    leave={item}
                                                    dateRange={dateRange}
                                                    userId={""}
                                                    isLeaveApproval={false}
                                                    isExpanded={false}
                                                    isView={true}
                                                />
                                            )
                                        })
                                    }
                                    childrenSkeleton={<LeaveStatusSkeleton />}
                                />
                        }]}
                        />
                    </CustomCard>
                    <UpcomingHolidays holidays={holidayList} />
                </div>
            </div >
        </Layout >
    );
}

export default ManagerDashboard;