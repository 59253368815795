import "./index.css";

export const LoadingScreen: React.FC = () => {
    return (
        <div className="bg-white h-screen w-full flex items-center justify-center">
            <div className="ring_box">
                <div className="ring"></div>
                <div className="ring"></div>
                <div className="ring"></div>
                <p>Loading ...</p>
            </div>
        </div>
    );
};