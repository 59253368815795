import './App.css';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { UserContextProvider } from './context/user';
import AppRoutes from './routes';

function App() {
    const location = useLocation();

    useEffect(() => {
        const pageTitle = getTitleFromRoute(location.pathname)
        document.title = `Auxilio ${pageTitle ? "-" : ""} ${pageTitle}`
    }, [location])

    const getTitleFromRoute: any = (pathname: string): string => {
        const segments = pathname.split('/').filter((segment) => segment !== '')
        return segments.map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1)).join(' ')
    }

    return (
        <UserContextProvider>
            <AppRoutes />
        </UserContextProvider >
    );
}

export default App;
