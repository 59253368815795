import React from "react"
import { Link } from 'react-router-dom'
import { Button } from "../../components/common/buttons"

export default function NotFound() {
    return (
        <div className="h-screen flex flex-col justify-start p-8">
            <div className="">
                <Link to="/dashboard" className="flex justify-start gap-3 items-center">
                    <img src="/assets/au-dark-logo.png" alt="Auxilio" className="h-11 w-11" />
                    <div className={`text-[#2E3033] text-lg font-extrabold overflow-hidden T max-w-32 opacity-100`}>AUXILIO</div>
                </Link>
            </div>
            <div className="h-[100%] flex justify-center items-center px-4">
                <div className="text-center md:w-[40%] flex flex-col items-center gap-4">
                    <img src="/assets/404-page/not-found.png" alt="" className="" />
                    <h1 className="mb-4 text-5xl font-semibold text-[#252F40]">Oops!</h1>
                    <p className="mb-4 text-lg text-gray-600">Oops! That page seems to have taken a detour. Let us guide you back to your destination.</p>

                    <Link to="/dashboard">
                        <Button variant="primary" size="medium">Back to home page</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
