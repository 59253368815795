import { formattedDate } from "../utils/helpers";
import { fetchData } from "./utils";


const updateHolidays = async (holidayID: any, data: any) => {
    const url = `/edit-holidays/${holidayID}`;
    return await fetchData(url, { method: "PUT", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error updating holiday");
};

const setHolidays = async (data: any) => {
    const url = `/holidays`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body: JSON.stringify(data) }, "Error updating holiday");
};

const deleteHoliday = async (holidayID: any) => {
    const url = `/holiday/${holidayID}`;
    return await fetchData(url, { method: "DELETE", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error deleting holiday");
};

const leaveApproval = async (leaveId: any, userId: string) => {
    const url = `/leaves/${leaveId}/approve/${userId}`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error send forgot password email");
};

const leaveReject = async (leaveId: any, userId: string) => {
    const url = `/leaves/${leaveId}/reject/${userId}`;
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error send forgot password email");
};

const createLeave = async (data: any) => {
    const url = `/leaves`;
    const body = JSON.stringify(data);
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body }, "Error fetching project details");
};

const getLeaveLogs = async (empID: string) => {
    const url = `/leaves?EmployeeID=${empID}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching leave logs");
};

const getLeaveBalance = async (empID: string) => {
    const url = `/leaves/employee/${empID}/leave-balance`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching leave logs count");
};

const revokeLeaveRequest = async (leaveID: string) => {
    const url = `/leaves/${leaveID}/cancel`;
    const response = await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Leave ID is required to revoke");
    return response;
};

const getEmployeesLeaveStatus = async (empID: string, index: number) => {
    const url = `/leaves?Status=PENDING&ApproverID=${empID}&ApproverIDStatus=PENDING&ApproverIndex=${index}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching employees leave status");
};

const getLeavesHistory = async (empID: string) => {
    const url = `/leaves?ApproverID=${empID}&ApproverIDStatus=APPROVED`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching leaves history");
};

const getEmployeeBirthdays = async (month: string, day: string) => {
    const url = `/employees/birthdays?month=${month}&day=${day}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching employee birthdays");
};

const getNotices = async () => {
    const url = `/leaves/notices`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching operational data");
};

const createNotice = async (data: any) => {
    const url = `/leaves/notice`;
    const body = JSON.stringify(data);
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body }, "Error fetching project details");
};

const deleteNotice = async (id: string) => {
    const url = `/leaves/notice/${id}`;
    return await fetchData(url, { method: "DELETE", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching project details");
};

const getHolidays = async () => {
    const url = `/leaves/holidays`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching holidays");
};

const getEmployeesOnHolidays = async () => {
    const today = new Date();
    const lastDate = new Date();
    lastDate.setDate(today.getDate() + 7);
    const url = `/leaves/daily-attendance?EndDate=${formattedDate(lastDate)}&StartDate=${formattedDate(today)}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching holidays");
};

const setAttendance = async (data: any) => {
    const url = `/leaves/attendance`;
    const body = JSON.stringify(data);
    return await fetchData(url, { method: "POST", headers: { "Content-Type": "application/json" }, credentials: "include", body }, "Error fetching project details");
};

const getTeamMembers = async (id: string) => {
    const url = `/teams/members/${id}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching holidays");
};

const getWeeklyAttendance = async () => {
    const url = `/leaves/weekly-attendance`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching holidays");
};

const getEmployeeStatus = async () => {
    const url = `/leaves/employee-status`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching holidays");
};

const getEmployeeYearCount = async (id: string) => {
    const url = `/leaves/employee-status?emp_id=${id}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching holidays");
};

const getWorkingHistory = async (id: string, start_date: string, end_date: string) => {
    const url = `/leaves/${id}/attendance?start_date=${start_date}&end_date=${end_date}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching project details");
};

const getMetrics = async (id: string = "") => {
    const url = `/leaves/metrics?emp_id=${id}`;
    return await fetchData(url, { method: "GET", headers: { "Content-Type": "application/json" }, credentials: "include" }, "Error fetching metrics");
};

const hrApi = {
    setHolidays,
    updateHolidays,
    deleteHoliday,
    leaveApproval,
    leaveReject,
    createLeave,
    revokeLeaveRequest,
    getLeaveBalance,
    getEmployeesLeaveStatus,
    getLeavesHistory,
    getHolidays,
    getLeaveLogs,
    getEmployeeBirthdays,
    getNotices,
    createNotice,
    deleteNotice,
    setAttendance,
    getEmployeesOnHolidays,
    getTeamMembers,
    getWeeklyAttendance,
    getEmployeeStatus,
    getEmployeeYearCount,
    getWorkingHistory,
    getMetrics
}

export default hrApi