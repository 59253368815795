import React, { useEffect, useState } from "react"
import "./index.css"
import { CustomCard } from "../../card"
import { AccordionGroup } from "../../accordion"
import { EmployeeBirthdayProp } from "../../../types"
import { EnvelopeIcon } from "@heroicons/react/24/outline"
import { fullName } from "../../../utils/helpers"
import { ModalBox } from "../../modal-box"
import { Button, CrossButton } from "../buttons"
import hrApi from "../../../services/hr-module"
import { ComponentLoading } from "../../component-loading"

const EmployeeBirthday = () => {
    const [employeesBirthdays, setEmployeesBirthdays] = useState<EmployeeBirthdayProp[]>([])
    const currentDate = new Date();
    const day = currentDate.getDate().toString();
    const monthShort = currentDate.toLocaleString('en-GB', { month: 'short' });
    const monthNumber = (currentDate.getMonth() + 1).toString();
    const formattedDate = `${day} ${monthShort}`;

    const getEmployeeBirthdays = async () => {
        const data: any = await hrApi.getEmployeeBirthdays(monthNumber, day)
        if (data && data.birthdays) {
            setEmployeesBirthdays(data.birthdays)
        }
    }

    const todayBirthday = employeesBirthdays.length ? employeesBirthdays.map((item, key) => {
        item.bob = formattedDate
        return (
            <BirthdayCard key={key} {...item} />
        )
    }) : <div className="text-center">
        <p>No data</p>
    </div>

    useEffect(() => {
        getEmployeeBirthdays()
    }, []);

    return (
        <CustomCard>
            <h3 className="heading2">Anniversaries & Birthday</h3>
            <AccordionGroup
                accordions={[
                    {
                        title: "Today",
                        content:
                            <ComponentLoading
                                isLading={false}
                                error={!employeesBirthdays.length}
                                children={todayBirthday}
                                childrenSkeleton={null}
                            />
                    }
                ]}
            />
        </CustomCard>
    )
}

export default EmployeeBirthday;

const BirthdayCard: React.FC<EmployeeBirthdayProp> = ({ email, emp_id, first_name, gender, image, last_name, bob }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleOpenMOdel = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    return (
        <>
            <div className="flex justify-between items-center w-full py-3">
                <Birthday name={fullName(first_name, last_name)} image={image} dob={bob} />
                <div className="flex items-center justify-center">
                    <a href={`mailto:${email}`} className="p-1 rounded-md border border-[#DADADA] bg-white">
                        <EnvelopeIcon className="size-5" />
                    </a>
                </div>
            </div>
            <ModalBox isOpen={isOpen} onClose={closeModal}>
                <div className="flex justify-between items-center mb-5">
                    <h2 className="h2 w-96 mb-1">Name{fullName(first_name, last_name)}</h2>
                    <CrossButton onClick={closeModal} />
                </div>
                <Birthday name={fullName(first_name, last_name)} image={image || ''} dob={bob} />
                <textarea name="reason" id="reason"
                    onChange={() => { }}
                    rows={3}
                    maxLength={800}
                    placeholder='Wishing you a very happy birthday!..........'
                    className="text-area mt-6 mb-4"
                />
                <Button variant="primary">
                    Wish
                </Button>
            </ModalBox>
        </>
    )
}

type BirthdayType = {
    name: string
    image: string
    dob?: string
}

function Birthday(params: BirthdayType) {
    return (
        <div className="flex  items-center gap-2">
            <div>
                <img
                    src={params.image ? params.image : "/assets/profile-pitcher.png"} alt=""
                    className="h-10 w-10 rounded-lg overflow-hidden"
                    loading="lazy"
                />
            </div>
            <div>
                <h1 className="text-sm font-bold text-[#324054] capitalize mb-1">{params.name}</h1>
                <div className="flex justify-between gap-2 items-center">
                    <div className="py-1 px-3 rounded-md inline-block text-xs font-medium text-[#14804A] bg-[#E1FCEF]">Birthday</div>
                    <p className="text-[#00000066] font-semibold">{params.dob}</p>
                </div>
            </div>
        </div>
    )
}

// Hi Elmer Laverty,
// Wishing you a very happy birthday!
// We appreciate your hard work and dedication to Arun Data Processing.
// You're a valuable member of the team, and we're grateful for your contributions.