import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { user } from '../types';

interface UserContextType {
    user: user;
    setUser: React.Dispatch<React.SetStateAction<UserContextType['user']>>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

const defaultUser = {
    designation: '',
    dob: '',
    email: '',
    emp_id: '',
    first_name: '',
    gender: '',
    id: '',
    image: '',
    last_name: '',
    role: '',
    token: '',
    sidebar_open: true
}

export const UserContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<UserContextType['user']>(defaultUser);

    useEffect(() => {
        const localUser = localStorage.getItem("AuxilioUser2");
        if (localUser) {
            const jsonString = atob(localUser);
            const storedUser: UserContextType['user'] = JSON.parse(jsonString);
            setUser((prevUser) => ({ ...prevUser, ...storedUser }));
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }

    return context;
};