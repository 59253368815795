import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";

export type ExpandMenuOptionProps = {
    isActive: boolean;
    title: string;
    to: string;
};

type ExpandMenuProps = {
    title: string;
    options: ExpandMenuOptionProps[];
    icon: ReactNode;
    isOpen: boolean;
    onClick: () => void;
    isExpanded: boolean
};

const ExpandMenu: React.FC<ExpandMenuProps> = ({ title, options, icon, isOpen, onClick, isExpanded }) => {
    const className = `w-4 h-4 transform transition-all  duration-300 ${isOpen ? 'rotate-90' : '-rotate-90'} ${isExpanded ? "block" : "hidden"}`;

    return (
        <div className="relative inline-block text-left w-full group">
            <div
                className={`px-3 h-11 py-[10px] rounded-md flex justify-between items-center gap-2 mt-2 T hover-class hover:bg-[#F7ECFF] bg-white text-[#757575] cursor-pointer`}
                onClick={onClick}
            >
                <div className="flex items-center gap-2">
                    <div className={"nav-btn-light"}>{icon}</div>
                    <div className={`flex text-base font-medium hover-text overflow-hidden ${isExpanded ? "w-auto h-6" : "w-0 h-0"}`}
                    >
                        {title}
                    </div>
                </div>
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className={className}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                    </svg>
                </div>
            </div>
            {!isExpanded &&
                <div className="absolute left-full top-1/3 mb-1 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 text-nowrap w-fit">
                    {title}
                </div>
            }
            {isOpen && (
                <div className="border-l-2 border-[#F1F2ED] ml-6 pl-3 mt-1">
                    <div className="overflow-hidden">
                        {options.map((option, key) => {
                            const slug = option.title.toLowerCase();
                            return (
                                <Link to={option.to} key={key} className="bg-red-700">
                                    <div
                                        className={`px-4 py-3 rounded-lg flex w-full mb duration-300 transition-all cursor-pointer hover:bg-[#F7ECFF] ${option.isActive ? "bg-[#F7ECFF]" : " bg-white text-[#757575]"}`}
                                    >
                                        <div className="shrink text-zinc-800 dark:text-white text-sm font-semibold leading-tight">
                                            {option.title}
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExpandMenu;