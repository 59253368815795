import React, { memo, ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import { date } from "../../constants";

interface LoginLayoutProps {
    children: ReactNode;
    name: string;
    description: string
}

const LoginLayout: React.FC<LoginLayoutProps> = (props) => {
    return (
        <>
            <div className="flex h-full items-center justify-center px-6 py-12 lg:px-8 login-bg">
                <div className="bg-white w-2/4 p-12 rounded-3xl max-w-xl">
                    <div className="flex items-center justify-center gap-2 mb-5">
                        <img
                            className="h-6 w-6"
                            src="/assets/logo.png"
                            alt="Auxilio"
                        />
                        <h2 className="bruno-ace-sc-regular text-center text-base font-normal tracking-tight text-[#2E3033]">
                            AUXILIO
                        </h2>
                    </div>
                    <h2 className="font-medium text-2xl text-[#043133]">{props.name}</h2>
                    <h2 className="text-base text-[#043133] font-medium">{props.description}</h2>
                    {props.children}
                </div>
                <div className="absolute bottom-6 text-[#FFFFFF] text-center">
                    {date.getFullYear()} Auxilio, All right Reserved
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default memo(LoginLayout);